import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InventoryGeneralSettingsService {
  constructor(private http: HttpClient) {}

  submitStoreType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/store_type_config?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getStoreTypeDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/store_type_config_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateStoreType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/store_type_config_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  storeTypeSearch(current_page, per_page, employeeName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/store_type_config?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getStoreCategoryDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/store_category_config_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitStoreCategory(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/store_category_config?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateStoreCategory(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/store_category_config_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  storeCategorySearch(current_page, per_page, employeeName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/store_category_config?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getUomDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/measure_unit_config_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitUom(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/measure_unit_config?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateUom(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/measure_unit_config_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  uomSearch(current_page, per_page, employeeName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/measure_unit_config?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  uomListing(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_measure_unit_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getProductClassificationDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/adasd?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitProductClassification(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/aSas?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateProductClassification(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/adasd?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  productClassificationSearch(
    current_page,
    per_page,
    employeeName,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/adasd?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&name=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getParentClassificationDetail(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_product_classification?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitDemandType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/demand_type_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDemandTypeDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/demand_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDemandType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/demand_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  demandTypeSearch(current_page, per_page, employeeName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/demand_type_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitDemandPriority(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/demand_priority_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDemandPriorityDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/demand_priority_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDemandPriority(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/demand_priority_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  demandPrioritySearch(current_page, per_page, employeeName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/demand_priority_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitVendorType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/vendor_type_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVendorTypeDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/vendor_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateVendorType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/vendor_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  vendorTypeSearch(current_page, per_page, employeeName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/vendor_type_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitTaxType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/tax_type_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTaxTypeDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/tax_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateTaxType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/tax_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  taxTypeSearch(current_page, per_page, employeeName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/tax_type_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitPolicyType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/work_flow_policy_type_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPolicyTypeDetail(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/work_flow_policy_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePolicyType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/work_flow_policy_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  policyTypeSearch(
    current_page,
    per_page,
    searchString,
    status,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/work_flow_policy_type_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          searchString +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getReportingFlowPolicyDetail(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/work_flow_policy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          id,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitReportingFlowPolicyForm(value, removed_policy_array): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/work_flow_policy?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          removed_policy_array,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getWorkFlowPolicyByType(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_work_flow_policy_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getNarrationTypeDetail(employeeId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/narration_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateNarrationType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/narration_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  narrationSearch(current_page, per_page, employeeName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/narration_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitNarrationType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/narration_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  //  API for Inventory Control Services
  inventorySystemControls(
    value1,
    value2,
    value3,
    value4,
    value5,
    value6,
    value7,
    store,
    poStore,
    irStore,
    IrLocation,
    userControl,
    irDepartment,
    showqty,
    show_all_ir_item_on_pr,
    auto_grn_verify,
    show_ir_store_wise_on_in,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/system_control_settings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          report_header_alignment: value1,
          stock_in_hand: value2,
          ir_signature: value3,
          store_wise_products: value4,
          non_zero_products: value5,
          service_department: value6,
          service_sub_department: value7,
          ot_store: store,
          po_store: poStore,
          default_ir_store: irStore,
          is_location: IrLocation,
          is_user: userControl,
          is_department: irDepartment,
          show_ir_in_qty: showqty,
          show_all_ir_item_on_pr: show_all_ir_item_on_pr,
          auto_grn_verify: auto_grn_verify,
          show_ir_store_wise_on_in: show_ir_store_wise_on_in,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  savePosControls(mrn, restrictToPrintLabelOnPos, autoApproveConsumablesRequestedFromIpd): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/system_control_settings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          pos_def_pat: mrn,
          restrict_to_print_label_on_pos: restrictToPrintLabelOnPos,
          auto_approve_consumables_requested_from_ipd: autoApproveConsumablesRequestedFromIpd,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  inventorySystemAdminControls(serviceControl): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/system_control_settings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          ipd_service_charges: serviceControl.ipd_service_charges,
          service: serviceControl.service,
          patient_types: serviceControl.patient_type_id,
          allow_ipd_service_charges: serviceControl.allow_ipd_service_charges,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  inventoryStoreControls(serviceControl): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/system_control_settings?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          lab_store_id: serviceControl.lab_store_id,
          dialysis_store_id: serviceControl.dialysis_store_id,
          radiology_store_id: serviceControl.radiology_store_id,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // API For Inventory System Get Call
  getInventorySystemControls(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/system_control_settings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // API For Inventory Get Last demand data against specific Product Get Call
  getLastDemandData(product, location): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/last_demanded_indent_req_qty?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&product=' +
          product +
          '&location=' +
          location,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // API for Apply department and su department too All Services.
  applyDepartmentAllServices(): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/apply_department_to_all_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          // icd_code: value
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
