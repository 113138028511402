import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import * as moment from "moment/moment";

@Component({
  selector: 'app-lab-costing-modal',
  templateUrl: './lab-costing-modal.component.html',
  styleUrls: ['./lab-costing-modal.component.css']
})
export class LabCostingModalComponent implements OnInit  {
  @Input() addReportsCostingDetail: any;
  showCostingDetail = false;

  constructor(
  ) {}

  ngOnInit(): void {
  }
  showHIdeCostingDetail(): any {
    this.showCostingDetail = !this.showCostingDetail;
  }

}
