<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>
          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <!--          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">General</a>-->
          <!--            </li>-->
          <!--            <li class="breadcrumb-item">-->
          <!--              <a  class="text-muted">Minimized Aside</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <div class="d-flex align-items-center">
        <span class="switch switch-icon ml-5">
          <span class="mr-2">Radiant</span>
          <label>
            <input
              type="checkbox"
              checked="checked"
              name="UrlCheck"
              (change)="updatePACSURL($event.target.checked)"
            />
            <span></span>
          </label>
          <span class="ml-1">Web</span>
        </span>
      </div>
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom gutter-b mb-4">
        <!--begin::Body-->
        <div class="card-body p-6">
          <!--begin::Table-->
          <div class="table_top_bar">
            <form>
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3 pr-0">
                  <label>From Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="From Date"
                    readonly
                    class="form-control form-control-sm"
                    id="start_date"
                    [(ngModel)]="startDate"
                  />
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3 pr-0">
                  <label>To Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="To Date"
                    readonly
                    class="form-control form-control-sm"
                    id="end_date"
                    [(ngModel)]="endDate"
                  />
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 pr-0">
                  <label>Patient Search</label>
                  <div class="input-icon">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      name="mrn"
                      [(ngModel)]="searchMrn"
                      placeholder="Search By MRN, Patient Name, Phone Number"
                    />
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 pr-0">
                  <label>Acc. No</label>
                  <div class="input-icon">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      name="searchAcc"
                      [(ngModel)]="searchAcc"
                      placeholder="Search ACC"
                    />
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 pr-0">
                  <label>CNIC</label>
                  <div class="input-icon">
                    <input
                      type="text"
                      id="nic"
                      class="form-control form-control-sm"
                      name="mrn"
                      [(ngModel)]="patientNic"
                      placeholder="Search CNIC"
                      (keypress)="onlyNumeric($event)"
                    />
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 pr-0">
                  <label>Department</label>
                  <select
                    class="form-control form-control-sm selectpicker"
                    id="department"
                    name="department"
                    [(ngModel)]="departmentId"
                    data-dropup-auto="false"
                    data-live-search="true"
                  >
                    <option value="" disabled>Select</option>
                    <option value="" *ngIf="subDepartments.length > 0">
                      All
                    </option>
                    <option
                      [value]="department.id"
                      *ngFor="let department of subDepartments"
                    >
                      {{ department?.title }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 pr-0">
                  <label>Status</label>
                  <select
                    class="form-control form-control-sm selectpicker"
                    id="statusType"
                    name="statusType"
                    [(ngModel)]="statusType"
                    data-dropup-auto="false"
                    data-live-search="true"
                  >
                    <option value="">All</option>
                    <option value="acknowledge" selected>Pending</option>
                    <option value="reporting">Acknowledged</option>
                    <option value="final_signed">Final Signed</option>
                    <option value="delivered">Delivered</option>
                    <option value="cancel">Cancel</option>
                  </select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 pr-0">
                  <label>Priority</label>
                  <select
                    class="form-control form-control-sm selectpicker"
                    id="urgencyType"
                    name="statusType"
                    [(ngModel)]="urgency"
                    data-dropup-auto="false"
                    data-live-search="true"
                  >
                    <option value="">All</option>
                    <option [ngValue]="'routine'">Routine</option>
                    <option [ngValue]="'same_day'">Same Day</option>
                    <option [ngValue]="'emergency'">Emergency</option>
                  </select>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 pr-0 text-right">
                  <button
                    class="btn btn-light-primary btn-sm font-weight-bold mr-2 mt-2"
                    type="submit"
                    (click)="searchFilterValue()"
                    (submit)="searchFilterValue()"
                    ngbTooltip="Search"
                    placement="bottom"
                  >
                    Search
                  </button>
                  <button
                    (click)="updateFilters('')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-2 mt-2"
                  >
                    All
                  </button>
                  <button
                    (click)="updateFilters('opd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-2 opd-btn mt-2"
                  >
                    OPD
                  </button>
                  <button
                    (click)="updateFilters('ipd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-2 ipd-btn mt-2"
                  >
                    IPD
                  </button>
                  <button
                    (click)="updateFilters('urgent')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-2 urgent mt-2"
                  >
                    Urgent
                  </button>
                  <button
                    (click)="updateFilters('returned')"
                    type="button"
                    class="btn btn-light-primary btn-sm returned mt-2"
                  >
                    Returned
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>

      <div class="card card-custom gutter-b mb-4">
        <!--begin::Body-->
        <div class="card-body p-6">
          <div class="row">
            <div class="col-10">
              <div class="form-group">
                <label>Indication for Exam</label>
                <textarea
                  class="form-control"
                  rows="2"
                  style="height: auto !important"
                  [(ngModel)]="history"
                ></textarea>
              </div>
            </div>
            <div class="col-2">
              <div class="row" *ngIf="showAcknowledgementButton">
                <div class="col-12">
                  <div class="mb-2">
                    <span class="d-flex"
                      >Total Services: {{ listIds.length || 0 }}</span
                    >
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <a
                      class="btn btn-light-primary btn-sm px-6 font-weight-bold w-100"
                      ngbTooltip="Acknowledge"
                      placement="bottom"
                      (click)="openResultModal()"
                      >Acknowledge</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="listIds.length === 1">
              <div
                class="mb-1"
                *ngIf="
                  acknowledgementData[0]
                    ?.any_allergies_to_iodinated_contrast !== ''
                "
              >
                <span class="mr-2">Any allergies to iodinated contrast?</span>
                <strong>{{
                  acknowledgementData[0]?.any_allergies_to_iodinated_contrast
                    ? "Yes"
                    : "No"
                }}</strong>
              </div>
              <div
                class="mb-1"
                *ngIf="
                  acknowledgementData[0]
                    ?.any_contraindications_To_receive_contrast !== ''
                "
              >
                <span class="mr-2"
                  >Any contraindications to receive contrast?</span
                >
                <strong>{{
                  acknowledgementData[0]
                    ?.any_contraindications_To_receive_contrast
                    ? "Yes"
                    : "No"
                }}</strong>
              </div>
              <div
                class="mb-1"
                *ngIf="
                  acknowledgementData[0]
                    ?.any_contraindications_to_receive_contrast_textarea
                "
              >
                {{
                  acknowledgementData[0]
                    ?.any_contraindications_to_receive_contrast_textarea || ""
                }}
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>

      <div class="card card-custom gutter-b mb-4">
        <!--begin::Body-->
        <div class="card-body p-6" style="padding-top: 0px">
          <div class="table-responsive position-relative">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_3"
            >
              <thead>
                <tr>
                  <th style="width: 10px"></th>
                  <th style="width: 15%"></th>
                  <th style="width: 16%"></th>
                  <th style="width: 16%"></th>
                  <th style="width: 15%"></th>
                  <th style="width: 10%"></th>
                  <th style="width: 18%"></th>
                  <!--                <th style="width: 8%"></th>-->
                  <th style="width: 8%; text-align: center">
                    <input
                      type="checkbox"
                      (change)="updateRecordsAllList($event.target.checked)"
                      *ngIf="showAcknowledgementButton"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let element of pageDataList; let i = index"
                  [ngClass]="{
                    returned: element.is_rejected === true,
                    urgent: element.urgent === true,
                    urgentTrue: element.urgent_action === true
                  }"
                >
                  <td
                    [ngClass]="{
                      'ipd-btn': element.ipd === true,
                      'opd-btn': element.ipd === false
                    }"
                  ></td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{
                        element.order_date | date: "dd-MM-yyyy h:mm a"
                      }}</span
                    >
                    <span class="text-muted font-weight-bold text-muted d-block"
                      >Acc No: {{ element.accession_number }}</span
                    >
                    <span class="text-muted font-weight-bold text-muted d-block"
                      >MRN: {{ element.patient.mrn }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ element.patient_name }}</span
                    >
                    <span
                      class="text-muted font-weight-bold text-muted d-block"
                      >{{ element.service_name }}</span
                    >
                    <span class="text-muted font-weight-bold text-muted d-block"
                      >Films: {{ element.films || 0 }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ element.visit_type }}</span
                    >
                    <span
                      class="text-muted font-weight-bold text-muted d-block"
                      *ngIf="element.department_title"
                      >Dept: {{ element.department_title }}</span
                    >
                    <span
                      class="text-muted font-weight-bold text-muted d-block"
                      *ngIf="element?.ward_name"
                      >Ward: {{ element?.ward_name }}</span
                    >
                  </td>
                  <td>
                    <!--                  <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{element.performing_doctor_name}}</span>-->
                    <!--                  <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{element.referring_doctor_name}}</span>-->
                    <span
                      class="label label-lg label-light-success label-inline cursor-pointer"
                      *ngIf="
                        element?.performing_doctor_name;
                        else performingElse
                      "
                      (click)="
                        changeDoctor(
                          'performing',
                          element,
                          element.performing_doctor_id
                        )
                      "
                      >Per Dr:
                      {{ element?.performing_doctor_name | titlecase }}</span
                    >
                    <ng-template #performingElse>
                      <span
                        class="label label-lg label-light-success label-inline cursor-pointer"
                        (click)="changeDoctor('performing', element, '')"
                        >Per Dr: Nill</span
                      >
                    </ng-template>
                    <span
                      class="label label-lg label-light-warning label-inline cursor-pointer d-flex mt-3"
                      *ngIf="element?.referring_doctor_name; else referringElse"
                      (click)="
                        changeDoctor(
                          'referring',
                          element,
                          element.referring_doctor_id
                        )
                      "
                      >Ref Dr:
                      {{ element?.referring_doctor_name | titlecase }}</span
                    >
                    <ng-template #referringElse>
                      <span
                        class="label label-lg label-light-warning label-inline cursor-pointer d-flex mt-3"
                        (click)="changeDoctor('referring', element, '')"
                        >Ref Dr: Nill</span
                      >
                    </ng-template>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ element.urgent_new | titlecase }}</span
                    >
                    <span
                      class="text-muted font-weight-bold text-muted d-block"
                      >{{ element?.investigation_status | titlecase }}</span
                    >
                  </td>
                  <td>
                    <ng-container *ngIf="element?.service_cancelled === false">
                      <div
                        class=""
                        *ngIf="element?.investigation_status === 'delivered'"
                      >
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder"
                          *ngIf="element.report"
                          (click)="printConfirmModal(element)"
                          style="padding: 2px 5px"
                        >
                          Print
                        </button>
                      </div>
                      <div
                        class=""
                        *ngIf="element?.investigation_status === 'final'"
                      >
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder"
                          (click)="deliveredResult(element)"
                          style="padding: 2px 5px"
                        >
                          Deliver
                        </button>
                      </div>
                    </ng-container>
                  </td>
                  <!--                <td>-->
                  <!--                <div *ngIf="element?.investigation_status === 'final'">-->
                  <!--                  <span *ngIf="element?.urgent_action === true">True</span>-->
                  <!--&lt;!&ndash;                  <span *ngIf="element?.urgent_action === false">False</span>&ndash;&gt;-->
                  <!--                </div>-->
                  <!--              </td>-->
                  <td style="text-align: center">
                    <ng-container *ngIf="element?.service_cancelled === false">
                      <div
                        *ngIf="element?.investigation_status === 'pending'"
                        style="text-align: center"
                      >
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder"
                          *ngIf="
                            element?.ipd === true &&
                              element?.received_by_name === '';
                            else checkBoxServices
                          "
                          (click)="receivedOrderConfirm(element, i)"
                          style="padding: 2px 5px"
                        >
                          Receive
                        </button>
                        <ng-template #checkBoxServices>
                          <input
                            type="checkbox"
                            name="acknowledge_{{ element.id }}"
                            class="is_acknowledged checkbox"
                            *ngIf="showAcknowledgementButton"
                            (change)="updateRecordsList(element)"
                            style="margin: auto"
                          />
                        </ng-template>
                      </div>
                      <div style="text-align: center">
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder"
                          (click)="openDiscomImage(element?.accession_number)"
                          style="padding: 2px 5px"
                          *ngIf="
                            element?.accession_number &&
                            element?.investigation_status !== 'pending'
                          "
                        >
                          Image
                        </button>
                      </div>
                      <div
                        style="text-align: center"
                        *ngIf="
                          element?.investigation_status === 'pending' &&
                          element?.payment_status === 'paid'
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder mt-1"
                          style="padding: 2px 5px"
                          (click)="cancelLabTest(element)"
                        >
                          Cancel
                        </button>
                      </div>
                      <div
                        style="text-align: center"
                        *ngIf="
                          element?.investigation_status === 'pending' &&
                          element?.payment_status === 'unpaid'
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-light-primary btn-sm font-weight-bolder mt-1"
                          style="padding: 2px 5px"
                          (click)="openAddDiscountModal(element)"
                        >
                          Cancel
                        </button>
                      </div>
                    </ng-container>
                    <button
                      type="button"
                      class="btn btn-light-primary btn-icon btn-sm mt-1"
                      style="padding: 2px 5px"
                      (click)="returnCancelLabTest(element)"
                      *ngIf="element?.service_cancelled === true"
                    >
                      <span class="pt-1"><i class="fas fa-undo"></i> </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->

          <div class="pagination_main">
            <ngb-pagination
              [(page)]="page"
              (pageChange)="loadPage(page)"
              [pageSize]="perPage"
              [collectionSize]="totalPages"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
            ></ngb-pagination>
            <div class="dropdown">
              <a
                class="btn dropdown-toggle btn-light btn-sm"
                role="button"
                id="pagination_btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="page_count">
                  {{ perPage }}
                </span>
                <span class="count_count_icon"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="pagination_btn">
                <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                  >25</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                  >50</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                  >75</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                  >100</a
                >
                <a class="dropdown-item" (click)="selectPaginationLimit(150)"
                  >150</a
                >
                <!--                <a class="dropdown-item" (click)="selectPaginationLimit(totalPages)">All</a>-->
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #changeDrModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">
            Change
            <span *ngIf="changDrType === 'referring'">Referring</span>
            <span *ngIf="changDrType === 'performing'">Performing</span>
            Doctor!
          </h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <label>Reasons</label>
              <select
                class="form-control form-control-sm"
                data-live-search="true"
                id="doctorsListChange"
                [(ngModel)]="changDrId"
              >
                <option value="" [disabled]="true">Select Doctor</option>
                <ng-container *ngIf="changDrType === 'referring'">
                  <ng-container *ngFor="let doc of doctors">
                    <option
                      [value]="doc.id"
                      *ngIf="doc.employee_type !== 'visiting'"
                    >
                      {{ doc.full_name }}
                    </option>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="changDrType === 'performing'">
                  <ng-container *ngFor="let doc of doctors">
                    <option
                      [value]="doc.id"
                      *ngIf="doc.employee_type !== 'visiting'"
                    >
                      {{ doc.full_name }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="changeDoctorFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #deliveredLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Order Delivered!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="reportDelivered" (ngSubmit)="deliveredResultFun()">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Relation</label>
                  <select
                    class="form-control"
                    formControlName="receiver_relation"
                    (change)="updateReceiverType($event.target.value)"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_relation.errors
                    }"
                  >
                    <option value="Self">Self</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Receiver Name</label>
                  <input
                    class="form-control"
                    formControlName="receiver_name"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_name.errors
                    }"
                  />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>Receiver CNIC</label>
                  <input
                    class="form-control"
                    type="text"
                    maxlength="13"
                    (keypress)="_onlyNumeric($event)"
                    formControlName="receiver_cnic"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_cnic.errors
                    }"
                  />
                </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label class="checkbox checkbox-sm float-left mr-3">
                    <input
                      type="checkbox"
                      name="showHeader"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="showHeader"
                    />
                    <span></span>
                  </label>
                  <span>You want to show Header on report</span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="checkbox checkbox-sm float-left mr-3">
                    <input
                      type="checkbox"
                      name="showHeader"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="showFooter"
                    />
                    <span></span>
                  </label>
                  <span>You want to show Footer on report</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer p-3">
            <button
              type="button"
              class="btn btn-sm btn-default"
              (click)="c('Close click')"
            >
              Close
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
              Confirm
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="printConfirmation"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Print Report</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <label class="checkbox checkbox-sm float-left mr-3">
              <input type="checkbox" [(ngModel)]="showHeader" />
              <span></span>
            </label>
            <span>You want to show Header on report</span>
          </div>
          <div class="col-12 mt-5">
            <label class="checkbox checkbox-sm float-left mr-3">
              <input type="checkbox" [(ngModel)]="showFooter" />
              <span></span>
            </label>
            <span>You want to show Footer on report</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="printReport(this.labId)"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #orderReceivedConfirmation let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Receive!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <label>Received By</label>
              <select
                class="form-control form-control-sm"
                data-live-search="true"
                id="receivedBy"
                [(ngModel)]="orderReceived.received_by"
              >
                <option value="" [disabled]="true">Select Received By</option>
                <option [value]="doc.id" *ngFor="let doc of employeesListing">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
            <div class="col-sm-8 mt-5">
              <label>Inform To</label>
              <select
                class="form-control form-control-sm"
                data-live-search="true"
                id="informTo"
                [(ngModel)]="orderReceived.inform_to"
              >
                <option value="" [disabled]="true">Select Inform To</option>
                <!--                <option [value]="doc.id" *ngFor="let doc of doctors">{{doc.first_name}} {{doc.last_name}}</option>-->
                <ng-container
                  *ngFor="let employeesList of employeesListing; let i = index"
                >
                  <option [value]="employeesList?.id">
                    {{ employeesList?.first_name }}
                    <span *ngIf="employeesList?.middle_name !== null">
                      {{ employeesList?.middle_name }}</span
                    >
                    {{ employeesList?.last_name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="receivedOrderFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Results Modal-->
<div
  class="modal fade"
  id="ResultModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2">
        <h5 class="modal-title">Add Results</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="resultForm" (ngSubmit)="acknowledgeRecords()">
        <div class="modal-body">
          <div class="row">
            <!--            Scan completed-->
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Scan Completed</label>
                <select
                  formControlName="scan_completed"
                  class="form-control form-control-sm"
                  formGroupName="scan_completed"
                >
                  <option [ngValue]="''" [disabled]="true">Select</option>
                  <option [ngValue]="true">Yes</option>
                  <option [ngValue]="false">No</option>
                </select>
              </div>
            </div>
            <!--   Total contrast used-->
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Total Contrast Used</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Total Contrast Used"
                  formControlName="total_contrast_used"
                />
              </div>
            </div>
            <!--            Type of contrast used-->
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Type Of Contrast Used</label>
                <select
                  class="form-control form-control-sm"
                  formControlName="type_of_contrast"
                >
                  <option [ngValue]="''" [disabled]="true">select</option>
                  <option [ngValue]="'ionic'">Ionic</option>
                  <option [ngValue]="'non_ionic'">Non Ionic</option>
                </select>
              </div>
            </div>
            <!--            Total radiation dose-->
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Total radiation dose</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Total radiation dose"
                  formControlName="total_radiation_dose"
                />
              </div>
            </div>
            <!--            Any issues in image acquisition-->
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Any Issues In Image Acquisition</label>
                <select
                  class="form-control form-control-sm"
                  formControlName="image_acquisition_issue"
                >
                  <option [ngValue]="''" [disabled]="true">select</option>
                  <option [ngValue]="true">Yes</option>
                  <option [ngValue]="false">No</option>
                </select>
              </div>
            </div>
            <!--            Comment on issues-->
            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
              <div class="form-group">
                <label>Comment On Issues</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Comment On Issues"
                  formControlName="issue_comments"
                />
              </div>
            </div>
            <!--            Any other comments for radiologists-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <label>Any Other Comments For Radiologists</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Any Other Comments For Radiologists"
                  formControlName="other_comments"
                />
              </div>
            </div>
          </div>
          <hr/>
          <div
            class="text-right cursor-pointer"
            style="text-decoration: underline; color: #3699ff"
          >
            <span *ngIf="showCostingDetail" (click)="showHIdeCostingDetail()"
            >Hide Costing Detail</span
            >
            <span *ngIf="!showCostingDetail" (click)="showHIdeCostingDetail()"
            >Show Costing Detail</span
            >
          </div>
          <hr/>
          <div *ngIf="showCostingDetail">
            <table class="table">
              <thead>
              <tr>
                <th style="width: 30%">Name </th>
                <th style="width: 25%" class="text-center">Qty per unit</th>
                <th style="width: 25%" class="text-center">Qty Required</th>
                <th style="width: 20%" class="text-center">UOM </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let costing of radCosting?.test_cost_details?.cost_details">
                <td>{{costing?.name}}</td>
                <td class="text-center">{{costing?.unit_quantity}}</td>
                <td class="text-center">{{costing?.required_qty}}</td>
                <td class="text-center">{{costing?.uom}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="closeResultModal()"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-sm btn-primary">
            Acknowledge
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #cancelLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />
            Are you sure you want to cancel this services!<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="cancelLabTestFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #returnCancelLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" />
            Are you sure you want to Return this cancel services to
            Acknowlegdement<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="returnCancelLabTestFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Patient Discount Modal-->

<!-- Your modal HTML -->
<div
  class="modal fade"
  id="fullScreenModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  data-backdrop="false"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="labparameter">Adjustment V2</h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="hideDiscountModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isModal">
          <app-patient-discount-v2
            [invoiceId]="invoiceId"
            [patientId]="patientId"
            [isModal]="isModal"
            [cancelService]="'cancelService'"
            (isCloseModal)="hideModal($event)"
          ></app-patient-discount-v2>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="hideDiscountModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
