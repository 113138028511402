import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabRoutingModule } from './lab-routing.module';
import { LabReportsComponent } from './lab-reports/lab-reports.component';
import { LabReportsVerifyComponent } from './lab-reports-verify/lab-reports-verify.component';
import { LabReportsReprintComponent } from './lab-reports-reprint/lab-reports-reprint.component';

import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';
import { LabTestService } from '../../services/lab-test.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LabconfigurationService } from '../../services/labconfiguration.service';
import { LabTrackingReportComponent } from './reports/lab-tracking-report/lab-tracking-report.component';
import { ServiceService } from '../../services/service.service';
import { PathologistRemarksComponent } from './pathologist-remarks/pathologist-remarks.component';
import { DepartmentService } from '../../services/department.service';
import { EmployeeRegistrationService } from '../../services/employee-registration.service';
import { DoctorConfigurationService } from '../../services/doctor-configuration.service';
import { ExternalHospitalMaintenanceService } from '../../services/external-hospital-maintenance.service';
import { LinkLabParameterComponent } from './link-lab-parameter/link-lab-parameter.component';
import { LabParameterService } from '../../services/lab-parameter.service';
import { LinkLabParameterService } from '../../services/link-lab-parameter.service';
import { HospitaldoctorService } from '../../services/hospitaldoctor.service';
import { UserService } from '../../services/user.service';
import { CultureConfigService } from '../../services/culture-config.service';
import { PatientService } from '../../services/patient.service';
import { LabReportsUpdateComponent } from './lab-reports-update/lab-reports-update.component';
import { CultureConfigurationComponent } from './culture-configuration/culture-configuration.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PdfReportComponent } from './PDF-Report/pdf-report/pdf-report.component';
import { ShareDataService } from '../../services/share-data.service';
import { PdfServicesService } from '../../services/pdf-services.service';
import { LabProcessingComponent } from './lab-processing/lab-processing.component';
import { LabControlsV2Component } from './lab-controls-v2/lab-controls-v2.component';
import { LabTestV2Component } from './lab-test-v2/lab-test-v2.component';
import { LabReportPdfComponent } from './reports/lab-report-pdf/lab-report-pdf.component';
import { ReportCustomizeOneComponent } from './shared/report-customize-one/report-customize-one.component';
import { CultureReportComponent } from './shared/culture-report/culture-report.component';
import { PatientTypeService } from '../../services/patient-type.service';
import { ShareParameterComponent } from './share-parameter/share-parameter.component';
import { IpdModule } from '../ipd/ipd.module';
import { ParametersV2Component } from './parameters-v2/parameters-v2.component';
import { ConfigurationV2Component } from './configuration-v2/configuration-v2.component';
import { CultureConfigurationV2Component } from './culture-configuration-v2/culture-configuration-v2.component';
import { PatientModule } from '../patient/patient.module';
import { LabTestModalComponent } from './lab-test-modal/lab-test-modal.component';
import { PcrReportComponent } from './shared/pcr-report/pcr-report.component';
import { PcrConfigurationComponent } from './pcr-configuration/pcr-configuration.component';
import { LabTrackingReportV2Component } from './reports/lab-tracking-report-v2/lab-tracking-report-v2.component';
import { PcrModalComponent } from './shared/pcr-modal/pcr-modal.component';
import { CustomPipeModuleModule } from '../../custom-pipe-module/custom-pipe-module.module';
import {SharedModule} from '../shared-modules/shared.module';
import { LabCostingModalComponent } from './shared/lab-costing-modal/lab-costing-modal.component';

@NgModule({
  declarations: [
    LabReportsComponent,
    LabReportsVerifyComponent,
    LabReportsReprintComponent,
    LabTrackingReportComponent,
    PathologistRemarksComponent,
    LinkLabParameterComponent,
    LabReportsUpdateComponent,
    CultureConfigurationComponent,
    PdfReportComponent,
    LabProcessingComponent,
    LabControlsV2Component,
    LabReportPdfComponent,
    ReportCustomizeOneComponent,
    CultureReportComponent,
    LabTestV2Component,
    ShareParameterComponent,
    ParametersV2Component,
    ConfigurationV2Component,
    CultureConfigurationV2Component,
    LabTestModalComponent,
    PcrReportComponent,
    PcrConfigurationComponent,
    LabTrackingReportV2Component,
    PcrModalComponent,
    LabCostingModalComponent,
  ],
  imports: [
    CommonModule,
    LabRoutingModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FormsModule,
    ImageCropperModule,
    SharedModule,
    IpdModule,
    PatientModule,
    CustomPipeModuleModule,
  ],
  exports: [
    LabControlsV2Component,
    LabReportPdfComponent,
    ShareParameterComponent,
    ShareParameterComponent,
    LabTestModalComponent,
  ],
  providers: [
    DepartmentsbyhospitalService,
    LabTestService,
    LabconfigurationService,
    ServiceService,
    DepartmentService,
    EmployeeRegistrationService,
    DoctorConfigurationService,
    ExternalHospitalMaintenanceService,
    LabParameterService,
    LinkLabParameterService,
    HospitaldoctorService,
    UserService,
    CultureConfigService,
    PatientService,
    ShareDataService,
    PdfServicesService,
    PatientTypeService,
  ],
})
export class LabModule {}
