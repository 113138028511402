<div>
  <!--begin::Card-->
  <div class="card">
    <div class="card-body user-card-body">
      <div class="" style="position: static">
        <form
          [formGroup]="BirthCertificateForm"
          (ngSubmit)="saveBirthCertificate()"
        >
          <div class="row m-0 mb-7">
            <div class="col-md-12 col-sm-12">
              <h5 class="page-title text-align-left">Father Information</h5>
            </div>
            <div class="col-md-4 col-sm-4">
              <label> Father MRN </label>
              <div class="form-group">
                <input
                  type="text"
                  formControlName="father_id"
                  class="form-control form-control-sm"
                  placeholder="Father MRN"
                  [ngModel]="birth_certificate?.father_id"
                  (focusout)="familyPatient()"
                  (keypress)="_onlyNumeric($event)"
                  maxlength="8"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>First Name</label>
              <div class="form-group">
                <input
                  type="text"
                  formControlName="father_first_name"
                  class="form-control form-control-sm"
                  placeholder="First Name"
                  [ngModel]="birth_certificate?.father_first_name"
                  (keypress)="_keyPressOnlyChar($event)"
                />
                <!--                       [ngClass]="{ 'is-invalid': submitted && b.father_first_name.errors }"/>-->
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>Last name</label>
              <div class="form-group">
                <input
                  type="text"
                  formControlName="father_last_name"
                  class="form-control form-control-sm"
                  placeholder="Last Name"
                  [ngModel]="birth_certificate?.father_last_name"
                  (keypress)="_keyPressOnlyChar($event)"
                />
                <!--                       [ngClass]="{ 'is-invalid': submitted && b.father_last_name.errors }"/>-->
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>CNIC</label>
              <div class="form-group">
                <input
                  class="form-control form-control-sm"
                  formControlName="father_cnic"
                  [ngModel]="birth_certificate?.father_cnic"
                  placeholder="CNIC"
                  (keypress)="_onlyNumeric($event)"
                  maxlength="13"
                />
                <!--                       [ngClass]="{ 'is-invalid': submitted && b.father_cnic.errors }">-->
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>Profession</label>
              <div class="form-group">
                <input
                  class="form-control form-control-sm"
                  formControlName="profession"
                  [ngModel]="birth_certificate?.profession"
                  placeholder="Profession"
                />
                <!--                       [ngClass]="{ 'is-invalid': submitted && b.profession.errors }">-->
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>Contact Number</label>
              <div class="form-group">
                <input
                  class="form-control form-control-sm"
                  formControlName="phone_number"
                  [ngModel]="birth_certificate?.phone_number"
                  placeholder="Contact Number"
                  (keypress)="_onlyNumeric($event)"
                  maxlength="11"
                />
                <!--                       [ngClass]="{ 'is-invalid': submitted && b.phone_number.errors }">-->
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>Comments</label>
              <div class="form-group">
                <input
                  class="form-control form-control-sm"
                  formControlName="comments"
                  [ngModel]="birth_certificate?.comments"
                  placeholder="Comments"
                />
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 col-sm-12">
              <h5 class="page-title">Other Information</h5>
            </div>
            <div class="col-md-4 col-sm-4">
              <label> Last Menstruation Date </label>
              <div class="form-group">
                <input
                  type="date"
                  formControlName="last_menstruation_date"
                  [ngModel]="birth_certificate?.last_menstruation_date"
                  class="form-control form-control-sm"
                  max="{{ today }}"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>Baby Weight</label>
              <div class="form-group">
                <input
                  type="text"
                  formControlName="baby_weight"
                  class="form-control form-control-sm"
                  placeholder="Baby Weight"
                  [ngModel]="birth_certificate?.baby_weight"
                  (keypress)="
                    maxLengthOrAllowDecimal($event);
                    positiveNoWithDecimal($event, 3)
                  "
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>Gestational Age</label>
              <div class="form-group">
                <input
                  type="number"
                  formControlName="gestational_age"
                  class="form-control form-control-sm"
                  placeholder="Gestational Age"
                  [ngModel]="birth_certificate?.gestational_age"
                  (input)="daysOfBaby($event.target.value)"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>Doctor Conducting Delivery</label>
              <!--              <label>Mid Wife</label>-->
              <div class="form-group">
                <select
                  class="form-control form-control-sm doctorConductingDelivery"
                  data-container="body"
                  formControlName="doctor_conducting_delivery"
                  data-live-search="true"
                  multiple
                  [ngModel]="birth_certificate?.mid_wife"
                >
                  <ng-container *ngFor="let data of staffNurses">
                    <option [value]="data.id">{{ data?.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <label>Attending Paediatrician</label>
              <div class="form-group">
                <select
                  class="form-control form-control-sm doctorSelectpicker"
                  data-container="body"
                  formControlName="neonatal_doctor"
                  data-live-search="true"
                  multiple
                  [ngModel]="birth_certificate?.neonatal_doctor"
                >
                  <ng-container *ngFor="let d of doctors">
                    <option [value]="d.id" *ngIf="d?.gender === 'Female'">
                      {{ d.first_name }} {{ d.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>

          <div class="row m-0 mb-12">
            <div class="col-md-4 col-sm-4 col-lg-4">
              <label class="checkbox checkbox-sm">
                <input
                  type="checkbox"
                  #MIChkEnable
                  formControlName="meconium_aspiration_syndrome"
                  autocomplete="off"
                  [ngModel]="birth_certificate?.meconium_aspiration_syndrome"
                />
                <span class="mr-2"></span>Meconium Aspiration Syndrome
              </label>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-4">
              <label class="checkbox checkbox-sm">
                <input
                  type="checkbox"
                  #MIChkEnable
                  formControlName="doctor_not_attended_delivery"
                  autocomplete="off"
                  [ngModel]="birth_certificate?.doctor_not_attended_delivery"
                />
                <span class="mr-2"></span>Doctor not Attended Delivery
              </label>
            </div>
          </div>

          <div class="row m-0 mb-7">
            <div class="col-md-12 col-sm-12">
              <h5 class="page-title text-align-left">Other Information</h5>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                <label>Birth Type<span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  formControlName="birth_type"
                  [ngModel]="birth_certificate?.birth_type"
                  [ngClass]="{ 'is-invalid': submitted && b.birth_type.errors }"
                >
                  <option value="" [selected]="true" [disabled]="true">
                    Select Birth Type
                  </option>
                  <option value="Vaginal">Vaginal</option>
                  <option value="Natural">Natural</option>
                  <option value="Scheduled Cesarean">Scheduled Cesarean</option>
                  <option value="Unplanned Cesarean">Unplanned Cesarean</option>
                  <option value="Vaginal Birth after C-Section (VBAC)">
                    Vaginal Birth after C-Section (VBAC)
                  </option>
                  <option value="Scheduled Induction">
                    Scheduled Induction
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                <label>Caesarian Number</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="caesarian_number"
                  [ngModel]="birth_certificate?.caesarian_number"
                  placeholder="Caesarian Number"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                <label
                  >Rank of Pregnancy<span class="text-danger">*</span></label
                >
                <input
                  class="form-control form-control-sm"
                  formControlName="rank_of_pregnancy"
                  [ngModel]="birth_certificate?.rank_of_pregnancy"
                  placeholder="Rank of Pregnancy"
                  [ngClass]="{
                    'is-invalid': submitted && b.rank_of_pregnancy.errors
                  }"
                />
              </div>
            </div>
          </div>

          <div class="row m-0 mb-7 align-items-center">
            <div class="col-lg-2 col-md-2 col-sm-3 col-3">
              <div class="form-group">
                <label>Gender</label>
                <select
                  class="form-control form-control-sm"
                  data-live-search="true"
                  data-container="body"
                  id="gender"
                  formControlName="gender"
                >
                  <option value="">Select</option>
                  <ng-container *ngFor="let gender of gender">
                    <option [value]="gender.id">{{ gender.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3 col-3 mb-5">
              <label>Time of Birth</label>
              <input type="time" class="form-control form-control-sm" formControlName="time_of_birth">
            </div>
            <div class="col-lg-1 col-md-1 col-sm-3 col-3">
              <label>Mortality:</label>
            </div>

            <div class="col-md-2 col-sm-6 col-9" style="margin-top: 5px">
              <label class="switch">
                <input
                  type="checkbox"
                  formControlName="mortality"
                  [ngModel]="birth_certificate?.mortality" />
                <span class="slider round"> </span
              ></label>
            </div>

            <div class="col-lg-1 col-md-1 col-sm-3 col-3">
              <label>Morbidity:</label>
            </div>

            <div class="col-md-2 col-sm-6 col-9" style="margin-top: 5px">
              <label class="switch">
                <input
                  type="checkbox"
                  formControlName="morbidity"
                  [ngModel]="birth_certificate?.morbidity"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <div class="row m-0 mb-7 align-items-center"></div>

          <div class="row m-0 text-right">
            <div class="col-12">
              <button type="submit" class="btn btn-primary btn-sm">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--end::Card-->
</div>
