<ng-container *ngFor="let dataItem of checkListDetail; let i = index">
  <div
    *ngIf="dataItem?.check_list_temp_config.length > 0"
    style="border-bottom: 1px solid #a08d8d; margin-bottom: 21px; font-size: 15px; line-height: 40px"
  >
    <h3>{{ dataItem?.heading | titlecase }}</h3>
    <div
      class="row"
      *ngFor="
                  let data of dataItem?.check_list_temp_config;
                  let z = index
                "
    >
      <div
        class="col"
        *ngFor="
                    let item of data.check_list_template_configurators;
                    let j = index
                  "
      >
        <ng-container
          *ngIf="item.check_list_type === 'heading'"
        ></ng-container>
        <ng-container *ngIf="item.check_list_type === 'text_editor'">
          <ng-container *ngIf="item.value">
            <strong class="mr-2">{{ item.name | titlecase }}:</strong
            ><span  [innerHTML]="sanitizeHtml(item.value)"></span>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="item.check_list_type === 'all_employees_list'"
        >
          <ng-container *ngIf="item.employee_name">
            <strong class="mr-2">{{ item.name | titlecase }}:</strong>
            {{ item.employee_name }}
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="item.check_list_type === 'all_doctor_list'"
        >
          <ng-container *ngIf="item.employee_name">
            <strong class="mr-2">{{ item.name | titlecase }}:</strong>
            {{ item.employee_name }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="item.check_list_type === 'drop_down'">
          <ng-container *ngIf="item.value_name">
            <strong class="mr-2">{{ item.name | titlecase }}:</strong>
            {{ item.value_name }}
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="
                      item.check_list_type !== 'text_editor' &&
                      item.check_list_type !== 'all_employees_list' &&
                      item.check_list_type !== 'all_doctor_list' &&
                      item.check_list_type !== 'heading' &&
                      item.check_list_type !== 'drop_down'
                    "
        >
          <ng-container *ngIf="item.value">
            <strong class="mr-2">{{ item.name | titlecase }}:</strong>
            {{ item.value }}
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<div class="d-flex" style="margin-bottom: 10px" *ngIf="signature">
  <table class="info_table w-100">
    <tbody>
    <tr class="mt-5">
      <!--                <td style="width: 50%">-->
      <!--                  <strong>Patient/Attendant Signature</strong>-->
      <!--                </td>-->
      <td class="text-left">
        <div style="height: 100px">
          <img
            style="width: 150px"
            src="{{ signature }}"
          />
        </div>
        <div><strong>Patient/Attendant Signature</strong></div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
