import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  countries(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/countries?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  groups(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_groups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  companies(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_company_basic_info?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // group crud
  getGroupListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/group_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addGroup(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/group_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  rejectedLeave(value, remarks): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/direct_leave_rejection?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          remarks,
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateGroup(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/group_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificGroup(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/group_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // division crud
  getDivisonListing(page, perPage, value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/division_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          value,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addDivison(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/division_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDivison(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/division_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSpecificDivison(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/division_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // add company start
  addCompanyBasicInformation(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/company_basic_info?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCompanyBasicInformation(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/company_basic_info_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addAccountsInformation(value, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/company_account_info?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addToFav(id, is_bookmarked): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/user_bookmark_screens/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          is_bookmarked,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getFavScreen(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/user_bookmark_screens/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          // medical_unit_id: localStorage.getItem('current_medical_unit_id'), {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAccountsInformation(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/company_account_info?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addContactInformation(value, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/company_contact_info?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getContactInformation(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/company_contact_info?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCompanyListing(page, per_page, search_string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/company_basic_info?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addBankFacilityImage(value, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/company_bank_facility?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getBankFacilityImage(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/company_bank_facility?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // end company start
  getGroupDetailActiveList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_groups?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateCompanyBasicInformation(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/company_basic_info_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDivisionActiveList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_division_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateBranch(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/branch_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addBranch(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/branch_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getBranchListing(page, per_page, search_string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/branch_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addBranchById(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/branch_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeesOfSpecificDepartment(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_department_employee?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDepartmentEmployeeForRoster(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_department_employee_for_roster?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeesOfDepartment(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_department_all_employee?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchShiftListing(page, per_page, search_string, status, type): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/shift_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string +
          '&status=' +
          status +
          '&type=' +
          type,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchEmployeeAvailableShiftListing(id, date): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_available_shift?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&date=' +
          date +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateClinicNumber(value, id): Observable<any> {
    const results = this.http
      .put(
        environment.rorUrl +
          '/v4/clinics/' +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          code: value.code,
          is_active: value.is_active,
          country_id: value.country_id,
          state_id: value.state_id,
          city_id: value.city_id,
          location_id: value.location_id,
          address: value.address,
          contact_no: value.contact_no,
          focal_person: value.focal_person,
          focal_person_contact: value.focal_person_contact,
          comments: value.comments,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateClinicNumberv2(value, id, file1, file2, file3): Observable<any> {
    const body = new FormData();
    body.append('name', value.name);
    body.append('code', value.code);
    body.append('is_active', value.is_active);
    body.append('country_id', value.country_id);
    body.append('state_id', value.state_id);
    body.append('city_id', value.city_id);
    body.append('location_id', value.location_id);
    body.append('address', value.address);
    body.append('contact_no', value.contact_no);
    body.append('focal_person', value.focal_person);
    body.append('focal_person_contact', value.focal_person_contact);
    body.append('comments', value.comments);
    body.append('invoice_header_img', file1 || '');
    body.append('lab_report_header_img', file2 || '');
    body.append('lab_report_footer_img', file3 || '');
    const results = this.http
      .put(
        environment.pythonUrl +
          '/api/clinics/' +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'),
          body,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchShiftLocationListing(
    page,
    per_page,
    search_string,
    status,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/shift_location_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addShiftLocationData(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/shift_location_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateShiftLocationData(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/shift_location_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addShiftData(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/shift_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateShiftData(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/shift_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addClinicToDepartment(clinicId, id, type): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/clinics/assign_clinic?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          clinic_id: clinicId,
          id,
          type,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addClinicNumber(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/clinics?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          code: value.code,
          is_active: value.is_active,
          country_id: value.country_id,
          state_id: value.state_id,
          city_id: value.city_id,
          location_id: value.location_id,
          address: value.address,
          contact_no: value.contact_no,
          focal_person: value.focal_person,
          focal_person_contact: value.focal_person_contact,
          comments: value.comments,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addClinicNumberv2(value, file1, file2, file3): Observable<any> {
    const body = new FormData();
    body.append('name', value.name);
    body.append('code', value.code);
    body.append('is_active', value.is_active);
    body.append('country_id', value.country_id);
    body.append('state_id', value.state_id);
    body.append('city_id', value.city_id);
    body.append('location_id', value.location_id);
    body.append('address', value.address);
    body.append('contact_no', value.contact_no);
    body.append('focal_person', value.focal_person);
    body.append('focal_person_contact', value.focal_person_contact);
    body.append('comments', value.comments);
    body.append('invoice_header_img', file1 || '');
    body.append('lab_report_header_img', file2 || '');
    body.append('lab_report_footer_img', file3 || '');
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/clinics/?auth_token=` +
          localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'),
          // medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          // name: value.name,
          // code: value.code,
          // is_active: value.is_active,
          // country_id: value.country_id,
          // state_id: value.state_id,
          // city_id: value.city_id,
          // location_id: value.location_id,
          // address: value.address,
          // contact_no: value.contact_no,
          // focal_person: value.focal_person,
          // focal_person_contact: value.focal_person_contact,
          // comments: value.comments,
          body,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitDefault(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/clinics/set_default/?auth_token=` +
        localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'),
        {
          is_default: value.is_default,
          id: value.screen_id,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getClinicList(status): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/clinics?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getClinicListv2(status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/clinics/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllocatedBranches(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/clinics/allocated_emp_branches/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getShiftLocationdata(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/shift_location_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getShiftdata(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/shift_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateProjectBasicInformation(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/project_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addProjectBasicInformation(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/project_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getBranchActiveList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/get_branch_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getProjectListing(page, per_page, search_string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/project_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProjectBasicInformation(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/project_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addProjectDurationInformation(value, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/project_duration_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getProjectDuration(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/project_duration_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCompanyContactInformation(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/project_contact_information?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addCompanyContactInformation(value, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/project_contact_information?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDayWiseSalarySetting(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/day_wise_salary_setting?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitDayWiseSalarySetting(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/day_wise_salary_setting?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitJobType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/staff_type?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getJobType(page, per_page, search_string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/staff_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getJobTypeDataById(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/staff_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateJobType(id, value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/staff_type_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitEmpCat(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_category?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmpCat(page, per_page, search_string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_category?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmpCatDataById(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_category_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEmpCat(id, value): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/employee_category_detail?id=` +
          id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getEmpCategory(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_category?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getStaffType(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/staff_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPACSUrl(): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/radiology_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPACSUrlV2(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/radiology_configurators/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPACSUrlPublicV2(): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/public_radiology_configurator/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addPACSUrl(value): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/radiology_configurators?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id: value.id,
          radiant_url: value.radiant_url,
          web_url: value.web_url,
          default_pacs: value.default_pacs,
          header_height: value.header_height,
          footer_height: value.footer_height,
          rad_status: value.rad_status,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  addPACSUrlV2(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/api/radiology_configurators/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id: value.id,
          radiant_url: value.radiant_url,
          web_url: value.web_url,
          default_pacs: value.default_pacs,
          header_height: value.header_height,
          footer_height: value.footer_height,
          rad_status: value.rad_status,
          show_worklist: value.show_worklist,
          show_service_name_on_radiology: value.show_service_name_on_radiology,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  addExpenses(expense): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/expenses?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          expense,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addExpensesV2(expense): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/expenses/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          expense,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getExpenses(fromDate, toDate): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/expenses?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getExpensesV2(
    fromDate,
    toDate,
    transaction,
    configurator_type,
  ): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/expenses?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&transaction=' +
          transaction +
          '&configurator_type=' +
          configurator_type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getConfigTypes(type): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/configurator_types_for_expense?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&configurator_type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  submitAccountIdentifier(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/finance/account_group_identifier/`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          priority: value.priority,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAccountIdentifier(): Observable<any> {
    const results = this.http
      .get(`${environment.pythonUrl}/finance/account_group_identifier/`, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('auth_token'),
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
    return results;
  }

  updateAccountIndentifier(id: any, value: any): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/finance/account_group_identifier/` + id + `/`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          name: value.name,
          priority: value.priority,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitShowMedicinesInIpd(value, showMedicineID): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/ipd/ipd_system_control/` +
          showMedicineID +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          show_medicine_status: value.show_medicine_status,
          is_medicine_manual_input: value.is_medicine_manual_input,
          show_req_med_directly_on_admin: value.show_req_med_directly_on_admin,
          auto_approve_medicine_request: value.auto_approve_medicine_request,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitShowMedicinesInOpd(value, showMedicineID): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/system_admin_opd_control/` +
          showMedicineID +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          show_medicine_status: value.show_medicine_status,
          is_medicine_manual_input: value.is_medicine_manual_input,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getShowMedicinesInIpd(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/ipd_system_control?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getShowMedicinesInOpd(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_admin_opd_control?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitTokenTimeIntervalInOpd(value, showgeneralID): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/system_admin_opd_control/` +
          showgeneralID +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          que_manage_refresh_token_time: value.time_interval,
          visit_expiry_time: value.visit_expiry_time,
          reset_token_time: value.reset_token_time,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getTokenRefreshTimeInOpd(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_admin_opd_control?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
