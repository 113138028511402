import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfiguratorsService } from '../../../../services/configurators.service';
import { DepartmentsbyhospitalService } from '../../../../services/departmentsbyhospital.service';
import { EmployeeVisitService } from '../../../../services/employee-visit.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../../../../services/configuration.service';
import { FinanceConfigurationService } from '../../../../services/finance-configuration.service';
import { ShareDataService } from '../../../../services/share-data.service';
import { LocationService } from '../../../../services/location.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-add-employee-visit',
  templateUrl: './add-employee-visit.component.html',
  styleUrls: ['./add-employee-visit.component.css'],
})
export class AddEmployeeVisitComponent implements OnInit {
  employeeVisitForm: UntypedFormGroup;
  isAdd = false;
  title: any;
  genderDetail: any;
  designation: any;
  depart: any;
  visitId: any;
  StaffType: any;
  allEmployeesStaffTypeDoctor = [];
  countries = [];
  states = [];
  cities = [];
  country_edit_id: any;
  state_edit_id: any;
  city_edit_id: any;
  countryStateCityData: any;

  constructor(
    private fb: UntypedFormBuilder,
    private configuratorsService: ConfiguratorsService,
    private departmentsByHospital: DepartmentsbyhospitalService,
    private visitService: EmployeeVisitService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    private financeConfigService: FinanceConfigurationService,
    private shareDataService: ShareDataService,
    private locationService: LocationService,
    private ref: ChangeDetectorRef,
  ) {
    this.employeeVisitForm = this.fb.group({
      title: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: [''],
      gender: ['', Validators.required],
      speciality: [''],
      nic: [''],
      primary_contact: [''],
      pmdc_registration_no: [''],
      office_number: [''],
      mobile_number: [''],
      hospital_name: [''],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      staff_type: ['', Validators.required],
      address: ['', Validators.required],
      department: ['', Validators.required],
      designation: ['', Validators.required],
      status: ['', Validators.required],
    });
  }
  onSubmit(): any {
    this.isAdd = true;
    if (this.employeeVisitForm.invalid) {
      return;
    }
    const str = this.employeeVisitForm.get('nic').value;
    const firstHalf = str.slice(0, 13);
    const secondHalf = str.slice(13, 14);
    if (firstHalf !== '' && secondHalf !== '-') {
      this.employeeVisitForm.get('nic').setValue(firstHalf + '-' + secondHalf);
    }
    if (this.visitId) {
      this.ngxLoader.start();
      this.visitService
        .updateVisitForm(this.employeeVisitForm.value, this.visitId)
        .subscribe(
          (data) => {
            this.isAdd = false;
            this.ngxLoader.stop();
            this.router.navigate(['/employee-visiting/list']);
            toastr.success(' update Successfully');
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.error);
          },
        );
    } else {
      this.ngxLoader.start();
      this.visitService.submitVisitForm(this.employeeVisitForm.value).subscribe(
        (data) => {
          this.isAdd = false;
          this.ngxLoader.stop();
          toastr.success('Successfully Submitted');
          this.router.navigate(['/employee-visiting/list']);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
    }
  }

  getSpecificEmployee(): any {
    // this.ngxLoader.start();
    this.visitService.getSpecificEmployee(this.visitId).subscribe(
      (data) => {
        this.countryStateCityData = data;
        this.country_edit_id = data.country;
        this.state_edit_id = data.state;
        this.city_edit_id = data.city;
        this.employeeVisitForm.patchValue({
          title: data.title,
          first_name: data.first_name,
          last_name: data.last_name,
          gender: data.gender,
          speciality: data.speciality,
          nic: data.nic,
          pmdc_registration_no: data.pmdc_registration_no,
          primary_contact: data.primary_contact,
          office_number: data.office_number,
          mobile_number: data.mobile_number,
          hospital_name: data.hospital_name,
          country: data.country,
          state: data.state,
          city: data.city,
          address: data.address,
          department: data.department,
          staff_type: data.staff_type,
          designation: data.designation,
          status: data.status,
        });
        setTimeout((e) => {
          $('#department').selectpicker('refresh');
          $('#speciality').selectpicker('refresh');
          $('#designation').selectpicker('refresh');
          $('#title').selectpicker('refresh');
          $('#gender').selectpicker('refresh');
          this.ngxLoader.stop();
        }, 50);
      },
      (err) => {
        toastr.error(err.error.error);
        this.ngxLoader.stop();
      },
    );
  }

  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getDepartments(): any {
    this.departmentsByHospital.getOpdDepartmentsListV2().subscribe((data) => {
      this.depart = data.departments;
      setTimeout(() => {
        $('#department').selectpicker('refresh');
        $('#department').selectpicker();
      }, 500);
    });
  }

  configuratorDetails(): any {
    this.configuratorsService.fetchConfigurationV2('gender').subscribe(
      (data) => {
        this.genderDetail = data.configurator_types;
        setTimeout(() => {
          $('#gender').selectpicker('refresh');
          $('#gender').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.message.message);
      },
    );
    this.configuratorsService
      .fetchConfigurationV2('designation')
      .subscribe((data) => {
        this.designation = data.configurator_types;
        setTimeout(() => {
          $('#designation').selectpicker('refresh');
          $('#designation').selectpicker();
        }, 500);
      });
    this.configuratorsService.fetchConfigurationV2('title').subscribe(
      (data) => {
        this.title = data.configurator_types;
        setTimeout(() => {
          $('#title').selectpicker('refresh');
          $('#title').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.message.message);
      },
    );
  }
  // Get staff type
  getStaffType(): any {
    this.configurationService.getStaffType().subscribe(
      (data) => {
        this.StaffType = data;
        if (this.StaffType.length) {
          setTimeout((e) => {
            $('.selectPickerEmployeeRoles').selectpicker();
            $('#staff_type').selectpicker('refresh');
            $('#staff_type').selectpicker();
          }, 500);
        }
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getEmployeesDoctorWise(): any {
    this.allEmployeesStaffTypeDoctor = [];
    this.financeConfigService.getAllActiveEmployees().subscribe(
      (data) => {
        this.allEmployeesStaffTypeDoctor = data;
        setTimeout(() => {
          $('#speciality').selectpicker();
          $('#speciality').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  getCountryData(): any {
    this.locationService.getCountries().subscribe(
      (data) => {
        if (data) {
          this.countries = data.countries;
          if (this.visitId){
            const countryId = this.countries.find((t) => t.id = this.countryStateCityData?.country);
            this.employeeVisitForm.get('country').patchValue(countryId.id);
            this.getCountryStates(countryId.id, true);
          } else {
            this.employeeVisitForm.get('country').patchValue('');
          }
          setTimeout(() => {
            $('#countryId').selectpicker('refresh');
          }, 500);
        }
      },
      (err) => {},
    );
  }

  getCountryStates(value, status): any {
    this.states = [];
    this.ngxLoader.start();
    this.locationService.getCountryState(value).subscribe(data => {
      this.states = data?.states;
      if (this.visitId && status){
        const stateId = this.states.find((t) => t.id = this.countryStateCityData?.state);
        this.employeeVisitForm.get('state').patchValue(stateId.id);
        this.getStateCities(stateId.id, true);
      } else {
        this.employeeVisitForm.get('state').patchValue('');
      }
      setTimeout(() => {
        $('#stateId').selectpicker('refresh');
      }, 500);
      this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
      toastr.error(err.error.message);
    });
  }
  getStateCities(value, status): any {
    this.cities = [];
    this.ngxLoader.start();
    this.locationService.getStateCity(value).subscribe(data => {
      this.cities = data?.cities;
      if (this.visitId && status){
        const cityId = this.states.find((t) => t.id = this.countryStateCityData?.city);
        this.employeeVisitForm.get('city').patchValue(cityId.id);
      } else {
        this.employeeVisitForm.get('city').patchValue('');
      }
      setTimeout(() => {
        $('#cityId').selectpicker('refresh');
      }, 500);
      this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
      toastr.error(err.error.message);
    });
  }

  ngOnInit(): void {
    this.visitId = Number(this.route.snapshot.queryParamMap.get('id'));
    this.getStaffType();
    this.configuratorDetails();
    this.getDepartments();
    this.getEmployeesDoctorWise();
    if (this.visitId) {
      this.getSpecificEmployee();
    }
    this.getCountryData();
    $('#nic').mask('99999-9999999-9');
  }
}
