import { Component, OnInit } from '@angular/core';
import { TaskManagementService } from '../../../services/task-management.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { ConfigurationService } from '../../../services/configuration.service';
import { RosterServiceService } from '../../../services/roster-service.service';
import { RouteShareService } from '../../../services/route-share.service';
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-employees-timesheet',
  templateUrl: './employees-timesheet.component.html',
  styleUrls: ['./employees-timesheet.component.css'],
})
export class EmployeesTimesheetComponent implements OnInit {
  allTasks = [];
  weekDuration = 'current';
  fromDate: any;
  toDate: any;
  employeesOfDepart: any;
  employees = '';
  department = '';
  allDepartments: any;
  locationData = [];
  draggedItemIndex: number;
  currentDateIndex: number;
  screenName = '';
  employeeData: any;
  taskId: any;
  employeeLogsData: any;
  isMainModalOpen = true;
  openAddTaskModal = false;

  constructor(
    private task: TaskManagementService,
    private ngxLoader: NgxUiLoaderService,
    private configurationService: ConfigurationService,
    private rosterServiceService: RosterServiceService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.getDepartments();
  }

  ngOnInit(): void {
    // this.callMainFunction();
    this.selectWeekDuration();
    // this.getEmployeesOfDepartment();
    this.department = '';
  }
  callMainFunction() {
    const today = moment();
    let startOfWeek;
    let endOfWeek;
    startOfWeek = today.clone().startOf('isoWeek');
    endOfWeek = startOfWeek.clone().add(4, 'days');
    this.fromDate = startOfWeek.format('YYYY-MM-DD');
    this.toDate = endOfWeek.format('YYYY-MM-DD');
    setTimeout((e) => {
      this.getEmployeeAllTasks();
    }, 500);
  }
  selectWeekDuration() {
    const today = moment();
    let startOfWeek;
    let endOfWeek;
    if (this.weekDuration === 'current') {
      startOfWeek = today.clone().startOf('isoWeek');
      endOfWeek = startOfWeek.clone().add(4, 'days');
    } else if (this.weekDuration === 'next') {
      startOfWeek = today.clone().startOf('isoWeek').add(7, 'days');
      endOfWeek = startOfWeek.clone().add(4, 'days'); // 4 days from Monday is Friday
    } else if (this.weekDuration === 'previous') {
      startOfWeek = today.clone().startOf('isoWeek').subtract(7, 'days');
      endOfWeek = startOfWeek.clone().add(4, 'days'); // 4 days from Monday is Friday
    } else {
    }
    this.fromDate = startOfWeek.format('YYYY-MM-DD');
    this.toDate = endOfWeek.format('YYYY-MM-DD');
  }
  addTask(event: any) {
    if (event === 'true') {
      this.callMainFunction();
      $('#viewTaskModal').modal('hide');
    } else {
      this.callMainFunction();
    }
  }
  getEmployeeAllTasks(): any {
    this.ngxLoader.start();
    this.task
      .getEmployeeAllTasks(
        this.fromDate,
        this.toDate,
        this.employees,
        this.department,
      )
      .subscribe(
        (data) => {
          this.allTasks = data;
          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
            this.ngxLoader.stop();
          }, 100);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.allDepartments = data.department;
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  getEmployeesOfDepartment(): any {
    if (this.department === undefined) {
      this.department = '';
    }
    this.employeesOfDepart = [];
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getEmployeesOfSpecificDepartment(this.department)
      .subscribe(
        (data) => {
          this.employeesOfDepart = data;
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
            $('#employeeDepartmentList').selectpicker();
          }, 500);
        },
        (err) => {
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
          }, 500);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  openModal(id): any {
    this.taskId = id;
    this.openAddTaskModal = true;
    $('#taskFile').val('');
    if (!this.taskId) {
      $('#medicationsAllergies').val(null).trigger('change');
    }
    $('#viewTaskModal').modal('show');
  }

  closeAddTaskModal(): any{
    this.openAddTaskModal = false;
    $('#viewTaskModal').modal('hide');
  }

  openTimeSheetDetailModal(data): any{
    this.employeeData = data;
    $('#timeSheetDetailModal').modal('show');
    this.getEmployeeTimeLog();
  }

  closeTimeSheetDetailModal(): any{
    $('#timeSheetDetailModal').modal('hide');
  }

  getEmployeeTimeLog(): any {
    this.ngxLoader.start();
    this.task
      .getEmployeeTimeLog(
        this.fromDate,
        this.toDate,
        this.employeeData,
      )
      .subscribe(
        (data) => {
          this.employeeLogsData = data;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
}
