import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-check-list-print',
  templateUrl: './check-list-print.component.html',
  styleUrls: ['./check-list-print.component.css']
})
export class CheckListPrintComponent implements OnInit {
  @Input() checkListDetailData: any[];
  @Input() signature: any;
  // checkListDetailArray: any[] = [];
  checkListDetail: any[] = [];

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    console.log('checkListDetail', this.checkListDetail)
    this.setCheckListData(this.checkListDetailData);
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log('changes', changes);
  //   this.setCheckListData(changes.currentValue);
  // }
  setCheckListData(checkListDetailData){
    console.log('checkListDetailData', checkListDetailData);
    this.checkListDetail = checkListDetailData.filter(dataItem =>
      dataItem.check_list_temp_config?.some(checkData =>
        checkData.check_list_template_configurators?.some(item =>
          item.value || item.employee_name || item.value_name
        )
      )
    );
  }

  sanitizeHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
