import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DepartmentsByHospitalService } from '../../../services/departments-by-hospital.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { EmployeeSelfServiceService } from '../../../services/employee-self-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { OpdService } from '../../../services/opd.service';
import { ConfiguratorsService } from '../../../services/configurators.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-patient-documents',
  templateUrl: './patient-documents.component.html',
  styleUrls: ['./patient-documents.component.css'],
})
export class PatientDocumentsComponent implements OnInit {
  @ViewChild('patientImageModal') patientImageModal: ElementRef;
  @Input() patientData: any;

  locationData: any;
  depart: any;
  selectedLocation = [];
  employeeId: any;
  docTypes = [];
  Image = [];
  disableSubmitButton = false;
  patientDocs = [];
  patient: any;
  textareas = '';
  currentImageIndex: number;
  currentImage = '';
  docType = '';
  reportsObject: UntypedFormGroup;
  imageDetail: any;
  arraylist: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private opdService: OpdService,
    private configuratorService: ConfiguratorsService,
    private modalService: NgbModal,
  ) {
    this.reportsObject = this.formBuilder.group({
      docType: '',
      document_name: '',
      files: '',
      text_areas: '',
    });
  }

  ngOnInit(): void {
    this.getDocTypes();
    this.getPatDocs();
    // $('#textareas').summernote();
    // $('#textareas').summernote({
    //   height: '100px',
    //   callbacks: {
    //     onInit: () => {
    //       $('.note-icon-video').parent().remove();
    //       $('.note-icon-picture').parent().remove();
    //       $('.note-icon-table').parent().remove();
    //     }
    //   }
    // });
  }

  uploadImage(): any {
    if (this.reportsObject.get('docType').value === '') {
      toastr.error('Select Document Type');
      return;
    }
    if (this.reportsObject.get('document_name').value === '') {
      toastr.error('Add Document Name');
      return;
    }
    this.reportsObject.get('files').setValue(this.Image);
    // this.reportsObject.get('text_areas').setValue($('#textareas').summernote('code'));
    if (this.reportsObject.get('files').value.length === 0) {
      toastr.error('Select Files');
      return;
    }
    this.disableSubmitButton = true;
    console.log('this.reportsObject.get23', this.reportsObject.value);
    this.ngxLoader.start();
    this.opdService
      .uploadPatientDocumentsWhileRegistration(
        this.reportsObject.value,
        this.patientData.id,
      )
      .subscribe(
        (data) => {
          this.disableSubmitButton = false;
          this.ngxLoader.stop();
          if (data.message) {
            toastr.success(data.message);
            this.getPatDocs();
            // this.docType = '';
            this.Image = [];
            $('#file').val('');
            this.reportsObject.reset({
              docType: '',
            });
            // $('#textareas').summernote('code', '');
          }
        },
        (error) => {
          this.ngxLoader.stop();
          this.disableSubmitButton = false;
        },
      );
  }

  getDocTypes(): any {
    this.docTypes = [];
    this.ngxLoader.start();
    this.configuratorService.getDocTypesV2('true').subscribe(
      (data) => {
        this.docTypes = data.document_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  openDocuments(url): any {
    window.open(url);
  }

  getPatDocs(): any {
    this.patientDocs = [];
    this.ngxLoader.start();
    this.opdService
      .getPatientDocumentsWhileRegistration(this.patientData.id)
      .subscribe(
        (data) => {
          if (data.message) {
            toastr.success(data.message);
          }
          if (data) {
            this.patientDocs = data;
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  onFileChange(event): any {
    for (let i = 0; i < event.target.files.length; i++) {
      this.Image.push(event.target.files[i]);
      if (event.target.files.length === i + 1) {
        $('#file').val('');
      }
    }
  }

  removeFileObject(index: any) {
    this.Image.splice(index, 1);
  }

  openDoc(url: any, index, id): any {
    if (url) {
      this.imageDetail = this.patientDocs.find((t) => t.id === Number(id));
      this.currentImage = url;
      this.currentImageIndex = index;
      this.modalService.open(this.patientImageModal, { size: 'lg' });
    }
  }

  closePatImgModal(): any {
    this.currentImage = '';
    this.modalService.dismissAll(this.patientImageModal);
  }

  openDetailModal(id): any {
    this.imageDetail = this.patientDocs.find((t) => t.id === Number(id));
    $('#detailModal').modal('show');
  }

  fun(i): any {
    this.arraylist = this.patientDocs[i].name;
  }
}
