<div class="card card-custom mb-5">
  <div
    class="text-right cursor-pointer mt-2 mb-2 mr-4"
    style="text-decoration: underline; color: #3699ff"
  >
    <span *ngIf="showCostingDetail" (click)="showHIdeCostingDetail()"
    >Hide Costing Detail</span
    >
    <span *ngIf="!showCostingDetail" (click)="showHIdeCostingDetail()"
    >Show Costing Detail</span
    >
  </div>
  <div *ngIf="showCostingDetail" class="card-body">
    <div>
      <table class="table">
        <thead>
        <tr>
          <th style="width: 30%">Name </th>
          <th style="width: 25%" class="text-center">Qty per unit</th>
          <th style="width: 25%" class="text-center">Qty Required</th>
          <th style="width: 20%" class="text-center">UOM </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let costing of addReportsCostingDetail?.test_cost_details?.cost_details">
          <td>{{costing?.name}}</td>
          <td class="text-center">{{costing?.unit_quantity}}</td>
          <td class="text-center">{{costing?.required_qty}}</td>
          <td class="text-center">{{costing?.uom}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
