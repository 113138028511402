import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CultureConfigService } from '../../../../services/culture-config.service';
import { LabTestService } from '../../../../services/lab-test.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-pcr-modal',
  templateUrl: './pcr-modal.component.html',
  styleUrls: ['./pcr-modal.component.css'],
})
export class PcrModalComponent implements OnInit {
  @Output() updatedData: EventEmitter<any> = new EventEmitter<any>();
  @Output() closePcrModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() pcrListData: any;
  @Input() cultureList: any;
  @Input() pcrData: any;
  @Input() detectorList: any;
  @Input() organismList: any;
  @Input() labResultEntryAllow: boolean;
  cultureForm: UntypedFormGroup;
  pcrRemarks = '';
  antibioticIds = [];
  resultSubmitBtn = false;
  showCostingDetailModal = false;
  labTestName = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private cultureConfigService: CultureConfigService,
    private labTestService: LabTestService,
  ) {
    this.cultureForm = this.formBuilder.group({
      introduction: [''],
      result_interpretation: [''],
      limitations: [''],
      lab_test_detail_id: [''],
      detector: this.formBuilder.array([]),
      organism: this.formBuilder.array([]),
    });
  }

  addFieldsInCulture(type): any {
    if (type === 'organism') {
      this.organism.push(this.addOrganismValue(''));
    }
    if (type === 'detector') {
      this.detector.push(this.addDetectorValue(''));
      const ind = this.detector.length - 1;
      $('#detector' + ind).selectpicker('refresh');
      $('#anti_biotic' + ind).selectpicker('refresh');
      setTimeout(() => {
        $('#detector' + ind).selectpicker();
        $('#anti_biotic' + ind).selectpicker();
      }, 50);
    }
  }
  removeCultureIndex(type, ind): any {
    if (type === 'organism') {
      this.organism.removeAt(ind);
    }
    if (type === 'detector') {
      this.detector.removeAt(ind);
    }
  }
  get detector(): any {
    return this.cultureForm.get('detector') as UntypedFormArray;
  }
  get organism(): any {
    return this.cultureForm.get('organism') as UntypedFormArray;
  }
  addOrganismValue(data): any {
    return this.formBuilder.group({
      organism_name: [data.organism_name || ''],
      organism_id: [data.organism_id || ''],
      organism_result: [
        this.labTestName.includes('wound')
          ? data.wound_result
          : data.organism_result || '',
      ],
      organism_type: [data.organism_type || ''],
      batch_no: [data.batch_no || ''],
      organism_comments: [data.organism_comments || ''],
      organism_short_name: [data.organism_short_name || ''],
    });
  }
  addDetectorValue(data): any {
    console.log('data?.detector_ids', data?.detector_ids);
    return this.formBuilder.group({
      anti_biotic: [data.anti_biotic || ''],
      detector_ids: [data?.detector_ids],
      detector_result: [
        this.labTestName.includes('wound')
          ? data.wound_result
          : data.detector_result || '',
      ],
      batch_no: [data.batch_no || ''],
      // detector_comments: [data.detector_comments || ''],
      // detector_name: [data.detector_name || ''],
      // detector_short_name: [data.detector_short_name || ''],
    });
  }
  getOrganismValues(id, i): any {
    if (id) {
      const organism = this.organismList.find((t) => t.id === Number(id));
      this.organism.at(i).get('organism_name').setValue(organism.name);
      this.organism
        .at(i)
        .get('organism_comments')
        .setValue(
          this.labTestName.includes('wound')
            ? organism?.wound_comments
            : organism.comments,
        );
      this.organism
        .at(i)
        .get('organism_short_name')
        .setValue(organism.short_name);
    }
  }
  getDetectorValues(id, i): any {
    if (id) {
      const detector = this.detectorList.find((t) => t.id === Number(id));
      this.detector.at(i).get('detector_name').setValue(detector.name);
      this.detector.at(i).get('detector_comments').setValue(detector.comments);
      this.detector
        .at(i)
        .get('detector_short_name')
        .setValue(detector.short_name);
    }
  }
  updatePcrRemarks(value): any {
    this.pcrRemarks = value;
    $('#pcrRemark').val(value);
  }
  getPcrData(): any {
    const labTestName = this.pcrListData?.lab_investigation?.profile_name;
    this.labTestName = labTestName.toLowerCase();
    if (this.pcrListData.lab_test_details) {
      if (this.pcrListData.lab_test_details.length > 0) {
        this.pcrRemarks =
          this.pcrListData.lab_test_details[0].pathologist_remarks;
      }
    }
    while (this.detector.length) {
      this.detector.removeAt(0);
    }
    while (this.organism.length) {
      this.organism.removeAt(0);
    }
    if (this.pcrListData.pcr_report_details) {
      this.cultureForm.patchValue({
        introduction: this.pcrListData?.pcr_report_details?.introduction,
        result_interpretation:
          this.pcrListData?.pcr_report_details?.result_interpretation,
        limitations: this.pcrListData?.pcr_report_details?.limitations,
      });
      if (this.pcrListData.pcr_report_details.detector) {
        for (
          let j = 0;
          j < this.pcrListData.pcr_report_details.detector.length;
          j++
        ) {
          this.detector.push(
            this.addDetectorValue(
              this.pcrListData.pcr_report_details.detector[j],
            ),
          );
          setTimeout(() => {
            $('#detector' + j).selectpicker('refresh');
            $('#detector' + j).selectpicker();
            $('#anti_biotic' + j).selectpicker('refresh');
            $('#anti_biotic' + j).selectpicker();
          }, 500);
          if (
            this.pcrListData?.status === 'Received' &&
            this.labResultEntryAllow
          ) {
            this.getAntiBiotic(j);
          }
        }
      }
      if (this.pcrListData.pcr_report_details.organism) {
        for (
          let j = 0;
          j < this.pcrListData.pcr_report_details.organism.length;
          j++
        ) {
          this.organism.push(
            this.addOrganismValue(
              this.pcrListData.pcr_report_details.organism[j],
            ),
          );
        }
        if (
          this.pcrListData?.status === 'Received' &&
          this.labResultEntryAllow
        ) {
          this.getTextAccordingToTest();
        }
      }
    }
  }
  getAntiBiotic(i): any {
    this.antibioticIds = [];
    const detectorIds = this.detector.at(i).get('detector_ids').value;
    detectorIds.forEach((id: number) => {
      const detector = this.detectorList.find((item) => item.id === id);
      if (detector && detector.anti_biotic.length > 0) {
        this.antibioticIds = this.antibioticIds.concat(detector.anti_biotic);
      }
    });
    let uniqueAntiBiotic = [...new Set(this.antibioticIds)];
    console.log('uniqueAntiBiotic', uniqueAntiBiotic);
    this.detector.at(i).get('anti_biotic').setValue(uniqueAntiBiotic);
    setTimeout(() => {
      $('#anti_biotic' + i).selectpicker('refresh');
      $('#anti_biotic' + i).selectpicker();
    }, 500);
  }
  getTextAccordingToTest(): any {
    const organismPositiveResult = this.organism.value.filter(
      (t) => t.organism_result === 'Positive',
    );
    const organismNegativeResult = this.organism.value.find(
      (t) => t.organism_result === 'Negative',
    );
    const filteredResults = this.detector.value.filter(
      (t) => t.detector_result === 'R',
    );
    if (this.labTestName.includes('wound')) {
      let woundIntro =
        'The Wound Assay used detects specific gene targets from ' +
        this.organism.length +
        ' common pathogens in wound specimens obtained with a swab from individuals with wound infections. \n ';
      let woundResultInterpretation = '';
      if (organismPositiveResult.length) {
        woundIntro +=
          'The Drug Resistance Panel used is a real-time fluorescent isothermal PCR assay intended for the qualitative detection of drug resistance gene markers. \n ';
        woundResultInterpretation =
          'The patient wound swab specimen tested POSITIVE for the ';
        for (let i = 0; i < this.organism.value.length; i++) {
          if (this.organism.value[i].organism_result === 'Positive') {
            woundResultInterpretation +=
              this.organism.value[i].organism_name + ', ';
          }
        }
      }
      // if (filteredResults.length) {
      if (organismNegativeResult && !organismPositiveResult.length) {
        woundResultInterpretation +=
          ' The ' +
          this.organism.length +
          ' pathogens targeted with the assay were not detected in the patient’s wound swab specimen. The test result is NEGATIVE.';
      }
      if (filteredResults.length) {
        woundResultInterpretation +=
          '\nDrug resistance panel showed that the patient is RESISTANT to ';
        for (let i = 0; i < this.detector.value.length; i++) {
          if (this.detector.value[i].detector_result === 'R') {
            for (
              let j = 0;
              j < this.detector.value[i].anti_biotic.length;
              j++
            ) {
              const antiBiotic = this.cultureList.find(
                (t) => t.id === this.detector.value[i].anti_biotic[j],
              );
              woundResultInterpretation += antiBiotic?.name ?? ' ';
              woundResultInterpretation += ', ';
            }
            woundResultInterpretation += ' (';
            for (
              let k = 0;
              k < this.detector.value[i].detector_ids.length;
              k++
            ) {
              const detector = this.detectorList.find(
                (t) => t.id === this.detector.value[i].detector_ids[k],
              );
              woundResultInterpretation += detector?.name;
              if (k < this.detector.value[i].detector_ids.length - 1) {
                woundResultInterpretation += ', ';
              } else {
                woundResultInterpretation += ') ';
              }
            }
          }
        }
      }
      // }
      let limitation =
        'Performance of the Wound Panel Assay for the ' +
        this.organism.length +
        ' common pathogens was established for wound specimens only. Other specimen types have not been evaluated. \n ' +
        'Negative results do not preclude infection with viral or other bacterial or fungal microorganisms that are not listed in the Wound Panel Assay. \n ' +
        'Negative results of the Drug Resistance Panel do not preclude resistance to antibiotics as there may be other mechanisms of treatment failure. Therefore, negative results should not be used as the sole basis for patient management decisions. Negative results must be combined with clinical observations, patient history, and epidemiological information. ';
      this.cultureForm.get('introduction').patchValue(woundIntro);
      this.cultureForm.get('limitations').patchValue(limitation);
      this.cultureForm
        .get('result_interpretation')
        .patchValue(woundResultInterpretation);
    } else if (this.labTestName.includes('uti')) {
      let utiResultInterpretation = '';
      let utiIntro =
        'The UTI Assay used is an isothermal assay that can detect and identify common UTI pathogens by the presence of their DNA in a urine sample. This assay detects specific gene targets from the ' +
        this.organism.length +
        ' common pathogens. \n ';
      if (organismPositiveResult.length) {
        utiIntro +=
          'The Drug Resistance Panel used is a real-time fluorescent isothermal PCR assay intended for the qualitative detection of drug resistance gene markers.';
        utiResultInterpretation =
          'The patient urine specimen tested POSITIVE for the pathogen with ';
        for (let i = 0; i < this.organism.value.length; i++) {
          if (this.organism.value[i].organism_result === 'Positive') {
            if (this.organism.value[i].organism_type === 'h') {
              utiResultInterpretation +=
                this.organism.value[i].organism_name +
                (this.organism.value[i].organism_comments ? ' (' + this.organism.value[i].organism_comments + ')' : '') +
                ' with High Concentration (100,000 colony forming units per mL in a standard Urine Culture). ';
          } else if (this.organism.value[i].organism_type === 'm') {
              utiResultInterpretation +=
                this.organism.value[i].organism_name + (this.organism.value[i].organism_comments ? ' (' + this.organism.value[i].organism_comments + ')' : '') +
                ' with Medium Concentration (10,000 to 50,000 colony forming units mL in a standard Urine Culture). ';
            } else if (this.organism.value[i].organism_type === 'l') {
              utiResultInterpretation +=
                this.organism.value[i].organism_name + (this.organism.value[i].organism_comments ? ' (' + this.organism.value[i].organism_comments + ')' : '') +
                ' with Low Concentration (10,000 colony forming units per mL in a standard Urine Culture). ';
            }
          }
        }
      }
      if (organismNegativeResult && !organismPositiveResult.length) {
        utiResultInterpretation +=
          'The ' +
          this.organism.length +
          ' pathogens targeted with the assay were not detected in the patient’s urine sample. The test result is NEGATIVE ';
      }
      if (filteredResults.length) {
        utiResultInterpretation +=
          '\n Drug resistance panel showed that the patient is RESISTANT to antibiotics containing ';
        for (let i = 0; i < this.detector.value.length; i++) {
          if (this.detector.value[i].detector_result === 'R') {
            for (
              let j = 0;
              j < this.detector.value[i].anti_biotic.length;
              j++
            ) {
              const antiBiotic = this.cultureList.find(
                (t) => t.id === this.detector.value[i].anti_biotic[j],
              );
              utiResultInterpretation += antiBiotic?.name ?? ' ';
              utiResultInterpretation += ', ';
            }
            utiResultInterpretation += ' (';
            for (
              let k = 0;
              k < this.detector.value[i].detector_ids.length;
              k++
            ) {
              const detector = this.detectorList.find(
                (t) => t.id === this.detector.value[i].detector_ids[k],
              );
              utiResultInterpretation += detector?.name;
              if (k < this.detector.value[i].detector_ids.length - 1) {
                utiResultInterpretation += ', ';
              } else {
                utiResultInterpretation += ') ';
              }
            }
          }
        }
      }
      let limitationUti = '';
      limitationUti =
        'The performance of the UTI Assay used was established for urine specimens only. \n ' +
        'Negative results of the UTI Assay do not preclude infection with viral or other bacterial or fungal ' +
        'microorganisms that are not listed in the Assay Panel and should not be the sole basis of a patient ' +
        ' management decision. ';
      if (organismPositiveResult.length) {
        limitationUti +=
          'Negative results of the Drug Resistance Panel do not preclude resistance to antibiotics as there may be other mechanisms of treatment failure. Therefore, negative results should not be used as the sole basis for patient management decisions. Negative results must be combined with clinical observations, patient history, and epidemiological information. ';
      }
      this.cultureForm.get('introduction').patchValue(utiIntro);
      this.cultureForm.get('limitations').patchValue(limitationUti);
      this.cultureForm
        .get('result_interpretation')
        .patchValue(utiResultInterpretation);
    }
  }
  submitCulturePcrReport(isDraft): any {
    this.cultureForm
      .get('lab_test_detail_id')
      .setValue(this.pcrData.lab_test_detail_id);
    const detectorList = this.detector.value;
    if (detectorList) {
      for (let i = 0; i < detectorList.length; i++) {
        const detectorTypeValue = detectorList[i].detector_ids;
        const antiBioticValue = detectorList[i].anti_biotic;
        if (detectorTypeValue) {
          const filterData = this.detectorList.filter((t) =>
            detectorTypeValue.includes(t.id),
          );
          detectorList[i].detector_type_data = filterData;
        }
        if (antiBioticValue) {
          const antiBioticfilterData = this.cultureList.filter((t) =>
            antiBioticValue.includes(t.id),
          );
          detectorList[i].anti_biotic_data = antiBioticfilterData;
        }
      }
    }
    const formData = this.cultureForm.value;
    formData.detector = detectorList;
    this.resultSubmitBtn = true;
    this.ngxLoader.start();
    this.labTestService
      .submitCulturePcrReport(
        formData,
        $('#pcrRemark').val(),
        isDraft,
        this.pcrData,
      )
      .subscribe(
        (data) => {
          let obj = {
            isDraft,
            data,
          };
          this.updatedData.emit(obj);
          this.resultSubmitBtn = false;
          this.ngxLoader.stop();
        },
        (err) => {
          this.resultSubmitBtn = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  closePcrModals(): any {
    this.closePcrModal.emit();
  }
  ngOnInit(): void {
    this.getPcrData();
    setTimeout(() => {
      this.showCostingDetailModal = true;
    }, 500);
  }
}
