import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, timeout } from 'rxjs/operators';

@Injectable()
export class LabTestService {
  constructor(private http: HttpClient) {}
  create(labTest): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_investigations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_investigation: labTest,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createV2(labTest): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/lab_investigations/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_investigation: labTest,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  update(labTest, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_investigations/` +
          id +
          '/update_lab_investigations?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_investigation: labTest,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateV2(labTest, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_lab_investigations/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_investigation: labTest,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addReportingTime(reporting_type, reporting_value, id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_investigations/` +
          id +
          '/update_reporting_time?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          reporting_type,
          reporting_value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addReportingTimeV2(
    reporting_type,
    reporting_value,
    lab_investigation_id,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/update_reporting_time/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          reporting_type,
          reporting_value,
          lab_investigation_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAllLabTest(
    currentPage: any,
    perPage: any,
    code: any,
    name: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_investigations/get_lab_tests_in_medical_unit?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&code=' +
          code +
          '&name=' +
          name,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAllLabTestV2(
    currentPage: any,
    perPage: any,
    code: any,
    name: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_lab_tests_in_medical_unit?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&code=' +
          code +
          '&name=' +
          name,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchLabTest(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_investigations/` +
          id +
          `/lab_test_by_id?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchAllRequestedLabTest(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&filter_type=' +
          lab_filters.type +
          '&lab_id=' +
          lab_filters.lab_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  collectLabTest(id, specimen_id, sample_taken_from_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/orders/collect_lab_orders`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          lab_order_detail_id: id,
          specimen_id,
          sample_taken_from_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  collectLabProcessingTest(values, collectedDateAndTime, test_cost_details): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/collect_lab_order/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          lab_order_details: values,
          collected_time: collectedDateAndTime,
          test_cost_details: test_cost_details,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  collectLabTestonly(id, specimen_id, sample_taken_from_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/collect_lab_orders/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_order_detail_id: id,
          specimen_id,
          sample_taken_from_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  receivedLabTest(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/orders/receive_lab_orders`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          lab_order_detail_id: id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  receivedLabProcesingTest(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/receive_lab_orders/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          lab_order_detail_id: id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchReceiivedLabTest(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/received_lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&filter_type=' +
          lab_filters.type +
          '&lab_id=' +
          lab_filters.lab_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchReceivedLabTestByMrn(mrn): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/received_lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          mrn,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchReceivedLabTestByMrnV2(mrn): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/received_lab_orders/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          mrn,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchReportDetails(id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/report_parameters?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchReportDetailsV2(id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/report_parameters/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchCollectedLabTest(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/collected_lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&filter_type=' +
          lab_filters.type +
          '&lab_id=' +
          lab_filters.lab_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitLabTestResult(reports, pathologist_remarks, draft): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_test_details`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          reports,
          pathologist_remarks,
          draft,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitSmsData(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/sms_configuration/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          sms_configuration: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSmsData(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/sms_configuration/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateBillingControl(billingControls, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/system_billing_control/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          system_billing_controls: billingControls,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateGeneralControl(generalControls, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/system_general_control/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          system_general_controls: generalControls,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitLabTestResultV2(
    reports,
    pathologist_remarks,
    draft,
    patient_mrn,
    test_cost_details,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/lab_test_details/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          reports,
          patient_mrn,
          pathologist_remarks,
          draft,
          test_cost_details,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitCulturePcrReport(
    reports,
    pathologist_remarks,
    draft,
    pcrData,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/lab_test_details/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          order_id: pcrData.order_id,
          report_type: pcrData.report_type,
          id: pcrData.id,
          lab_test_detail_id: pcrData.lab_test_detail_id,
          patient_mrn: pcrData.patient_mrn,
          lab_assessment_id: pcrData.lab_assessment_id,
          pathologist_remarks,
          draft,
          reports,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateUnVerifiedLabTest(
    reports,
    pathologist_remarks,
    draft,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_test_details`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          reports,
          pathologist_remarks,
          draft,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchUnVerifiedLabTest(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/unverified_lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&filter_type=' +
          lab_filters.type +
          '&lab_id=' +
          lab_filters.lab_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchUnVerifiedLabTestByMrn(mrn): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/unverified_lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          mrn,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  verifiedOrDeliveredOrders(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/verified_or_delivered_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&filter_type=' +
          lab_filters.type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&lab_id=' +
          lab_filters.lab_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  verifiedOrDeliveredOrdersByMrn(lab_filters): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/verified_or_delivered_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          lab_filters.patient_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  approvedUnVerifiedLabTest(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/orders/verify_lab_orders`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  approvedUnVerifiedLabTestV2(id, patient_mrn): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/verify_lab_order/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
          patient_mrn,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  approvedUnVerifiedLab(mrn): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/lab_service_verification_sms/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          mrn: mrn,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAllLabReportsLabTest(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/all_lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&filter_type=' +
          lab_filters.type +
          '&lab_status=' +
          lab_filters.lab_status +
          '&lab_id=' +
          lab_filters.lab_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchAllLabProcessingReportsLabTest(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    console.log('lab_filters', lab_filters);
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/all_lab_orders/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_type=' +
          lab_filters.patient_type +
          '&department_id=' +
          lab_filters.department_id +
          '&lab_status=' +
          lab_filters.lab_status +
          '&search_name=' +
          lab_filters.search_name +
          '&search_type=' +
          lab_filters.search_type +
          '&filter_type=' +
          lab_filters.type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchLabTrackingData(
    fromDate,
    toDate,
    departmentId,
    patientType,
    labStatus,
    searchType,
    searchName,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/lab_tracking_data/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&patient_type=' +
          patientType +
          '&department_id=' +
          departmentId +
          '&lab_status=' +
          labStatus +
          '&search_name=' +
          searchName +
          '&search_type=' +
          searchType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchLabReportsLabTest(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/verified_lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&filter_type=' +
          lab_filters.type +
          '&lab_id=' +
          lab_filters.lab_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchDeliveredLabReportsTest(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/delivered_lab_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&department_id=' +
          lab_filters.department_id +
          '&parent_department_id=' +
          lab_filters.parent_department_id +
          '&filter_type=' +
          lab_filters.type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&lab_id=' +
          lab_filters.lab_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  rejectLabTest(id, reason): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/orders/reject_lab_orders`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
          reason,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  rejectLabProcessingTest(id, reason): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/reject_lab_orders/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
          reason,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  cancelLabTest(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/orders/cancel_lab_orders`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelLabProcessingTest(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/cancel_lab_orders/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  returnCancelLabTest(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/orders/uncancel_lab_orders`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  returnCancelLabTestV2(id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/uncancel_lab_orders/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientLabReports(patient_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/verified_or_unverified_orders_by_patient?patient_id=` +
          patient_id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientLabReportsV2(patient_id, medical_unit_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/verified_or_unverified_orders_by_patient?patient_id=` +
          patient_id +
          '&medical_unit_id=' +
          medical_unit_id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // deliveredLabTestResultFun(value): Observable<any> {
  //   const results = this.http.post(`${environment.rorUrl}/v4/orders/`+value.id+'/deliver_lab_order',
  //     {
  //       medical_unit_id: localStorage.getItem('current_medical_unit_id'),
  //       auth_token: localStorage.getItem('auth_token'),
  //       receiver_relation: value.receiver_relation,
  //       receiver_cnic: value.receiver_cnic,
  //       receiver_name: value.receiver_name,
  //     }, {headers: new HttpHeaders({ AuthenticationToken: localStorage.getItem('auth_token'), Accept: 'application/json'})}).pipe(map(data => data));
  //   return results;
  // }

  deliveredLabTestResultFun(value, ids): Observable<any> {
    const results = this.http
      .post(
        environment.rorUrl + '/v4/orders/deliver_lab_order?ids=' + ids,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          receiver_relation: value.receiver_relation,
          receiver_cnic: value.receiver_cnic,
          receiver_name: value.receiver_name,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  deliveredLabProcessingTestResultFun(value, ids): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/deliver_lab_order/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          `&ids=` +
          ids,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          receiver_relation: value.receiver_relation,
          receiver_cnic: value.receiver_cnic,
          receiver_name: value.receiver_name,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  uploadImage(image): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/orders123/deliver_lab_order?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id'),
        {
          images: image,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addLabTestDescriptionFun(description, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_investigations/` +
          id +
          '/update_lab_comments',
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          special_instructions: description.special_instructions,
          protocol: description.protocol,
          methodology: description.methodology,
          performed_method: description.performed_method,
          interpretation: description.interpretation,
          comments: description.comments,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addLabTestDescriptionFunV2(description, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/update_lab_comments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          special_instructions_name: description.special_instructions_name,
          special_instructions_show_top:
            description.special_instructions_show_top,
          special_instructions: description.special_instructions,
          protocol_name: description.protocol_name,
          protocol_show_top: description.protocol_show_top,
          protocol: description.protocol,
          methodology_name: description.methodology_name,
          methodology_show_top: description.methodology_show_top,
          methodology: description.methodology,
          performed_method_name: description.performed_method_name,
          performed_method_show_top: description.performed_method_show_top,
          performed_method: description.performed_method,
          interpretation_name: description.interpretation_name,
          interpretation_show_top: description.interpretation_show_top,
          interpretation: description.interpretation,
          comments_name: description.comments_name,
          comments_show_top: description.comments_show_top,
          comments: description.comments,
          lab_investigation_id: id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchAllLabStatusReport(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/lab_status_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&status=' +
          lab_filters.status +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchAllLabTrackingReport(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/orders/lab_tracking_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_mrn=' +
          lab_filters.patient_mrn +
          '&department_id=' +
          lab_filters.department_id +
          '&test_id=' +
          lab_filters.test_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchLabsControls(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/lab_controls?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchLabsControlsv2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/lab_controls?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchLabsControlsForLabV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/public_lab_controls?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLabsControls(description): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/lab_controls/`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          is_header: description.is_header,
          is_header_img: description.is_header_img,
          header_image: description.header_img_url,
          is_footer: description.is_footer,
          is_footer_img: description.is_footer_img,
          footer_image: description.footer_img_url,
          lab_status: description.lab_status,
          auto_receive: description.auto_receive,
          auto_verify: description.auto_verify,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLabsControlsv2(description): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/lab_controls/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          web_report: description.web_report,
          is_header: description.is_header,
          is_header_img: description.is_header_img,
          header_image: description.header_img_url,
          header_file2: description.header_img_url2,
          is_footer: description.is_footer,
          is_footer_img: description.is_footer_img,
          footer_image: description.footer_img_url,
          footer_file2: description.footer_img_url2,
          lab_status: description.lab_status,
          auto_receive: description.auto_receive,
          auto_verify: description.auto_verify,
          enable_external_lis: description.enable_external_lis,
          print_unpaid_invoice: description.print_unpaid_invoice,
          print_unapproved_discount: description.print_unapproved_discount,
          external_lis_base_url: description.external_lis_base_url,
          show_worklist: description.show_worklist,
          stop_unpaid_invoice_in_collection:
            description.stop_unpaid_invoice_in_collection,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  labOrderReport(selectedId, header, footer): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/lab_order_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          selectedId +
          '&show_header=' +
          header +
          '&show_footer=' +
          footer,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  sendReporType(report_type, id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/update_report_type/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          report_type,
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  previousResult(previous_result, id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/update_previous_result/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          previous_result,
          id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
