import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservedValueOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class EmployeeRegistrationService {
  constructor(private http: HttpClient) {}

  create(employee): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/employees?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          employee,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAllEmployees(employee): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/employees?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          employee.type +
          '&query=' +
          employee.search_query +
          '&dialysis=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateEmployees(employee, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/employees/` +
          id +
          ` ?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          employee,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchEmployees(employee): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/employees/get_employees_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          employee.type +
          '&query=' +
          employee.search_query +
          '&user=' +
          employee.user,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchEmployeesV2(employee): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_employees_by_type/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          employee.type +
          '&query=' +
          employee.search_query +
          '&user=' +
          employee?.user ?? '',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  employeeListName(employee): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_all_employee_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  forgotPass(old_password, new_password): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/users/reset`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          old_password,
          new_password,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAllConfiguration(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/configurator_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchConfiguration(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/configurator_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&configurator_type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchConfigurationV2(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/configurator_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&configurator_type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getBillingCategories(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/doctor_categories?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getUserRoles(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/roles?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getUserRolesV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/roles/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createUser(
    role_id,
    employee_id,
    user_name,
    password,
    status,
    browser_session,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/medical_unit_users`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          role_id,
          employee_id,
          user_name,
          password,
          status,
          browser_session,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createUserV2(
    role_id,
    employee_id,
    user_name,
    password,
    status,
    browser_session,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/medical_unit_users/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          // auth_token: localStorage.getItem('auth_token'),
          // medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          role_id,
          employee_id,
          user_name,
          password,
          status,
          browser_session,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createUserV3(value, employee_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/medical_unit_users/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          // auth_token: localStorage.getItem('auth_token'),
          // medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          role_id: value.role_id,
          user_name: value.username,
          password: value.password,
          status: value.status,
          browser_session: value.browser_session,
          title: value.title,
          first_name: value.first_name,
          last_name: value.last_name,
          primary_contact: value.primary_contact,
          staff_type: value.staff_type,
          department: value.department,
          designation: value.designation,
          is_doctor: value.is_doctor,
          employee_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateUser(
    role_id,
    employee_id,
    status,
    id,
    user_name,
    password,
    browser_session,
    user_id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/medical_unit_users/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_id,
          status,
          employee_id,
          user_name,
          password,
          browser_session,
          user_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateUserV2(
    role_id,
    employee_id,
    status,
    id,
    user_name,
    password,
    browser_session,
    user_id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/medical_unit_users/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_id,
          status,
          employee_id,
          user_name,
          password,
          browser_session,
          user_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateUserV3(value, employee_id, id, user_id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/medical_unit_users/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_id: value.role_id,
          status: value.status,
          employee_id: employee_id,
          user_name: value.username,
          password: value.password,
          browser_session: value.browser_session,
          title: value.title,
          first_name: value.first_name,
          last_name: value.last_name,
          primary_contact: value.primary_contact,
          staff_type: value.staff_type,
          department: value.department,
          designation: value.designation,
          is_doctor: value.is_doctor,
          user_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getUserModules(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/roles/modules?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addRoleMenu(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/roles/`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          role: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addRoleMenuV2(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/roles/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          role: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRoleV2(value, role_id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/roles/` +
          role_id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getUserRolesById(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/roles/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&role=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateRoleMenu(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/roles/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          // auth_token: localStorage.getItem('auth_token'),
          // medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          role: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  userDetailList(user): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/show_logs/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&user_id=' +
          user,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
