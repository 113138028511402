import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TaskManagementService } from '../../../services/task-management.service';
import * as moment from 'moment/moment';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RosterServiceService} from '../../../services/roster-service.service';
import {SystemConfigurationService} from '../../../services/system-configuration.service';
import {Router} from '@angular/router';

declare var $: any;
declare var toastr: any;
declare var Highcharts: any;

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.css']
})
export class TasksListComponent implements OnInit {
  searchTaskForm: UntypedFormGroup;
  searchTaskFiltersForm: UntypedFormGroup;

  page = 1;
  totalPages: any;
  perPage = 25;
  perPageValue: any;
  list: any;
  pageDataList: any;
  originalList: any[] = [];
  openAddTaskModal = false;
  checkOrderType = '';
  isMainModalOpen = true;
  isSubmittedValue = false;
  taskId: any;
  fromDate: any;
  toDate: any;
  taskStatus: any;
  priority: any;
  locationData: any;
  project: any;
  depart: any;
  screens = [];
  tagListData: any;
  employee: any;
  taskStatusData: any;
  taskPriorityData: any;
  taskTagData: any;
  currentUrl = '';

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private task: TaskManagementService,
    private fb: UntypedFormBuilder,
    private rosterServiceService: RosterServiceService,
    private systemConfigService: SystemConfigurationService,
    private router: Router
  ) {
    this.currentUrl = this.router.url;
    console.log('currentUrl', this.currentUrl);
  }

  ngOnInit(): void {
    this.searchTaskForm = this.fb.group({
      title: [''],
      task_id: [''],
      status: [''],
      department: [''],
      location: [''],
      priority: [''],
      type: [''],
      project: [''],
      related_to: [''],
      screen: [''],
      tags: [''],
      assign_to: [''],
    });
    this.searchTaskFiltersForm = this.fb.group({
      title: [''],
      task_id: [''],
      status: [''],
      department: [''],
      location: [''],
      priority: [''],
      type: [''],
      project: [''],
      related_to: [''],
      screen: [''],
      tags: [''],
      assign_to: [''],
    });
    this.perPageValue = 25;
    this.searchFilter();
    this.getStatus();
    this.getPriority();
    this.getProject();
    this.getDepartments();
    this.fetchScreens();
    this.getAllTags();
    this.getEmployess();
    setTimeout((e) => {
      $('#type').selectpicker('refresh');
      $('#type').selectpicker();
      $('#related_to').selectpicker('refresh');
      $('#related_to').selectpicker();
    }, 500);
    this.getTaskStatus();
    this.getTaskPriority();
    this.getTaskTag();
  }
  getTaskStatus(): any {
    this.task.getTaskStatus().subscribe(
      (data) => {
        this.taskStatusData = data;
        this.initializeTaskStatusChart(this.taskStatusData);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getTaskPriority(): any {
    this.task.getTaskPriority().subscribe(
      (data) => {
        this.taskPriorityData = data;
        this.initializePriorityChart(this.taskPriorityData);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getTaskTag(): any {
    this.task.getTaskTag().subscribe(
      (data) => {
        this.taskTagData = data;
        this.initializeTagChart(this.taskTagData);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  initializeTaskStatusChart(apiResponse): void {
    const taskData = apiResponse.data;

    const chartData = taskData.name.map((name, index) => ({
      name,
      y: taskData.count[index],
    }));

    Highcharts.chart('task-status-chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 240,
        width: 300,
        spacing: [0, 0, 0, 0],
        marginTop: 1,
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Task Status',
          type: 'pie',
          colorByPoint: true,
          data: chartData,
        }
      ],
      legend: {
        align: 'left',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        symbolHeight: 12,
        padding: 0,
        itemMarginTop: 0,
        itemMarginBottom: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }

  initializePriorityChart(apiResponse): void {
    const taskData = apiResponse.data;

    const chartData = taskData.name.map((name, index) => ({
      name,
      y: taskData.count[index],
    }));
    Highcharts.chart('priority-chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 220,
        width: 260,
        spacing: [0, 0, 0, 0],
        marginTop: 1,
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Priority',
          type: 'pie',
          colorByPoint: true,
          data: chartData,
        }
      ],
      legend: {
        align: 'left',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        symbolHeight: 12,
        padding: 0,
        itemMarginTop: 0,
        itemMarginBottom: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }

  initializeTagChart(apiResponse): void {
    const taskData = apiResponse.data;

    const chartData = taskData.name.map((name, index) => ({
      name,
      y: taskData.count[index],
    }));
    Highcharts.chart('tag-chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 240,
        width: 300,
        spacing: [0, 0, 0, 0],
        marginTop: 10,
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: 'Tag',
          type: 'pie',
          colorByPoint: true,
          data: chartData
        }
      ],
      legend: {
        align: 'left', // Align the legend to the right
        verticalAlign: 'middle', // Position the legend in the middle
        layout: 'vertical', // Arrange legend items vertically
        itemStyle: {
          fontSize: '12px', // Adjust font size to fit within the card
          fontWeight: 'bold',
        },
        symbolHeight: 12,
        padding: 0, // Add some padding to separate the legend from the chart
        itemMarginTop: 0,
        itemMarginBottom: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }

  sortStatus(order: string): any{
    if (order === 'asc') {
      this.pageDataList.sort((a, b) => {
        const nameA = a.task_status?.name || '';
        const nameB = b.task_status?.name || '';
        return nameA.localeCompare(nameB);
      });
    } else if (order === 'desc') {
      this.pageDataList.sort((a, b) => {
        const nameA = a.task_status?.name || '';
        const nameB = b.task_status?.name || '';
        return nameB.localeCompare(nameA);
      });
    } else {
      this.pageDataList = [...this.originalList]; // Reset to original list
    }
  }

  // Safely handle sorting for the Priority column
  sortPriority(order: string): any{
    if (order === 'asc') {
      this.pageDataList.sort((a, b) => {
        const priorityA = a.priority?.name || '';
        const priorityB = b.priority?.name || '';
        return priorityA.localeCompare(priorityB);
      });
    } else if (order === 'desc') {
      this.pageDataList.sort((a, b) => {
        const priorityA = a.priority?.name || '';
        const priorityB = b.priority?.name || '';
        return priorityB.localeCompare(priorityA);
      });
    } else {
      // 'all' - reset to the original unsorted data
      this.pageDataList = [...this.originalList];
    }
  }
  onSubmitFilters(): any {
    this.ngxLoader.start();
    this.task
      .getTaskListing(
        this.page,
        this.perPage,
        this.searchTaskFiltersForm.value
      )
      .subscribe(
        (data) => {
          this.pageDataList = data;
          this.totalPages = this.pageDataList.total_count;
          this.pageDataList = this.pageDataList.items;
          this.originalList = [...this.pageDataList];
          this.closeAddTaskModal();
          this.clearSideBarValues();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  clearSideBarValues(): any{
    this.searchTaskForm.patchValue({
      title: '',
      status: '',
      type: '',
      priority: '',
      location: '',
      project: '',
      related_to: '',
      department: '',
      screen: '',
      assign_to: '',
    });
    setTimeout((e) => {
      $('#selectStatus').selectpicker('refresh');
      $('#selectStatus').selectpicker();
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').selectpicker();
      $('#priority').selectpicker('refresh');
      $('#priority').selectpicker();
      $('#screen_id').selectpicker('refresh');
      $('#type').selectpicker('refresh');
      $('#type').selectpicker();
      $('#related_to').selectpicker('refresh');
      $('#related_to').selectpicker();
      $('#assign_to').selectpicker('refresh');
      $('#assign_to').selectpicker();
    }, 500);
    $('#selectTags').val('');
  }
  clearMainFilterValues(): any{
    this.searchTaskFiltersForm.patchValue({
      task_id: '',
      title: '',
      status: '',
      priority: '',
      assign_to: '',
    });
    setTimeout((e) => {
      $('#mainStatusId').selectpicker('refresh');
      $('#mainStatusId').selectpicker();
      $('#priorityId').selectpicker('refresh');
      $('#priorityId').selectpicker();
      $('#assigned_to').selectpicker('refresh');
      $('#assigned_to').selectpicker();
    }, 500);
  }
  searchFilter(): any {

    this.ngxLoader.start();
    this.searchTaskForm.get('tags').setValue($('#selectTags').val().toString());
    this.task
      .getTaskListing(
        this.page,
        this.perPage,
        this.searchTaskForm.value
      )
      .subscribe(
        (data) => {
          this.pageDataList = data;
          this.totalPages = this.pageDataList.total_count;
          this.pageDataList = this.pageDataList.items;
          this.originalList = [...this.pageDataList];
          $('#search-task').removeClass('offcanvas-on');
          // this.resetSelectedData();
          this.closeAddTaskModal();
          this.clearMainFilterValues();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  selectPaginationLimit(event): any {
    this.page = 1;
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    if (this.isSubmittedValue){
      this.onSubmitFilters();
    } else {
      this.searchFilter();
    }
  }

  loadPage(event): any {
    this.searchFilter();
  }

  openSearchList(): any {
    $('#search-task').toggleClass('offcanvas-on');
    $('.select2').select2({
      placeholder: 'Select or type Tags',
      tags: true,
    });
    setTimeout(() => {
      $('#selectTags').trigger('change');
    }, 1000);
  }
  closeSeacrhList(): any {
    $('#search-task').removeClass('offcanvas-on');
    // this.searchTaskForm.patchValue({
    //   title: '',
    //   status: '',
    //   type: '',
    //   priority: '',
    //   location: '',
    //   project: '',
    //   related_to: '',
    //   department: '',
    //   screen: '',
    //   assign_to: '',
    // });
    // setTimeout((e) => {
    //   $('#selectStatus').selectpicker('refresh');
    //   $('#selectStatus').selectpicker();
    //   $('.selectpicker').selectpicker('refresh');
    //   $('.selectpicker').selectpicker();
    //   $('#priority').selectpicker('refresh');
    //   $('#priority').selectpicker();
    //   $('#screen_id').selectpicker('refresh');
    //   $('#type').selectpicker('refresh');
    //   $('#type').selectpicker();
    //   $('#related_to').selectpicker('refresh');
    //   $('#related_to').selectpicker();
    //   $('#assign_to').selectpicker('refresh');
    //   $('#assign_to').selectpicker();
    // }, 500);
    // $('#selectTags').val('');
  }
  openModal(id): any {
    this.taskId = id;
    this.openAddTaskModal = true;
    $('#taskFile').val('');
    if (!this.taskId) {
      $('#medicationsAllergies').val(null).trigger('change');
    }
    $('#viewTaskModal').modal('show');
  }

  closeAddTaskModal(): any{
    if (this.checkOrderType !== 'work order'){
      this.openAddTaskModal = false;
       $('#viewTaskModal').modal('hide');
    }
  }

  addTask(event: any): any{
    console.log('revent', event);
    this.checkOrderType = event[1];
      if (this.isSubmittedValue) {
        this.onSubmitFilters();
      } else {
        this.searchFilter();
      }
      if (this.checkOrderType !== 'work order') {
         this.openAddTaskModal = false;
      $('#viewTaskModal').modal('hide');
    }
  }

  onSubmit(event): any{
    this.page = 1;
    this.perPage = 25;
    this.isSubmittedValue = event;
    if (this.isSubmittedValue){
      this.onSubmitFilters();
    } else {
      this.searchFilter();
    }
  }

  getStatus(): any {
    this.task.getStatusList(true).subscribe(
      (data) => {
        this.taskStatus = data;
        const defaultStatus = this.taskStatus.filter(
          (t) => t.is_default === true,
        );
        // this.addComplaint.get('status').patchValue(defaultStatus[0].id);
        setTimeout((e) => {
          $('#selectStatus').selectpicker('refresh');
          $('#selectStatus').selectpicker();
          $('#mainStatusId').selectpicker('refresh');
          $('#mainStatusId').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getPriority(): any {
    this.priority = [];
    this.task.getPriority().subscribe(
      (data) => {
        this.priority = data;
        // this.addComplaint.get('priority').patchValue(this.priority[0].id);
        setTimeout((e) => {
          $('#priority').selectpicker('refresh');
          $('#priorityId').selectpicker('refresh');
          $('#priority').selectpicker();
          $('#priorityId').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getProject(): any {
    this.task.getRoleBaseProject().subscribe(
      (data) => {
        this.project = data;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchScreens(): any {
    this.ngxLoader.start();
    this.screens = [];
    this.systemConfigService.getScreensV2('true').subscribe(
      (data) => {
        this.screens = data.screens;
        setTimeout(() => {
          $('#screen_id').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }

  getAllTags(): any {
    this.tagListData = [];
    this.task.getTagList().subscribe(
      (data) => {
        this.tagListData = data;
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getEmployess(): any {
    this.task.getEmployees('').subscribe(
      (data) => {
        this.employee = data;
        setTimeout((e) => {
          $('#assign_to').selectpicker('refresh');
          $('#assigned_to').selectpicker('refresh');
          $('#assign_to').selectpicker();
          $('#assigned_to').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  resetSelectedData(): any{
    this.searchTaskForm.patchValue({
      title: '',
      status: '',
      type: '',
      priority: '',
      location: '',
      project: '',
      related_to: '',
      department: '',
      screen: '',
      assign_to: '',
    });
    setTimeout((e) => {
      $('#selectStatus').selectpicker('refresh');
      $('#selectStatus').selectpicker();
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').selectpicker();
      $('#priority').selectpicker('refresh');
      $('#priority').selectpicker();
      $('#screen_id').selectpicker('refresh');
      $('#type').selectpicker('refresh');
      $('#type').selectpicker();
      $('#related_to').selectpicker('refresh');
      $('#related_to').selectpicker();
      $('#assign_to').selectpicker('refresh');
      $('#assign_to').selectpicker();
    }, 200);
    $('#selectTags').val('');
    $('.select2').select2({
      placeholder: 'Select or type Tags',
      tags: true,
    });
    setTimeout(() => {
      $('#selectTags').trigger('change');
    }, 1000);
  }
}
