import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RadiologyService } from '../../../services/radiology.service';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { Radiology, Department } from '../../_models/index';
import { ShareDataService } from '../../../services/share-data.service';
import { PatientTypeService } from '../../../services/patient-type.service';

declare var toastr: any;
declare var $: any;

@Component({
  selector: 'app-radiology-service-detail-v2',
  templateUrl: './radiology-service-detail-v2.component.html',
  styleUrls: ['./radiology-service-detail-v2.component.css'],
})
export class RadiologyServiceDetailV2Component implements OnInit {
  @Output() radiologyServiceData: EventEmitter<any> = new EventEmitter<any>();
  RadiologyForm: UntypedFormGroup;
  submitted = false;
  radiology: Radiology[];
  departments: Department[];
  contrast_option = ['yes', 'no'];
  department_code: any;
  updatebtn = false;
  showBackBtn = false;
  currentId: any;
  service_id: number;
  service_name: string;
  patientTypes = [];
  isChecked = false;
  submitRad = false;
  showCostingDetail = false;
  radiologyData: any;
  updateCostingDetail: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private radiologyService: RadiologyService,
    private departmentService: DepartmentsbyhospitalService,
    private sharedService: ShareDataService,
    private patientTypeService: PatientTypeService,
  ) {
    this.RadiologyForm = this.formBuilder.group({
      service_name: ['', Validators.required],
      cpt_code: ['', Validators.required],
      contrast: ['', Validators.required],
      body_region: ['', Validators.required],
      department_id: ['', Validators.required],
      service_price: [''],
      replicate_service_pat_types: [''],
      is_active: [true],
    });
  }
  clear(): any {
    this.submitted = false;
    this.RadiologyForm.patchValue({
      service_name: '',
      cpt_code: '',
      contrast: '',
      body_region: '',
      department_id: '',
      service_price: '',
      // replicate_service_pat_types: '',
      is_active: true,
    });
    if (this.patientTypes) {
      const defaultPatientType = this.patientTypes.find((t) => t.is_default);
      const patientType = defaultPatientType ? [defaultPatientType.id] : [];
      this.RadiologyForm.get('replicate_service_pat_types').setValue(
        patientType,
      );
      setTimeout(() => {
        $('#patient_type_service').selectpicker('refresh');
        $('#patient_type_service').selectpicker();
        $('#department_id').selectpicker('refresh');
        $('#department_id').selectpicker();
      }, 500);
    } else {
      this.getPatientTypes();
    }
  }

  createRadiology(): any {
    this.submitRad = true;
    this.submitted = true;
    if (this.RadiologyForm.invalid) {
      this.submitRad = false;
      return;
    }
    if (!this.isChecked && !this.currentId) {
      this.submitRad = false;
      toastr.error('Select Confirmation');
      return;
    }
    if (this.currentId) {
      const radiologyFormValue = this.RadiologyForm.value;
      radiologyFormValue.cost_details = this.updateCostingDetail;
      this.ngxService.start();
      this.radiologyService
        .updatev2(radiologyFormValue, this.currentId, this.service_id)
        .subscribe(
          (data) => {
            // this.router.navigate(['radiology/services-v2']);
            this.ngxService.stop();
            toastr.success('Updated successfully');
            this.radiologyServiceData.emit();
            this.submitRad = false;
          },
          (err) => {
            this.submitRad = false;
            this.ngxService.stop();
            toastr.error(err.error.error);
          },
        );
    } else {
      const radiologyFormValue = this.RadiologyForm.value;
      radiologyFormValue.cost_details = this.updateCostingDetail;
      this.ngxService.start();
      this.radiologyService.createv2(radiologyFormValue).subscribe(
        (data) => {
          // this.router.navigate(['radiology/services-v2']);
          this.ngxService.stop();
          toastr.success('Add successfully');
          this.radiologyServiceData.emit();
          this.submitRad = false;
        },
        (err) => {
          this.submitRad = false;
          this.ngxService.stop();
          toastr.error(err.error.error);
        },
      );
    }
  }
  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  noDecimalPoint(event: any): any {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  getPatientTypes(): any {
    this.patientTypes = [];
    this.patientTypeService.indexV2().subscribe(
      (data) => {
        this.patientTypes = data.patient_types;
        if (this.patientTypes) {
          const defaultPatientType = this.patientTypes.find(
            (t) => t.is_default,
          );
          const patientType = defaultPatientType ? [defaultPatientType.id] : [];
          if (patientType) {
            this.RadiologyForm.get('replicate_service_pat_types').setValue(
              patientType,
            );
          } else {
            this.RadiologyForm.get('replicate_service_pat_types').setValue('');
          }
        }
        setTimeout(() => {
          $('#patient_type_service').selectpicker('refresh');
          $('#patient_type_service').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }

  getRadiologyData(): any {
    this.showBackBtn = true;
    this.updatebtn = true;
    this.showCostingDetail = false;
    this.ngxService.start();
    this.radiologyService.getRadiologyv2(this.currentId).subscribe(
      (data) => {
        const Data = data;
        this.radiologyData = Data.radiologies[0].test_cost_details;
        this.service_id = Data.radiologies[0].service.id;
        this.ngxService.stop();
        console.log('Data', Data);
        this.RadiologyForm.patchValue({
          service_name: Data.radiologies[0].service.name,
          cpt_code: Data.radiologies[0].cpt_code,
          contrast: Data.radiologies[0].contrast,
          body_region: Data.radiologies[0].body_region,
          department_id: Data.radiologies[0].service.department_id,
          replicate_service_pat_types:
            Data.radiologies[0].replicate_service_pat_types,
          service_price: Data.radiologies[0].service_price,
          is_active: Data.radiologies[0].is_active,
        });
        setTimeout(() => {
          $('#patient_type_service').selectpicker('refresh');
          $('#patient_type_service').selectpicker();
        }, 500);
        this.showCostingDetail = true;
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.error);
      },
    );
  }
  get f() {
    return this.RadiologyForm.controls;
  }

  getCostingData(event): any {
    this.updateCostingDetail = event;
    if (this.updateCostingDetail.is_service_price) {
      this.RadiologyForm.get('service_price').setValue(
        this.updateCostingDetail.total_price,
      );
      $('#service_price').prop('disabled', true);
    } else {
      $('#service_price').prop('disabled', false);
    }
  }

  ngOnInit(): void {
    this.getPatientTypes();
    this.sharedService.updateRadiologyServiceData.subscribe((data) => {
      this.currentId = Number(data);
      if (this.currentId) {
        this.RadiologyForm.get('service_price').clearValidators();
        this.RadiologyForm.get('service_price').updateValueAndValidity();
        this.RadiologyForm.get('replicate_service_pat_types').clearValidators();
        this.RadiologyForm.get(
          'replicate_service_pat_types',
        ).updateValueAndValidity();
        this.getRadiologyData();
        this.department_code = 'rad';
        this.departmentService
          .fetchSubDeptbyServicev2(this.department_code, '')
          .subscribe(
            (data) => {
              this.departments = data.departments;
              setTimeout(() => {
                $('#department_id').selectpicker('refresh');
                $('#department_id').selectpicker();
              }, 500);
            },
            (err) => {
              this.ngxService.stop();
              toastr.error(err.error.error);
            },
          );
      } else {
        this.showCostingDetail = false;
        this.RadiologyForm.get('service_price').setValidators(
          Validators.required,
        );
        this.RadiologyForm.get('service_price').updateValueAndValidity();
        this.RadiologyForm.get('replicate_service_pat_types').setValidators(
          Validators.required,
        );
        this.RadiologyForm.get(
          'replicate_service_pat_types',
        ).updateValueAndValidity();
        this.clear();
        this.department_code = 'rad';
        this.departmentService
          .fetchSubDeptbyServicev2(this.department_code, '')
          .subscribe(
            (data) => {
              this.departments = data.departments;
              setTimeout(() => {
                $('#department_id').selectpicker('refresh');
                $('#department_id').selectpicker();
              }, 500);
            },
            (err) => {
              this.ngxService.stop();
              toastr.error(err.error.error);
            },
          );
        this.radiologyData = '';
        setTimeout(() => {
          this.showCostingDetail = true;
        }, 500);
      }
    });
    // this.route.data.subscribe(({ department }) => {
    //   this.departments = department["departments"];
    //   this.ngxService.stop();
    // });
  }
}
