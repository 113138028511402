<form [formGroup]="addComplaint" (ngSubmit)="onSubmit()">
  <div class="d-flex flex-column-fluid main-content">
    <div class="">
      <div class="row">
        <!--Name-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="form-group">
            <label><span class="font-weight-bolder" *ngIf="taskId">{{complaintSingledata?.id}} - </span>Title<span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Name"
              formControlName="name"
              [ngClass]="{
                'is-invalid': isAdd && addComplaint.controls.name.errors
              }"
            />
          </div>
        </div>
        <!--Description-->
        <div class="col-12">
          <div class="form-group">
            <label>Description <span class="text-danger">*</span></label>
            <textarea
              type="text"
              class="form-control summerNoteEditor"
              formControlName="description"
              id="description"
              placeholder="Description"
            ></textarea>
          </div>
        </div>
<!--        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">-->
<!--          <div class="form-group">-->
<!--            <label>Reference Task Number</label>-->
<!--            <input-->
<!--              type="text"-->
<!--              class="form-control form-control-sm"-->
<!--              placeholder="Enter Reference Task"-->
<!--              formControlName="reference_task_number"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
        <!--Status-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>{{currentUrl === '/task-management/task-list' ? 'Task Status' : 'Order Status' }} <span class="text-danger">*</span></label>
            <div
              [ngClass]="{
                'is-invalid': isAdd && addComplaint.controls.status.errors
              }"
            >
              <select
                class="form-control form-control-sm"
                data-live-search="true"
                id="selectStatus"
                formControlName="status"
                name="selectStatus"
              >
                <ng-container *ngFor="let status of taskStatus">
                  <option [value]="status.id" *ngIf="status.is_active">
                    {{ status?.name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <!--Type-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>Type <span class="text-danger">*</span></label>
            <select
              class="form-control form-control-sm"
              formControlName="type"
              id="type"
              (change)="checkType($event.target.value)"
              [ngClass]="{
                'is-invalid': isAdd && addComplaint.controls.type.errors, 'pointer-events-no': complaintSingledata?.type === 'work order'
              }"
            >
              <option value="" [disabled]="true">Select</option>
              <ng-container>
                <ng-container *ngFor="let data of tasksTypes">
                  <option  [value]="data?.value">{{data?.label}}</option>
<!--                  <option *ngIf="currentUrl === '/task-management/task-list' && data?.type === 'task'" [value]="data?.value">{{data?.label}}</option>-->
<!--                  <option *ngIf="currentUrl !== '/task-management/task-list' && data?.type === 'order'" [value]="data?.value">{{data?.label}}</option>-->
                </ng-container>
              </ng-container>
            </select>
          </div>
        </div>
        <!--Priority-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>Priority <span class="text-danger">*</span></label>
            <div
              [ngClass]="{
                'is-invalid': isAdd && addComplaint.controls.priority.errors
              }"
            >
              <select
                class="form-control form-control-sm"
                data-live-search="true"
                id="prioritySelect3"
                formControlName="priority"
                data-none-selected-text="Select Priority"
                name="modalSelect"
              >
                <option value="" [disabled]="true">Select Priority</option>
                <ng-container *ngFor="let p of priority">
                  <option [value]="p.id">{{ p?.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <!--project-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>Project <span class="text-danger">*</span></label>
            <div
              [ngClass]="{
                'is-invalid': isAdd && addComplaint.controls.project.errors
              }"
            >
              <select
                class="form-control form-control-sm selectpicker"
                data-live-search="true"
                id="addProject"
                formControlName="project"
                name="modalSelect"
              >
                <option value="" [disabled]="true">Select Project</option>
                <ng-container *ngFor="let p of project">
                  <option [value]="p.id">
                    {{ p?.name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <!--Department-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>Department </label>
            <select
              class="form-control form-control-sm selectpicker depar"
              data-live-search="true"
              name="department"
              formControlName="department"
            >
              <option value="" [disabled]="true">Select Department</option>
              <ng-container *ngFor="let dep of depart">
                <option [value]="dep.id">{{ dep.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <!--Location-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>Location</label>

            <select
              class="form-control form-control-sm selectpicker"
              data-live-search="true"
              id="selectedLocation"
              formControlName="location"
              name="selectedLocation"
            >
              <option value="" [disabled]="true">Select Location</option>
              <ng-container *ngFor="let location of locationData">
                <option [value]="location.id">
                  {{ location?.name }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <!--Related To-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>Related to <span class="text-danger">*</span></label>
            <select
              class="form-control form-control-sm"
              formControlName="related_to"
              [ngClass]="{
                'is-invalid': isAdd && addComplaint.controls.related_to.errors
              }"
              (change)="onChangeRelatedTo($event.target.value)"
            >
              <option value="hims related">HIMS Related</option>
              <option value="others">Others</option>
            </select>
          </div>
        </div>
        <!--Tag-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group tagsInput">
            <div class="d-flex justify-content-between">
              <div>
                <label
                >Tag</label
                >
              </div>
              <div>
                <span
                  class="fa fa-plus cursor-pointer mr-2"
                  (click)="addTags()"
                ></span>
              </div>
            </div>
<!--            <label>Tag </label>-->
            <select
              class="form-control form-control-sm select2"
              multiple="multiple"
              id="medicationsAllergies"
            >
              <ng-container *ngFor="let tag of tagListData">
                <option [value]="tag.id">
                  {{ tag.name }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <!--Frequency of occurrence-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="form-group">
            <label
            >Frequency of occurrence <span class="text-danger">*</span></label
            >
            <select
              class="form-control form-control-sm"
              formControlName="frequency_of_occurrence"
              id="frequencyOccurrence"
              [ngClass]="{
                'is-invalid':
                  isAdd && addComplaint.controls.frequency_of_occurrence.errors
              }"
            >
              <option value="" [disabled]="true">Select</option>
              <option value="new">New</option>
              <option value="repeated">Repeated</option>
            </select>
          </div>
        </div>
        <!--Screen/report-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div
            class="form-group"
            [ngClass]="{
              'is-invalid': isAdd && addComplaint.controls.screen_id.errors
            }"
          >
            <label>Screen/report <span class="text-danger">*</span></label>
            <select
              class="form-control form-control-sm"
              formControlName="screen_id"
              data-live-search="true"
              id="screenId"
              (change)="getScreenfeatures($event.target.value)"
            >
              <option value="" [disabled]="true">Select</option>
              <option value="-1">None of these</option>
              <ng-container *ngFor="let screen of screens">
                <option [value]="screen.id">
                  {{ screen.name }} - {{ screen?.url }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div
          *ngIf="addComplaint.get('screen_id').value === '-1'"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"
        >
          <div class="d-block form-group">
            <label>Detail</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Screen Detail"
              formControlName="screen_detail"
            />
          </div>
        </div>
        <!--Feature/Functionality-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div
            class="form-group"
            [ngClass]="{
              'is-invalid': isAdd && addComplaint.controls.feature_id.errors
            }"
          >
            <div class="d-flex justify-content-between">
              <div>
                <label
                >Feature/Functionality
                  <span class="text-danger">*</span></label
                >
              </div>
              <div *ngIf="activeScreenId && activeScreenId !== '-1'">
                <span
                  class="fa fa-plus cursor-pointer mr-2"
                  (click)="addFeature()"
                ></span>
              </div>
            </div>
            <select
              class="form-control form-control-sm"
              formControlName="feature_id"
              data-live-search="true"
              data-none-selected-text="Select"
              id="feature_id"
              (change)="onFeaturesChange($event.target.value)"
            >
              <option value="" [disabled]="true">Select</option>
              <option value="-1">None of these</option>
              <ng-container *ngFor="let features of featureData">
                <option [value]="features.id">{{ features.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div
          *ngIf="addComplaint.get('feature_id').value === '-1'"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"
        >
          <div class="d-block form-group">
            <label>Detail</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Feature Detail"
              formControlName="feature_detail"
            />
          </div>
        </div>
<!--        <hr class="w-100 line-hide" style="margin-bottom: 20px !important" />-->
        <!--Contact Person Name-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="d-block form-group">
            <label>Contact Person</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Contact Person"
              formControlName="contact_person"
              [ngClass]="{
                'is-invalid':
                  isAdd && addComplaint.controls.contact_person.errors
              }"
            />
          </div>
        </div>
        <!--Name-->
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <div class="form-group">
            <label>Telephone No.</label>
            <input
              type="tel"
              class="form-control form-control-sm"
              (keypress)="_onlyNumeric($event)"
              placeholder="Telephone Number"
              formControlName="phone"
            />
          </div>
        </div>
        <!--link-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="form-group">
            <label>External Link.</label>
            <input
              type="tel"
              class="form-control form-control-sm"
              placeholder="Add link"
              formControlName="external_link"
            />
          </div>
        </div>
        <!--File Upload-->
        <div class="col-4">
          <div class="form-group">
            <label class="mr-2">File Upload</label>
            <input
              type="file"
              class="w-100"
              id="taskFile"
              (change)="fileUploadFunc($event)"
            />
          </div>
        </div>
        <div class="col-12 text-right" style="margin-left: auto">
          <div class="d-flex justify-content-end">
            <div>
          <button
            class="btn btn-sm btn-light-primary ml-5"
            type="button"
            (click)="addAssignData()"
          >Assign to
            <span class="fa fa-plus"></span>
          </button>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div
            class="mt-5"
            formArrayName="assign"
            *ngFor="let f of assignData.controls; let i = index"
          >
            <ng-container *ngIf="f.get('is_remove').value === false">
              <div [formGroupName]="i" class="row">
                <div class="col-lg-4">
                  <div class="">
                    <label>Employee:</label>
                    <select
                      class="form-control form-control-sm selectpicker"
                      data-live-search="true"
                      id="employee{{ i }}"
                      formControlName="assign_to"
                      name="employee"
                    >
                      <option value="" [disabled]="true">
                        Select Employee
                      </option>
                      <ng-container *ngFor="let emp of employee">
                        <option [value]="emp.id">
                          {{ emp?.employee_file_no }} -
                          {{ emp?.first_name }}
                          {{ emp?.middle_name || "" }}
                          {{ emp?.last_name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <label>Start Date</label>
                  <div class="form-group">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      id="fromDate{{ i }}"
                      formControlName="start_date"
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <label>Due Date</label>
                  <div class="form-group">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      id="toDate{{ i }}"
                      formControlName="due_date"
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <label>Time</label>
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      formControlName="time"
                      placeholder="Time"
                    />
                  </div>
                </div>
                <div class="col-lg-1">
                  <div class="form-group">
                    <label>Unit</label>
                    <select
                      class="form-control form-control-sm"
                      formControlName="unit"
                    >
                      <option value="hour">Hour</option>
                      <option value="minutes">Minutes</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-1 ml-auto text-right mt-2">
                  <button
                    (click)="deleteAssignData(i)"
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mt-4"
                    type="button"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x">
                      <svg
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        height="24px"
                        version="1.1"
                        viewBox="0 0 24 24"
                        width="24px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="none"
                          stroke-width="1"
                        >
                          <rect height="24" width="24" x="0" y="0"></rect>
                          <path
                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          ></path>
                          <path
                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000"
                            opacity="0.3"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <!--submit button-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
          <div class="form-group">
            <div class="text-right mt-3">
              <button
                *ngIf="!taskId"
                [disabled]="disabledSubmitBtn"
                class="btn btn-sm btn-primary"
                type="submit"
              >
                {{currentUrl === '/task-management/task-list' ? 'Add Task' : 'Add Order' }}
              </button>
              <button
                *ngIf="taskId"
                [disabled]="disabledSubmitBtn"
                class="btn btn-sm btn-primary"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Card-->
  </div>
  <ng-container>
    <div
      class="card-header-tabs-line culture-card-header pl-4 pr-4"
    >
      <div class="card-toolbar culture-toolbar">
        <ul class="nav nav-tabs nav-bold nav-tabs-line cultures">
          <li class="nav-item" *ngIf="addComplaint?.get('type').value === 'work order'">
<!--          <li class="nav-item" *ngIf="currentUrl !== '/task-management/task-list'">-->
            <a class="nav-link" [ngClass]="{'active': addComplaint?.get('type').value === 'work order'}" data-toggle="tab" href="#workOrder" (click)="getWorkOrder()">
<!--            <a class="nav-link" [ngClass]="{'active': currentUrl !== '/task-management/task-list'}" data-toggle="tab" href="#workOrder" (click)="getWorkOrder()">-->
              <span class="nav-text">Work Order </span>
            </a>
          </li>
          <li class="nav-item" *ngIf="taskId">
            <a [ngClass]="{'active': addComplaint?.get('type').value !== 'work order'}"
              class="nav-link"
              data-toggle="tab"
              href="#comment"
              id="commentTab"
              (click)="getTimeLogAndComments('comments')"
            >
              <span class="nav-text">Comments</span>
            </a>
          </li>

          <li class="nav-item"  *ngIf="taskId">
            <a class="nav-link" data-toggle="tab" href="#logHours" (click)="getTimeLogAndComments('time_lock')">
              <span class="nav-text">Log Hours</span>
            </a>
          </li>

          <li class="nav-item" *ngIf="taskId">
            <a class="nav-link" data-toggle="tab" href="#documents" (click)="getDocuments()">
              <span class="nav-text">Attachments</span>
            </a>
          </li>

          <li class="nav-item" *ngIf="taskId">
            <a class="nav-link" data-toggle="tab" href="#activityStream" (click)="getActivityStream()">
              <span class="nav-text">Activity Stream</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
    <div class="tab-content">
      <!--comment Start-->
      <div  *ngIf="taskId"
        class="tab-pane fade"
        [ngClass]="{'active show': addComplaint?.get('type').value !== 'work order'}"
        id="comment"
        role="tabpanel"
        aria-labelledby="comment"
      >
        <div *ngIf="!workOrder">
        <div class="col-12 mt-6">
          <div class="form-group">
            <textarea
              type="text"
              style="height: 100px; width: 100%"
              class="form-control summerNoteCommentEditor"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="taskComments"
              id="comments"
              placeholder="comments"
            ></textarea>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="form-group">
            <div class="d-flex justify-content-between mt-3">
              <div>
                <div class="d-flex">
                  <div>
                    <app-upload-images
                      [documentType]="'document'"
                      [isUploadImage]="true"
                      (imageData)="sendCommentDocs($event)"
                      [uploadDocumentButtonLabel] = "'Attach Documents'"
                    ></app-upload-images>
                  </div>
                  <div>
                  <div class="d-flex">
                    <div *ngIf="!recording" class="mr-2 cursor-pointer" (click)="toggleRecording()">
                      <button
                        class="btn btn-sm btn-primary pr-7 pl-7"
                        type="button"
                      >
                        <img
                          src="assets/icons/microphone.png"
                          style="height: 20px; width: 20px; margin-right: 7px"
                        />Record Audio
                      </button>
                    </div>
                    <div *ngIf="recording" class="mr-2 cursor-pointer" (click)="toggleRecording()">
                      <button
                        class="btn btn-sm btn-primary pl-5 pr-5"
                        type="button"
                      >
                        <img
                          src="assets/icons/microphone.png"
                          style="height: 20px; width: 20px; margin-right: 5px"
                        />
                        Stop Recording
                      </button>
                    </div>
                    <div class="mr-2 mt-2">
                      <span *ngIf="recording">{{ recordingText }}</span>
                    </div>
                  </div>
                  <div class="d-flex mt-2 align-items-center">
                    <div class="mr-5" id="audioContainer"></div>
                    <div class="mr-2" *ngIf="recordedBlob" (click)="deleteAudio()" style="cursor: pointer;">
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div>
              <button
                class="btn btn-sm btn-primary"
                [disabled]="commentBtn"
                type="button"
                (click)="onSubmitData2('comments')"
              >
                <img
                  src="assets/icons/quotes.png"
                  style="height: 20px; width: 20px; margin-right: 5px"
                />Add Comment
              </button>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <h4>Comments</h4>
        <div class="mt-6">
          <div class="timeline timeline-3">
            <div class="timeline-items">
              <ng-container
                *ngFor="let indexvalue of timeLockAndComments"
              >
                <div class="timeline-item">
                  <div class="timeline-media">
                    <img
                      *ngIf="
                                          indexvalue?.emp_profile_image === '' ||
                                          indexvalue?.emp_profile_image === null
                                        "
                      src="../../../../../assets/media/users/blank.png"
                      style="height: 100%; width: 100%"
                    />
                    <img
                      *ngIf="
                                          indexvalue?.emp_profile_image !== '' &&
                                          indexvalue?.emp_profile_image !== null
                                        "
                      src="{{ imagePath }}{{
                                          indexvalue?.emp_profile_image
                                        }}"
                      style="height: 100%; width: 100%"
                      class="side_applicant_image"
                    />
                  </div>
                  <div class="timeline-content">
                    <div
                      class="d-flex align-items-center justify-content-between mb-3"
                    >
                      <div class="w-100 mr-2">
                        <a
                          class="text-dark-75 text-hover-primary font-weight-bold"
                        >
                                            <span
                                              class="font-weight-bolder text-hover-primary pb-1"
                                            >{{ indexvalue?.first_name }}

                                              {{ indexvalue?.last_name }}</span
                                            >
                          <span
                            class="text-muted font-weight-bold text-muted ml-2"
                          >{{
                              indexvalue?.created_at
                                | date: "dd-MM-yyyy h:mm a"
                            }}</span
                          >
                        </a>
                      </div>
                    </div>
                    <div *ngIf="indexvalue?.comment">
                      <strong>Comments: </strong>
                      <p [innerHTML]="indexvalue?.comment"></p>
                    </div>
                    <div *ngIf="indexvalue?.recording">
                      <audio [src]="indexvalue?.recording" controls></audio>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      </div>
      <!--comment End-->
      <!--                log hours start-->
      <div *ngIf="!workOrder && taskId"
        class="tab-pane fade"
        id="logHours"
        role="tabpanel"
        aria-labelledby="logHours"
      >
        <div class="col-12 mt-4">
          <div class="d-flex justify-content-between">
            <div class="form-group">
              <label>Time Log Entries</label>
            </div>
            <div>
              <button
                class="btn btn-primary btn-sm"
                type="button"
                (click)="timeLog(false)"
              >
                Add Time
              </button>
            </div>
          </div>
          <div>
            <table class="table info-table table-responsive-sm">
              <th style="width: 20%">
                <span>Name </span>
              </th>
              <th style="width: 20%">
                <span>Date </span>
              </th>
              <th style="width: 15%">
                <span>Log Time </span>
              </th>
              <th style="width: 35%">
                <span>Comments </span>
              </th>

              <ng-container *ngFor="let taskData of timeLockAndComments">
                <tr>
                  <td>{{taskData?.first_name + " " + taskData?.last_name}}</td>
                  <td>{{taskData?.time_lock_date | dateTimeFormat}}</td>
                  <td>{{taskData?.daily_log}} {{taskData?.daily_log_unit}}</td>
                  <td>{{taskData?.comment}}</td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
      <!--                log hours End-->
      <!--                activity stream start-->
      <div *ngIf="!workOrder && taskId"
        class="tab-pane fade"
        id="activityStream"
        role="tabpanel"
        aria-labelledby="activityStream"
      >
        <div class="col-12 mt-6">
          <h4>Transaction History</h4>

          <div class="mt-6">
            <div class="timeline timeline-3">
              <div class="timeline-items">
                <ng-container
                  *ngFor="let indexvalue of complaintSingledata?.transaction"
                >
                  <div class="timeline-item">
                    <div class="timeline-media">
                      <img
                        *ngIf="
                                          indexvalue?.employee?.profile_image === '' ||
                                          indexvalue?.employee?.profile_image === null
                                        "
                        src="../../../../../assets/media/users/blank.png"
                        style="height: 100%; width: 100%"
                      />
                      <img
                        *ngIf="
                                          indexvalue?.employee?.profile_image !== '' &&
                                          indexvalue?.employee?.profile_image !== null
                                        "
                        src="{{ imagePath }}{{
                                          indexvalue?.employee?.profile_image
                                        }}"
                        style="height: 100%; width: 100%"
                        class="side_applicant_image"
                      />
                    </div>
                    <div class="timeline-content">
                      <div
                        class="d-flex align-items-center justify-content-between mb-3"
                      >
                        <div class="w-100 mr-2">
                          <a
                            class="text-dark-75 text-hover-primary font-weight-bold"
                          >
                                            <span
                                              class="font-weight-bolder text-hover-primary pb-1"
                                            >{{ indexvalue?.employee?.first_name }}
                                              <span
                                                *ngIf="
                                                  indexvalue?.employee?.middle_name !== null
                                                "
                                              >
                                                {{ indexvalue?.employee?.middle_name }}</span
                                              >
                                              {{ indexvalue?.employee?.last_name }}</span
                                            >
                            <span
                              class="text-muted font-weight-bold text-muted ml-2"
                            >{{
                                indexvalue?.created_at
                                  | date: "dd-MM-yyyy h:mm a"
                              }}</span
                            >
                          </a>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div
                          style="flex-basis: 50%"
                          *ngIf="indexvalue?.description"
                        >
                          <div class="d-block">
                            <span><strong>Changes:</strong></span>
                            <p [innerHTML]="indexvalue?.description"></p>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="indexvalue?.comments">
                        <strong>Comments: </strong>
                        <p [innerHTML]="indexvalue?.comments"></p>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--                activity stream end-->
<!--      work order list card start-->
      <form [formGroup]="workOrderForm" (ngSubmit)="submitWorkOrder()">
<!--        [ngClass]="{'active show': currentUrl !== '/task-management/task-list'}"-->
<!--        <div *ngIf="currentUrl !== '/task-management/task-list'"-->
        <div *ngIf="workOrder && addComplaint?.get('type').value === 'work order'"
        class="tab-pane fade"
        [ngClass]="{'active show': addComplaint?.get('type').value === 'work order', 'pointer-events-no' : complaintSingledata?.items_list.length || complaintSingledata?.labour_work.length || complaintSingledata?.item_comments || complaintSingledata?.labour_work_comments || complaintSingledata?.store_id || complaintSingledata?.location_id}"
        id="workOrder"
        role="tabpanel"
        aria-labelledby="workOrder"
      ><div class="row p-2">
          <div class="col-3 mt-6"><h5>Add Items <span *ngIf="complaintSingledata?.ir_no">(IR # {{complaintSingledata?.ir_no}})</span></h5></div>
        <!--store-->
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="d-block form-group">
            <label>Store <span class="text-danger">*</span></label>
            <div            [ngClass]="{
                  'is-invalid': isAddOrder && this.workOrderForm.controls.store_id.errors
                }">
            <select
              class="form-control form-control-sm bootstrap-select-container"
              data-conainer="body"
              data-live-search="Select Store"
              formControlName="store_id"
              id="store"
              name="store"
              data-none-selected-text="Select Store"
            >
              <option value="" disabled>Select Store</option>
              <ng-container *ngFor="let store of parentStores">
                <option [value]="store.id">{{ store?.name }}</option>
              </ng-container>
            </select>
            </div>
          </div>
        </div>
        <!--Location-->
        <div
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
        >
          <div class="form-group">
            <label>Location <span class="text-danger">*</span></label>
            <div   [ngClass]="{
                  'is-invalid': isAddOrder && this.workOrderForm.controls.location_id.errors
                }">
              <select
                class="form-control form-control-sm selectpicker locationDropdown"
                data-container="body"
                data-live-search="true"
                formControlName="location_id"
              >
                <option value="" [disabled]="true">Select Location</option>
                <ng-container *ngFor="let location of locationData">
                  <option [value]="location.id">
                    {{ location?.name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        </div>
              <table
                class="table table-vertical-center table-head-custom"
                id="kt_advance_table_widget_1"
              >
                <thead>
                <tr class="text-left">
                  <th style="width: 30%">Item Name</th>
                  <th style="width: 20%">
                    Qty
                  </th>
                  <th style="width: 20%">
                    Estimated Cost
                  </th>
                  <th style="width: 20%" class="text-right">
                    <button (click)="addMoreItems()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm" type='button'>
                                        <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                          width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"/>
                                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                                <path
                                                  d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                                  fill="#000000"/>
                                            </g></svg><!--end::Svg Icon-->
                                          </span>
                    </button>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr    formArrayName="items_list"
                       *ngFor="let item of itemsListArray.controls; let i = index"
                >
                  <ng-container [formGroupName]="i"
                  >
                    <td *ngIf="workOrder && !item.get('product_name').value">
                      <app-dynamic-search
                                           [formControl]="item.get('item_id')"
                                           [index]="i"
                                           [apiUrl]="'/inventory/get_product_list'"
                                           [multiple]="false"
                                           [editable]="false"
                      ></app-dynamic-search>
                    </td>
                    <td *ngIf="workOrder && item.get('product_name').value">
                      <input type="text" class="form-control form-control-sm" formControlName="product_name">
                    </td>
                    <td>
                      <input type="text" class="form-control form-control-sm" placeholder="0" formControlName="item_quantity" (keypress)="_onlyNumeric($event);noDecimalPoint($event)">
                    </td>
                    <td>
                      <input type="text" class="form-control form-control-sm" placeholder="0" formControlName="estimated_cost" (keypress)="_onlyNumeric($event);noDecimalPoint($event)">
                    </td>
                    <td class="text-right">
                      <button (click)="removeItem(i)"
                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                        type="button"
                      >
                          <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                />
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g></svg
                          ><!--end::Svg Icon--></span
                          >
                      </button>
                    </td>
                  </ng-container>
                </tr>
                <tr *ngIf="itemsListArray.length">
                  <td></td>
                  <td class="font-weight-bold text-right">Sub Total</td>
                  <td class="font-weight-bold">{{ subTotal | number }}</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            <div class="row p-2 mt-4">
            <div class="col-12">
              <label class="font-weight-bold">Items Detail</label>
              <div
                id="item_detail"
                class="item_detail"
              ></div>
            </div>
            </div>
            <div class="mt-6 p-2"><h5>Add Labour Work</h5></div>
            <table
              class="table table-vertical-center table-head-custom"
              id="kt_advance_table_widget_2"
            >
              <thead>
              <tr class="text-left">
                <th style="width: 30%">Labour Work Details</th>
                <th style="width: 20%">
                  Type
                </th>
                <th style="width: 20%">
                  Estimated Cost
                </th>
                <th style="width: 20%" class="text-right">
                  <button (click)="addMoreLabourWork()" class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm" type='button'>
                                        <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                          width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"/>
                                              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                                <path
                                                  d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                                  fill="#000000"/>
                                            </g></svg><!--end::Svg Icon-->
                                          </span>
                  </button>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr    formArrayName="labour_work"
                     *ngFor="let item of labourWorkArray.controls; let i = index"
              >
                <ng-container
                  [formGroupName]="i"
                >
                  <td>
                    <input type="text" class="form-control form-control-sm" placeholder="Enter Labour Work Detail" formControlName="work_detail">
                  </td>
                  <td>
                    <select class="form-control form-control-sm bootstrap-select-container" id="orderTypes" data-container="body" data-live-search="true"  formControlName="work_type">
                      <option value="">Select</option>
                      <ng-container *ngFor="let type of workOrderTypes">
                        <option [value]="type?.id">{{type?.name}}</option>
                      </ng-container>
                    </select>
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-sm" placeholder="0" formControlName="estimated_cost" (keypress)="_onlyNumeric($event);noDecimalPoint($event)">
                  </td>
                  <td class="text-right">
                    <button (click)="removeLabourWork(i)"
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                      type="button"
                    >
                          <span class="svg-icon svg-icon-primary svg-icon-2x"
                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                />
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g></svg
                          ><!--end::Svg Icon--></span
                          >
                    </button>
                  </td>
                </ng-container>
              </tr>
              <tr *ngIf="labourWorkArray.length">
                <td></td>
                <td class="font-weight-bold text-right">Sub Total</td>
                <td class="font-weight-bold">{{ labourWorksubTotal | number }}</td>
                <td></td>
              </tr>
              <tr *ngIf="labourWorkArray.length || itemsListArray.length">
                <td></td>
                <td class="font-weight-bold text-right">Grand Total:</td>
                <td class="font-weight-bold">{{subTotal + labourWorksubTotal | number}}</td>
              </tr>
              </tbody>
            </table>
            <div class="row p-2 mt-4">
              <div class="col-12">
                <label class="font-weight-bold">Labour Work Detail</label>
                <div
                  id="labour_detail"
                  class="labour_detail"
                ></div>
              </div>
            </div>
            <div class="text-right">
              <button [disabled]="complaintSingledata?.items_list.length || complaintSingledata?.labour_work.length || complaintSingledata?.item_comments || complaintSingledata?.labour_work_comments || complaintSingledata?.store_id || complaintSingledata?.location_id"
                type="submit" class="btn btn-sm btn-primary">Save</button>
            </div>

      </div>
      </form>
<!--      work order list card end-->
      <!--                documents start-->
      <div *ngIf="!workOrder && taskId"
        class="tab-pane fade"
        id="documents"
        role="tabpanel"
        aria-labelledby="documents"
      >
        <div class="col-12 mt-4">
          <div class="d-flex justify-content-between mb-4">
            <div class="form-group">
              <h3>Documents</h3>
            </div>
            <div>
              <app-upload-images
                [documentType]="'document'"
                [isUploadImage]="true"
                (imageData)="sendImages($event, false)"
                [uploadDocumentButtonLabel] = "'Attach Documents'"
              ></app-upload-images>
            </div>
          </div>
          <div class="row">
            <div class="col-4 mb-8" *ngFor="let docs of documentsList; let i = index">
              <div
                class="patient-docs position-relative"
                style="border: 1px solid #b7b5b5"
                [style.background-image]="'url(' + docs.file + ')'"
                (click)="openDoc(docs?.file, docs?.id)"
              >
                <!-- Delete Icon -->
                <i
                  class="fa fa-trash-alt delete-icon"
                  ngbTooltip="Delete Document"
                  (click)="deleteDoc(docs?.id); $event.stopPropagation()"
                ></i>
              </div>
              <div class="mt-2">
                <a>
                  {{docs?.file_name}}{{docs?.file_extension}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--                documents End-->
    </div>
  </ng-container>
</form>

<!--Feature Modal-->
<div
  class="modal fade"
  id="featureModal"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-lg" role="document">
    <div class="modal-content">
      <div class="modal-header p-3">
        <h5 class="modal-title" id="exampleModalLabel">Features and Functionalities</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" (click)="closeFeatureModal()">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--begin::Container-->
        <div class="row">
          <div class="col-sm-2 col-2">
            <div class="form-group">
              <label>Code</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="featureCode"
                (keypress)="_keyPressOnlyCharNoSpace($event)"
              />
            </div>
          </div>

          <div class="col-sm-5 col-6">
            <div class="form-group">
              <label>Feature or Functionality</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="featureName"
              />
            </div>
          </div>

          <div class="col-lg-2 col-md-2 col-sm-1 pl-10 form-group">
            <label>Status</label>
            <div class="mt-2">
              <label class="checkbox checkbox-sm">
                <input
                  type="checkbox"
                  [(ngModel)]="featureStatus"
                  autocomplete="off"
                />
                <span></span>
              </label>
            </div>
          </div>

          <div class="col-sm-3 col-3 pl-0 pr-7 text-right">
            <div class="form-group">
              <button
                class="btn btn-light-primary btn-sm mt-5"
                *ngIf="featureId === ''"
                (click)="addNewFeature()"
              >
                Add
              </button>
              <button
                class="btn btn-light-primary btn-sm mt-5"
                *ngIf="featureId !== ''"
                (click)="updateFeatureControl()"
              >
                Update
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-vertical-center table-head-custom">
            <thead>
            <tr>
              <th style="width: 16%">Code</th>
              <th style="width: 46%"><span>Feature/Functionality</span></th>
              <th style="width: 19%">Status</th>
              <th class="text-right" style="width: 19%">Update</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of featureData; let i = index">
              <td>{{ data?.code }}</td>
              <td>{{ data?.name }}</td>
              <td>
                  <span
                    class="label label-lg label-light-primary label-inline"
                    *ngIf="data?.is_active === true"
                  >Active</span
                  >
                <span
                  class="label label-lg label-light-danger label-inline"
                  *ngIf="data?.is_active === false"
                >InActive</span
                >
              </td>
              <td class="text-right">
                <a
                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                  (click)="updateFeature(data)"
                >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                            d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                          ></path>
                          <path
                            d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            opacity="0.3"
                          ></path>
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer p-3">
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="button"
              class="btn btn-sm btn-default"
              (click)="closeFeatureModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <!--end::Container-->
      <!--end::Entry Modal-->
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="timeLogModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Time Log</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="timeLogData" (ngSubmit)="onSubmitData2('daily_log_comments')">
        <div class="modal-body">
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label>Time<span class="text-danger">*</span></label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Time"
                  formControlName="daily_log"
                  [ngClass]="{
                    'is-invalid': timeCheck && timeLogData.controls.daily_log.errors
                  }"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label>Unit</label>
                <select
                  class="form-control form-control-sm"
                  formControlName="daily_log_unit"
                >
                  <option value="hour">Hour</option>
                  <option value="minutes">Minutes</option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Date</label>
                <input type="date" formControlName="date" class="form-control form-control-sm" [max]="todayDate">
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label>Comments<span class="text-danger">*</span></label>
                <textarea
                  style="height: 70px; resize: none"
                  class="form-control form-control-sm"
                  placeholder="comments"
                  formControlName="comment"
                  [ngClass]="{
                    'is-invalid':
                      timeCheck && timeLogData.controls.comment.errors
                  }"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeTimeLogModal()">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!--Add Tags-->
<div
  class="modal fade"
  id="addTypeModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Tags</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="taskTab">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-4 form-group">
              <label>Name<span class="text-danger">*</span></label>
              <input
                class="form-control form-control-sm"
                formControlName="name"
                id="name"
                type="text"
                [ngClass]="{
                  'is-invalid': isAddType && this.taskTab.controls.name.errors
                }"
              />
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 mt-8">
              <div class="form-group">
                <!--                  <label> Status  </label>-->
                <label class="checkbox checkbox-sm">
                  <input type="checkbox" formControlName="is_removed" />
                  <span class="mr-2"></span>Status
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeAddTagModal()">
          Close
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          (click)="submitType()"
        >
          Add New Tag
        </button>
        <!--        <button type="button" class="btn btn-primary" (click)="submitType()">Save changes</button>-->
      </div>
    </div>
  </div>
</div>
