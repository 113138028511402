<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">{{ screenName }}</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!-- container-xl container-xxl -->
    <div class="container-fluid">
      <div class="card card-custom">
        <div class="card-body">
          <form [formGroup]="labServicesForm" (ngSubmit)="searchInvoices()">
            <div class="row">
              <div [ngClass]="{'col-md-3 col-sm-3 col-4': showFilterBtn, 'col-md-4 col-sm-4 col-4': !showFilterBtn}">
                <div class="form-group">
                  <label>From Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="fromDate"
                    data-toggle="datetimepicker"
                    data-target="#fromDate"
                    (keydown)="preventChangeDate()"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div [ngClass]="{'col-md-3 col-sm-3 col-4': showFilterBtn, 'col-md-4 col-sm-4 col-4': !showFilterBtn}">
                <div class="form-group">
                  <label>To Date <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control form-control-sm datetimepicker-input"
                    id="toDate"
                    data-target="#toDate"
                    data-toggle="datetimepicker"
                    (keydown)="preventChangeDate()"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div *ngIf="showFilterBtn" class="col-md-2 col-sm-3 col-4">
                <label>Sub Department</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm"
                      formControlName="department_id"
                    >
                      <option value="" disabled>Select</option>
                      <option value="">All</option>
                      <ng-container *ngFor="let department of subDepartments">
                        <option [value]="department.id">
                          {{ department.title }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>

              <div *ngIf="showFilterBtn" class="col-2">
                <label>Patient Type</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm selectpicker"
                      id="patientType"
                      formControlName="patient_type"
                      data-dropup-auto="false"
                      data-live-search="true"
                    >
                      <option value="" disabled>Select</option>
                      <option value="all" [selected]="true">All</option>
                      <option
                        [value]="patientType.id"
                        *ngFor="let patientType of patientTypes"
                      >
                        {{ patientType.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div [ngClass]="{'col-2': showFilterBtn, 'col-4': !showFilterBtn}">
                <label>Status</label>
                <div class="form-group">
                  <div class="d-block">
                    <select
                      class="form-control form-control-sm"
                      formControlName="lab_status"
                    >
                      <option value="" disabled>Select</option>
                      <option value="" [selected]="true">All</option>
                      <option value="Open">Pending</option>
                      <option value="Collected">Sampled</option>
                      <option value="Received">Received</option>
                      <option value="Unverified">Reported</option>
                      <option value="Verified">Verified</option>
                      <option value="Delivered">Delivered</option>
                    </select>
                  </div>
                </div>
              </div>
              <div [ngClass]="{'col-3': showFilterBtn, 'col-4': !showFilterBtn}">
                <div>
                  <label>Search Type</label>
                  <div class="form-group">
                    <div class="d-block">
                      <select
                        class="form-control form-control-sm"
                        formControlName="search_type"
                      >
                        <option value="" disabled>Select</option>
                        <option value="mrn">MRN</option>
                        <option value="patient_name">Patient Name</option>
                        <option value="lab_id">LAB ID</option>
                        <option value="lab_test_name">LAB Test Name</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngClass]="{'col-5': showFilterBtn, 'col-4': !showFilterBtn}">
                <div>
                  <label>Search</label>
                  <div class="form-group">
                    <div class="d-block">
                      <input
                        class="form-control form-control-sm"
                        placeholder="Search"
                        formControlName="search_name"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!--add button-->
              <div class="col-4">
                <div class="form-group">
                  <div class="d-block">
                    <button
                      type="submit"
                      class="btn btn-primary btn-sm cus_btm"
                      ngbTooltip="Search Result"
                      placement="bottom"
                    >
                      Search
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm ml-2"
                      *ngIf="showRequisitionButton"
                      [routerLink]="'/blood-bank/blood-requisition-receiving'"
                    >
                      Requisitions List
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm cus_btm ml-2"
                      (click)="openLogsReports()"
                    >
                      Print Logs
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary btn-sm ml-2"
                      *ngIf="showDocumentsRequisitionButton"
                      (click)="openPatientDocs()"
                    >
                      Requisitions List
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card card-custom gutter-b mt-7">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Table-->
          <div class="table_top_bar mb-3">
            <div class="d-flex justify-content-between col-12">
              <div class="col-6">
                <div class="dropdown dropdown-inline mr-2" *ngIf="showFilterBtn">
                  <button
                    (click)="filtersInvoices('')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5"
                  >
                    All
                  </button>
                  <button
                    (click)="filtersInvoices('opd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 opd-btn"
                  >
                    OPD
                  </button>
                  <button
                    (click)="filtersInvoices('ipd')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 ipd-btn"
                  >
                    IPD
                  </button>
                  <button
                    (click)="filtersInvoices('urgent')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 urgent"
                  >
                    Urgent
                  </button>
                  <button
                    (click)="filtersInvoices('returned')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 returned"
                  >
                    Returned
                  </button>
                  <button
                    (click)="filtersInvoices('cancel')"
                    type="button"
                    class="btn btn-light-primary btn-sm mr-5 cancelLabButton"
                  >
                    Canceled
                  </button>
                </div>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <div class="dropdown dropdown-inline mr-2">
                  <button *ngIf="labCollectionAllow"
                          type="button"
                          [disabled]="selectedTestCollectArray.length === 0"
                          class="btn btn-light-primary btn-sm mr-2"
                          (click)="collectTestId('', '', true)"
                  >
                    Collect
                  </button>
                  <button *ngIf="isShowCsvBtn"
                          type="button"
                          class="btn btn-light-primary btn-sm mr-2"
                          (click)="openCsvModal()"
                  >
                    Upload CSV
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2"
                    [disabled]="selectedTestArray.length === 0"
                    (click)="resetActivePatient()"
                  >
                    Clear
                  </button>
                  <button *ngIf="showDeliverViaCnicBtn"
                    class="btn btn-light-primary btn-sm mr-2"
                    [disabled]="selectedTestArray.length === 0 || isDelivered"
                    (click)="deliveredResultLabMultiple()"
                  >
                    Deliver
                  </button>
                  <button *ngIf="showDeliverViaEmailBtn"
                    class="btn btn-light-primary btn-sm mr-2"
                    [disabled]="selectedTestArray.length === 0 || isDelivered"
                    (click)="deliveredResultVaiEmailLabMultiple()"
                  >
                    Deliver
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm"
                    [disabled]="
                      selectedTestArray.length === 0 ||
                      settingData?.enable_external_lis
                    "
                    (click)="printConfirmModal()"
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="table-responsive position-relative">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_3"
                style="min-height: 220px"
              >
                <thead>
                <tr>
                  <th style="width: 1%"></th>
                  <th style="width: 15%">Date / Lab Id</th>
                  <th style="width: 17%">Test Name / Department</th>
                  <th style="width: 15%">Patient Name / MRN#</th>
                  <th style="width: 18%">Patient Category/ Type</th>
                  <th style="width: 15%" *ngIf="isEditSpecimen">Specimen/ From</th>
                  <th style="width: 7%">Status</th>
                  <th style="width: 6%" *ngIf="labCollectionAllow">Collect</th>
                  <th class="text-right" style="width: 7%">Action</th>
                  <!--                  <th style="width: 10%"></th>-->
                  <!--                  <th style="width: 10%"></th>-->
                  <!--                  <th style="width: 8%"></th>-->
                </tr>
                </thead>
                <tbody>
                <ng-container
                  *ngFor="let element of pageDataList; let i = index"
                >
                  <tr
                    [ngClass]="{
                        returned: element?.rejected === true,
                        urgent: element?.urgent === true
                      }"
                  >
                    <td
                      [ngClass]="{
                          'ipd-btn': element?.ipd === true,
                          'opd-btn': element?.ipd === false
                        }"
                    ></td>
                    <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ element?.created_at | dateTimeFormat }}</span
                        >
                      <span class="text-muted font-weight-bold d-block"
                      >{{ element?.lab_id }}
                        </span>
                    </td>
                    <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ element?.test }}</span
                        >
                      <span class="text-muted font-weight-bold d-block">{{
                          element?.department_name
                        }}</span>
                    </td>
                    <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ element?.patient_title }}
                          {{ element?.patient_name }}</span
                        >
                      <span class="text-muted font-weight-bold d-block"
                      >{{ element?.patient_mrn }}/{{
                          element?.patient_gender?.charAt(0) | titlecase
                        }}/{{
                          element?.age
                        }}{{
                          element?.patient_age_type?.charAt(0) | titlecase
                        }}</span
                      >
                    </td>
                    <td>
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >
                          <span
                            *ngIf="
                              element?.ward_department === 'Walk In';
                              else elseDepartment
                            "
                          >Walk In</span
                          >
                          <ng-template #elseDepartment>
                            <span>{{ element?.patient_category }}</span
                            ><span *ngIf="element?.patient_category === 'IPD'">
                              - {{ element?.ward_department }}</span
                          >
                          </ng-template>
                        </span>
                      <span class="text-muted font-weight-bold d-block">{{
                          element?.patient_type
                        }}</span>
                    </td>
                    <td *ngIf="isEditSpecimen"
                        [ngClass]="{
                          'pointer-events-no': element.status !== 'Open'
                        }"
                    >
                        <span
                          class="label label-lg label-light-success label-inline cursor-pointer"
                          (click)="
                            changeSpicmen(element.id, i, 'collect', element)
                          "
                        >
                          {{
                            element?.specimen_name
                              ? element?.specimen_name
                              : "Nill"
                          }}</span
                        >
                      <!--                      <ng-template #performingElse>-->
                      <!--                    <span class="label label-lg label-light-success label-inline cursor-pointer"-->
                      <!--                          (click)="changeSpicmen(element.id, i, 'collect', element)">Nill</span>-->
                      <!--                      </ng-template>-->
                      <span
                        class="label label-lg label-light-warning label-inline cursor-pointer d-flex mt-3"
                        (click)="
                            changeSampleTaken(element.id, i, 'next', element)
                          "
                      >{{
                          element?.sample_taken_from_name
                            ? element?.sample_taken_from_name
                            : "Nill"
                        }}</span
                      >
                      <!--                      <ng-template #referringElse>-->
                      <!--                    <span class="label label-lg label-light-warning label-inline cursor-pointer d-flex mt-3"-->
                      <!--                          (click)="changeSampleTaken(element.id, i, 'next', element)">Nill</span>-->
                      <!--                      </ng-template>-->
                    </td>
                    <td>
                        <span
                          class="text-primary"
                          *ngIf="
                            element?.status === 'Open' &&
                            element?.service_cancelled === false
                          "
                        ><strong>Pending</strong></span
                        >
                      <span
                        class="cancelledStatus"
                        *ngIf="
                            element?.status === 'Open' &&
                            element?.service_cancelled === true
                          "
                      ><strong>Cancelled</strong></span
                      >
                      <span
                        style="color: #f0ad4e"
                        *ngIf="element?.status === 'Collected'"
                      ><strong>Sampled</strong></span
                      >
                      <span
                        class="text-success"
                        *ngIf="element?.status === 'Received'"
                      ><strong>Received</strong></span
                      >
                      <span
                        style="color: #70b8ea"
                        *ngIf="element?.status === 'Unverified'"
                      ><strong>Reported</strong></span
                      >
                      <span
                        style="color: #ffaedb"
                        *ngIf="element?.status === 'Verified'"
                      ><strong>Verified</strong></span
                      >
                      <span
                        class="text-danger"
                        *ngIf="element?.status === 'Delivered'"
                      ><strong>Delivered</strong></span
                      >
                      <span
                        (click)="openWhatsApp(element)"
                        class="text-danger d-flex cursor-pointer"
                        *ngIf="
                            (element?.status === 'Delivered' ||
                              element?.status === 'Verified') &&
                            smsControls.is_lab_whatsapp_sms
                          "
                      >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            width="26px"
                            height="26px"
                            clip-rule="evenodd"
                          >
                            <path
                              fill="#fff"
                              d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                            />
                            <path
                              fill="#fff"
                              d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                            />
                            <path
                              fill="#cfd8dc"
                              d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                            />
                            <path
                              fill="#40c351"
                              d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                            />
                            <path
                              fill="#fff"
                              fill-rule="evenodd"
                              d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                    </td>
                    <!--                    <td>{{element.send_out}}</td>-->
                    <!--                    <td class="pr-0">-->
                    <!--                      <ng-container *ngIf="element.service_cancelled === false" >-->
                    <!--                        <button type="button" class="btn btn-light-primary btn-sm" (click)="collectLabTestConform(element.id, i, 'collect', element)">Collect</button><br>-->
                    <!--                        <button type="button" class="btn btn-light-primary btn-sm mt-1" (click)="collectLabTestConform(element.id, i, 'next', element)">Collect & Next</button>-->
                    <!--                      </ng-container>-->
                    <!--                    </td>-->
                    <td *ngIf="labCollectionAllow">
                      <ng-container
                        *ngIf="
                            element?.status === 'Open' &&
                            element?.service_cancelled === false
                          "
                      >
                        <label
                          class="checkbox checkbox-sm justify-content-center"
                          *ngIf="
                              collectActivePatient === '' ||
                              collectActivePatient === element.patient_mrn
                            "
                          (change)="selectTestToCollect(element, $event)"
                        >
                          <input class="printCheckbox" type="checkbox"/>
                          <span></span>
                        </label>
                      </ng-container>
                    </td>
                    <td class="text-right">
                      <div class="d-flex justify-content-between">
                        <div class="text-right">
                          <div
                            *ngIf="
                                (element?.status === 'Verified' ||
                                  element?.status === 'Delivered') &&
                                labReportDeliverAllow
                              "
                          >
                            <ng-container
                              *ngIf="
                                  !(
                                    settingData.print_unpaid_invoice &&
                                    element.payment_status === 'unpaid' &&
                                    (element.patient_category === 'OPD' ||
                                      element.patient_category === 'EMR')
                                  ) &&
                                  !(
                                    settingData.print_unapproved_discount &&
                                    element.service_discount_approved_status ===
                                      1
                                  )
                                "
                            >
                              <label
                                class="checkbox checkbox-sm"
                                *ngIf="
                                    activePatient === '' ||
                                    activePatient === element.patient_mrn
                                  "
                                (change)="selectLabTest(element, $event)"
                              >
                                <input
                                  class="printCheckbox"
                                  type="checkbox"
                                />
                                <span></span>
                              </label>
                            </ng-container>
                            <!--                      <ng-container *ngIf="element?.is_delivered === false">-->
                            <!--                        <span class="label font-weight-bold label-lg label-light-danger label-inline">UnPaid OPD Service</span>-->
                            <!--                      </ng-container>-->
                          </div>
                        </div>
                        <div
                          class="dropdown dropdown-inline"
                          *ngIf="
                              element?.status !== 'Verified' &&
                              element.service_cancelled === false
                            "
                        >
                          <button
                            type="button"
                            class="btn btn-light-primary btn-icon btn-sm"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="ki ki-bold-more-ver"></i>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right">
                            <ng-container *ngIf="isShowBarcodeBtn">
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="element?.status === 'Open' || element?.status === 'Collected' ||
                                element?.status === 'Received'"
                                (click)="openQrcode(element.id)"
                              >Barcode</a
                              >
                            </ng-container>
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="
                                  element?.status === 'Open' &&
                                  labCollectionAllow
                                "
                              (click)="
                                  collectLabTestConform(
                                    element.id,
                                    i,
                                    'collect',
                                    element
                                  )
                                "
                            >Collect</a
                            >
                            <ng-container *ngIf="isShowRejectBtn">
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="element?.status === 'Open'"
                                (click)="rejectLabTest(element, i)"
                              >Reject</a
                              >
                            </ng-container>
                            <ng-container *ngIf="isShowCancelBtn">
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="
                                  element?.status === 'Open' &&
                                  element?.payment_status === 'paid'
                                "
                                (click)="cancelLabTest(element, i)"
                              >Cancel</a
                              >
                            </ng-container>
                            <ng-container *ngIf="isShowCancelBtn">
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="
                                  element?.status === 'Open' &&
                                  element?.payment_status === 'unpaid'
                                "
                                (click)="openAddDiscountModal(element)"
                              >Cancel</a
                              >
                            </ng-container>
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="
                                  element?.status === 'Collected' &&
                                  labReceivableAllow
                                "
                              (click)="collectTestRecievable(element.id, i)"
                            >Received</a
                            >
                            <ng-container *ngIf="isShowRejectBtn">
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="
                                  element?.status === 'Collected' &&
                                  labReceivableAllow
                                "
                                (click)="rejectLabTest(element, i)"
                              >Reject</a
                              >
                            </ng-container>
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="
                                  element?.status === 'Received' &&
                                  labResultEntryAllow
                                "
                              (click)="
                                  getReportDetail(element, i);
                                  getPendingResults(element.patient_mrn)
                                "
                            >Add Report</a
                            >
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="
                                  element?.status === 'Unverified' &&
                                  labReportVerifyAllow
                                "
                              (click)="
                                  getReportDetail(element, i);
                                  getPendingResults(element.patient_mrn)
                                "
                            >Update Report</a
                            >
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="
                                  element?.status === 'Delivered' &&
                                  showReportUpdateAfterDeliver
                                "
                              (click)="
                                  getReportDetail(element, i);
                                  getPendingResults(element.patient_mrn)
                                "
                            >Update Report</a
                            >
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="updateReportedTImeAllow && element?.status === 'Delivered'"
                              (click)="openUpdateReportedDateModal(element?.id)"
                            >Update Reported Time</a
                            >
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="
                                  element?.status === 'Unverified' &&
                                  labReportVerifyAllow
                                "
                              (click)="
                                  verifyReport(
                                    element.id,
                                    element.patient_mrn,
                                    i
                                  )
                                "
                            >Approve</a
                            >
                            <ng-container *ngIf="isShowRejectBtn">
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="
                                  element?.status === 'Unverified' &&
                                  labReportVerifyAllow
                                "
                                (click)="rejectLabTest(element, i)"
                              >Reject</a
                              >
                            </ng-container>
                            <ng-container
                              *ngIf="
                                  !(
                                    settingData.print_unpaid_invoice &&
                                    element.payment_status === 'unpaid' &&
                                    (element.patient_category === 'OPD' ||
                                      element.patient_category === 'EMR')
                                  ) &&
                                  !(
                                    settingData.print_unapproved_discount &&
                                    element.service_discount_approved_status ===
                                      1
                                  )
                                "
                            >
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="
                                    element?.status === 'Unverified' &&
                                    labReportVerifyAllow
                                  "
                                (click)="previewReport(element, i)"
                              >Preview</a
                              >
                            </ng-container>
                            <ng-container
                              *ngIf="
                                  !(
                                    settingData.print_unpaid_invoice &&
                                    element.payment_status === 'unpaid' &&
                                    (element.patient_category === 'OPD' ||
                                      element.patient_category === 'EMR')
                                  ) &&
                                  !(
                                    settingData.print_unapproved_discount &&
                                    element.service_discount_approved_status ===
                                      1
                                  )
                                "
                            >
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="element?.status === 'Delivered'"
                                (click)="previewReport(element, i)"
                              >Preview</a
                              >
                            </ng-container>
                          </div>
                        </div>
                        <div
                          class="dropdown dropdown-inline"
                          *ngIf="
                              element?.status === 'Verified' &&
                              element.service_cancelled === false
                            "
                        >
                          <button
                            type="button"
                            class="btn btn-light-primary btn-icon btn-sm"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="ki ki-bold-more-ver"></i>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="
                                  element?.status === 'Verified' &&
                                  showReportUpdateAfterDeliver
                                "
                              (click)="
                                  getReportDetail(element, i);
                                  getPendingResults(element.patient_mrn)
                                "
                            >Update Report</a
                            >
                            <a
                              class="dropdown-item cursor-pointer"
                              *ngIf="updateReportedTImeAllow && element?.status === 'Verified'"
                              (click)="openUpdateReportedDateModal(element?.id)"
                            >Update Reported Time</a
                            >
                            <ng-container
                              *ngIf="
                                  !(
                                    settingData.print_unpaid_invoice &&
                                    element.payment_status === 'unpaid' &&
                                    (element.patient_category === 'OPD' ||
                                      element.patient_category === 'EMR')
                                  ) &&
                                  !(
                                    settingData.print_unapproved_discount &&
                                    element.service_discount_approved_status ===
                                      1
                                  )
                                "
                            >
                              <a
                                class="dropdown-item cursor-pointer"
                                *ngIf="element?.status === 'Verified'"
                                (click)="previewReport(element, i)"
                              >Preview</a
                              >
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div
                        class="text-right ml-10"
                        *ngIf="
                            element.service_cancelled === true &&
                            labCollectionAllow
                          "
                      >
                        <ng-container>
                          <button
                            type="button"
                            class="btn btn-light-primary btn-icon btn-sm"
                            (click)="returnCancelLabTest(element)"
                          >
                              <span class="pt-1"
                              ><i class="fas fa-undo"></i>
                              </span></button
                          >
                          <br/>
                        </ng-container>
                        <!--                      <ng-container *ngIf="element?.is_delivered === false">-->
                        <!--                        <span class="label font-weight-bold label-lg label-light-danger label-inline">UnPaid OPD Service</span>-->
                        <!--                      </ng-container>-->
                      </div>
                    </td>
                    <!--                    <td class="pr-0">-->
                    <!--                      <ng-container *ngIf="element.service_cancelled === true" >-->
                    <!--                        <button type="button" class="btn btn-light-primary btn-icon btn-sm  " (click)="returnCancelLabTest(element)"><span-->
                    <!--                          class="pt-1"><i class="fas fa-undo"></i> </span> </button><br>-->
                    <!--                      </ng-container>-->
                    <!--                      <ng-container *ngIf="element.service_cancelled === false" >-->
                    <!--                        <button type="button" class="btn btn-light-primary btn-sm mt-1 " (click)="cancelLabTest(element)">Cancel</button><br>-->
                    <!--                        <button type="button" class="btn btn-light-primary btn-sm mt-1" (click)="rejectLabTest(element)">Rejected</button><br>-->
                    <!--                        <button type="button" class="btn btn-light-primary btn-sm mt-1" (click)="openQrcode(element.id)">Barcode</button>-->
                    <!--                      </ng-container>-->
                    <!--                    </td>-->
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
            <!--pagination-->
            <div class="pagination_main">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPage }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                  >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                  >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                  >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                  >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                  >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</div>

<!--UPdated Reported Date & Time Start-->
<div class="example-preview">
  <div>
    <ng-template #updateReportedDateModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Update</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>Reported Date <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm datetimepicker-input"
                  id="reported_date_time"
                  data-toggle="datetimepicker"
                  data-target="#reported_date_time"
                  [(ngModel)]="reportedDate"
                  (keydown)="preventChangeDate()"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="
            updateReportedDate()
            "
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<!--UPdated Reported Date & Time End-->
<div class="example-preview">
  <div>
    <ng-template #collectLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Order Collection!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>Specimen</label>
                <select
                  class="form-control form-control-sm"
                  [(ngModel)]="collectedLabObj.specimen_id"
                >
                  <option value="">Select</option>
                  <ng-container *ngFor="let type of labConfigurations">
                    <option
                      *ngIf="type.configurator_type === 'specimen'"
                      [value]="type.id"
                    >
                      {{ type.description }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="col-sm-8">
              <div class="form-group">
                <label>Sample Taken From</label>
                <select
                  class="form-control form-control-sm"
                  [(ngModel)]="collectedLabObj.sample_taken_from_id"
                >
                  <option value="">Select</option>
                  <ng-container *ngFor="let type of labConfigurations">
                    <option
                      *ngIf="type.configurator_type === 'sample_taken_from'"
                      [value]="type.id"
                    >
                      {{ type.description }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="col-sm-8">
              <div class="form-group">
                <label>Collection Date & Time</label>
                <input
                  type="text"
                  class="form-control form-control-sm datetimepicker-input"
                  id="collection_date_time"
                  data-toggle="datetimepicker"
                  data-target="#collection_date_time"
                  (keydown)="preventChangeDate()"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <app-lab-costing-modal [addReportsCostingDetail]="labCosting"></app-lab-costing-modal>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="
              collectTestId(collectedLabObj.id, collectedLabObj.index, false)
            "
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #collectSampleTaken let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Order Collection!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <ng-container *ngIf="isSpiceman">
              <div class="col-sm-8">
                <div class="form-group">
                  <label>Specimen</label>
                  <select
                    class="form-control form-control-sm"
                    [(ngModel)]="collectedLabObj.specimen_id"
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let type of labConfigurations">
                      <option
                        *ngIf="type.configurator_type === 'specimen'"
                        [value]="type.id"
                      >
                        {{ type.description }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!isSpiceman">
              <div class="col-sm-8">
                <div class="form-group">
                  <label>Sample Taken From</label>
                  <select
                    class="form-control form-control-sm"
                    [(ngModel)]="collectedLabObj.sample_taken_from_id"
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let type of labConfigurations">
                      <option
                        *ngIf="type.configurator_type === 'sample_taken_from'"
                        [value]="type.id"
                      >
                        {{ type.description }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="
              collectTestIdOnly(
                collectedLabObj.id,
                collectedLabObj.index,
                collectedLabObj.status
              )
            "
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #rejectLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Reject Test</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-8">
              <label>Reasons</label>
              <select class="form-control" [(ngModel)]="rejectedSampleReason">
                <option
                  *ngFor="let name of rejectedSampleReasonList"
                  value="{{ name }}"
                >
                  {{ name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-default"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="rejectLabTestFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Confirm Confirm Modal-->
<div class="example-preview">
  <div>
    <ng-template #cancelLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked"/>
            Are you sure you want to cancel this services!<br/>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="cancelLabTestFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #returnCancelLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <input type="checkbox" class="mr-2" [(ngModel)]="isChecked"/>
            Are you sure you want to return this services to lab!<br/>
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="returnCancelLabTestFun()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Via CNIC Modal-->
<div class="example-preview">
  <div>
    <ng-template #deliveredLabTestModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Order Deliver</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="reportDelivered" (ngSubmit)="deliveredResultFun()">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Receiver</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="receiver_relation"
                    (change)="updateReceiverType($event.target.value, '')"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_relation.errors
                    }"
                  >
                    <option value="Self">Self</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>Receiver Name</label>
                  <input
                    class="form-control form-control-sm"
                    formControlName="receiver_name"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_name.errors
                    }"
                  />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>Receiver CNIC</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    maxlength="13"
                    (keypress)="_onlyNumeric($event)"
                    formControlName="receiver_cnic"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && g.receiver_cnic.errors
                    }"
                  />
                </div>
              </div>
              <div class="col-sm-8" style="margin-top: 5px">
                <div class="form-group">
                  <label class="checkbox checkbox-sm float-left mr-3">
                    <input
                      type="checkbox"
                      name="showHeader"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="showHeader"
                      (change)="clearHeaderImage()"
                    />
                    <span></span>
                  </label>
                  <span>You want to show Header on report?</span>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="showHeader">
                <div class="form-group">
                  <select
                    class="form-control form-control-sm"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="headerImage"
                    (change)="updateFooterReportControl($event.target.value)"
                  >
                    <option value="">Select</option>
                    <option value="1">Show Default Image</option>
                    <option value="2">Show Second Image</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-8" style="margin-top: 5px">
                <div class="form-group">
                  <label class="checkbox checkbox-sm float-left mr-3">
                    <input
                      type="checkbox"
                      name="showHeader"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="showFooter"
                      (change)="clearFooterImage()"
                    />
                    <span></span>
                  </label>
                  <span>You want to show Footer on report?</span>
                </div>
              </div>
              <div class="col-sm-4" *ngIf="showFooter">
                <div class="form-group">
                  <select
                    class="form-control form-control-sm"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="footerImage"
                  >
                    <option value="">Select</option>
                    <option value="1">Show Default Image</option>
                    <option value="2">Show Second Image</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer p-3">
            <button
              type="button"
              class="btn btn-sm btn-default"
              (click)="c('Close click')"
            >
              Close
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
              Confirm
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>
<!--Via Email Modal-->
<div class="example-preview">
  <div>
    <ng-template #deliveredLabTestViaEmailModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Email Result</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="reportDeliveredViaEmail" (ngSubmit)="deliveredResultViaEmailFun()">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Patient</label>
                  <select
                    class="form-control form-control-sm disableRelation"
                    formControlName="receiver_relation"
                    (change)="updateReceiverType($event.target.value, '')"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && f.receiver_relation.errors
                    }"
                  >
                    <option value="Self">Self</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>Patient Name <span class="text-danger">*</span></label>
                  <input
                    class="form-control form-control-sm"
                    formControlName="receiver_name"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && f.receiver_name.errors
                    }"
                  />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>Patient Email <span class="text-danger">*</span></label>
                  <input
                    class="form-control form-control-sm"
                    type="email"
                    formControlName="receiver_cnic"
                    [ngClass]="{
                      'is-invalid':
                        reportDeliveredSubmited && f.receiver_cnic.errors
                    }"
                  />
                </div>
              </div>
<!--              <div class="col-sm-8" style="margin-top: 5px">-->
<!--                <div class="form-group">-->
<!--                  <label class="checkbox checkbox-sm float-left mr-3">-->
<!--                    <input-->
<!--                      type="checkbox"-->
<!--                      name="showHeader"-->
<!--                      [ngModelOptions]="{ standalone: true }"-->
<!--                      [(ngModel)]="showHeader"-->
<!--                      (change)="clearHeaderImage()"-->
<!--                    />-->
<!--                    <span></span>-->
<!--                  </label>-->
<!--                  <span>You want to show Header on report?</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-sm-4" *ngIf="showHeader">-->
<!--                <div class="form-group">-->
<!--                  <select-->
<!--                    class="form-control form-control-sm"-->
<!--                    [ngModelOptions]="{ standalone: true }"-->
<!--                    [(ngModel)]="headerImage"-->
<!--                    (change)="updateFooterReportControl($event.target.value)"-->
<!--                  >-->
<!--                    <option value="">Select</option>-->
<!--                    <option value="1">Show Default Image</option>-->
<!--                    <option value="2">Show Second Image</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-sm-8" style="margin-top: 5px">-->
<!--                <div class="form-group">-->
<!--                  <label class="checkbox checkbox-sm float-left mr-3">-->
<!--                    <input-->
<!--                      type="checkbox"-->
<!--                      name="showHeader"-->
<!--                      [ngModelOptions]="{ standalone: true }"-->
<!--                      [(ngModel)]="showFooter"-->
<!--                      (change)="clearFooterImage()"-->
<!--                    />-->
<!--                    <span></span>-->
<!--                  </label>-->
<!--                  <span>You want to show Footer on report?</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-sm-4" *ngIf="showFooter">-->
<!--                <div class="form-group">-->
<!--                  <select-->
<!--                    class="form-control form-control-sm"-->
<!--                    [ngModelOptions]="{ standalone: true }"-->
<!--                    [(ngModel)]="footerImage"-->
<!--                  >-->
<!--                    <option value="">Select</option>-->
<!--                    <option value="1">Show Default Image</option>-->
<!--                    <option value="2">Show Second Image</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
          <div class="modal-footer p-3">
            <button
              type="button"
              class="btn btn-sm btn-default"
              (click)="c('Close click')"
            >
              Close
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
              Confirm
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>
<!--ADD CSV Modal Start-->
<div
  class="modal fade"
  id="csvModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Upload CSV</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="form-group">
              <label>Detector</label>
              <input
                type="file"
                accept=".csv"
                id="detector"
                class="form-control form-control-sm"
                (change)="detectorCsvChange($event)"
              />
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="form-group">
              <label>Organism</label>
              <input
                type="file"
                accept=".csv"
                id="organism"
                class="form-control form-control-sm"
                (change)="organismCsvChange($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="uploadCsv()"
        >
          Upload
        </button>
      </div>
    </div>
  </div>
</div>
<!--ADD CSV MOdal End-->
<div
  class="modal fade"
  id="printConfirmation"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-8" style="margin-top: 5px">
            <label class="checkbox checkbox-sm float-left mr-3">
              <input
                type="checkbox"
                [(ngModel)]="showHeader"
                (change)="clearHeaderImage()"
              />
              <span></span>
            </label>
            <span>You want to show Header on report?</span>
          </div>
          <div class="col-4" *ngIf="showHeader">
            <div class="">
              <select
                class="form-control form-control-sm"
                [(ngModel)]="headerImage"
                (change)="updateFooterReportControl($event.target.value)"
              >
                <option value="">Select</option>
                <option value="1">Show Default Image</option>
                <option value="2">Show Second Image</option>
              </select>
            </div>
          </div>
          <div class="col-8 mt-5" style="margin-top: 5px">
            <label class="checkbox checkbox-sm float-left mr-3">
              <input
                type="checkbox"
                [(ngModel)]="showFooter"
                (change)="clearHeaderImage()"
              />
              <span></span>
            </label>
            <span>You want to show Footer on report?</span>
          </div>
          <div class="col-4 mt-5" *ngIf="showFooter">
            <div class="">
              <select
                class="form-control form-control-sm"
                [(ngModel)]="footerImage"
              >
                <option value="">Select</option>
                <option value="1">Show Default Image</option>
                <option value="2">Show Second Image</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="printReportByGroup()"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>

<div class="example-preview">
  <div>
    <ng-template #reportsModal let-c="close" let-d="dismiss">
      <div class="">
        <!--        <div class="modal-header p-3">-->
        <!--          <h4 class="modal-title">{{activeLabEntry?.test}}</h4>-->
        <!--          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">-->
        <!--            <span aria-hidden="true">&times;</span>-->
        <!--          </button>-->
        <!--        </div>-->
        <div class="modal-body modal-body-report">
          <div class="d-flex flex-row">
            <!--begin::Aside-->
            <div class="flex-row-auto w-150px w-xxl-250px">
              <div class="card card-custom mb-5">
                <div class="card-body user-card-body pt-4">
                  <div class="py-2">
                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span class="font-weight-bold mr-2">{{
                          currentPatientData?.patient_name
                        }}</span>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span class="font-weight-bold mr-2">{{
                          currentPatientData?.patient_mrn
                        }}</span>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span class="font-weight-bold mr-2">{{
                          currentPatientData?.age +
                          " " +
                          currentPatientData?.patient_age_type
                        }}</span>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span class="font-weight-bold mr-2">{{
                          currentPatientData?.patient_gender | uppercase
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom mb-5">
                <div class="card-body user-card-body pt-4">
                  <div class="py-2">
                    <table class="table">
                      <tr
                        *ngFor="
                          let element of patientLabTestListing;
                          let i = index
                        "
                      >
                        <td (click)="getReportDetail(element, i)">
                          <span
                            class="mb-1 font-size-lg cursor-pointer"
                            [ngClass]="{
                              'font-weight-bold text-primary':
                                element.id === activeLabEntry.id
                            }"
                          >{{ element.test }}</span
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row-fluid ml-lg-8">
              <div class="card card-custom mb-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-10">
                      <h4 class="m-0">
                        {{ activeLabEntry?.lab_investigation?.profile_name }}
                      </h4>
                    </div>
                    <div class="col-2">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="d('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="modalType === 'reportsModal'">
                <div>
                  <div
                    class="card card-custom mb-5"
                    *ngFor="let reports of reportType; let i = index"
                  >
                    <div class="card-body">
                      <div class="clearfix">
                        <div
                          class="col-xs-12 p-lr-0"
                          *ngIf="
                            reports.name !== 'null' &&
                            reports.name !== 'undefined'
                          "
                        >
                          <h4 class="margin-0">{{ reports.name }}</h4>
                        </div>
                      </div>
                      <div class="clearfix">
                        <table class="table table-striped margin-bottom-0">
                          <thead>
                          <tr>
                            <th style="width: 30%">Name</th>
                            <th style="width: 10%">Uom</th>
                            <!--                        <th style="width: 10%">specimen</th>-->
                            <th style="width: 10%">Range</th>
                            <th style="width: 10%">Comments</th>
                            <th style="width: 20%">Value</th>
                            <th style="width: 20%"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <ng-container
                            *ngFor="
                                let report of reports.value;
                                let j = index
                              "
                          >
                            <tr>
                              <td>{{ report.title }}</td>
                              <td>{{ report.uom }}</td>
                              <!--                        <td>{{report.specimen}}</td>-->
                              <td>{{ report.range || "--" }}</td>
                              <td>
                                  <span
                                    [innerHTML]="report.range_comments"
                                  ></span>
                              </td>
                              <td *ngIf="report?.report_type === 'single'">
                                <ng-container
                                  *ngIf="report?.range_type === 'in_between'"
                                >
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    style="max-width: 100%"
                                    placeholder="number"
                                    class="form-control form-control-sm"
                                    [ngClass]="
                                        (convertToNumber(report.report_range) >=
                                          report.range_start &&
                                          report.report_range <=
                                            report.range_end) ||
                                        report.report_range === '' ||
                                        report.report_range === null ||
                                        report.report_range === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                    [(ngModel)]="report.report_range"
                                  />
                                </ng-container>
                                <ng-container
                                  *ngIf="report?.range_type === 'less_then'"
                                >
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    style="max-width: 100%"
                                    placeholder="number"
                                    class="form-control form-control-sm"
                                    [ngClass]="
                                        convertToNumber(report.report_range) <=
                                          report.range_end ||
                                        report.report_range === '' ||
                                        report.report_range === null ||
                                        report.report_range === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                    [(ngModel)]="report.report_range"
                                  />
                                </ng-container>
                                <ng-container
                                  *ngIf="
                                      report?.range_type === 'greater_then'
                                    "
                                >
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    style="max-width: 100%"
                                    placeholder="number"
                                    class="form-control form-control-sm"
                                    [ngClass]="
                                        convertToNumber(report.report_range) >=
                                          report.range_start ||
                                        report.report_range === '' ||
                                        report.report_range === null ||
                                        report.report_range === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                    [(ngModel)]="report.report_range"
                                  />
                                </ng-container>
                                <ng-container
                                  *ngIf="report?.range_type === 'equal'"
                                >
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    style="max-width: 100%"
                                    placeholder="number"
                                    class="form-control form-control-sm"
                                    [ngClass]="
                                        convertToNumber(report.report_range) ===
                                          report.range_start ||
                                        report.report_range === '' ||
                                        report.report_range === null ||
                                        report.report_range === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                    [(ngModel)]="report.report_range"
                                  />
                                </ng-container>
                                <ng-container
                                  *ngIf="
                                      report?.range_type !== 'equal' &&
                                      report?.range_type !== 'greater_then' &&
                                      report?.range_type !== 'less_then' &&
                                      report?.range_type !== 'in_between'
                                    "
                                >
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    style="max-width: 100%"
                                    placeholder="number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.report_range"
                                  />
                                </ng-container>
                              </td>
                              <td
                                *ngIf="report?.report_type === 'single'"
                              ></td>

                              <td
                                colspan="2"
                                *ngIf="report?.report_type === 'text'"
                              >
                                <input
                                  type="text"
                                  placeholder="text"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="report.heading"
                                />
                                <div
                                  class="d-flex mt-2"
                                  *ngIf="report?.show_cutoff_patient_value"
                                >
                                  <input
                                    type="text"
                                    class="form-control form-control-sm mr-3"
                                    [(ngModel)]="report.cutoff_value"
                                    placeholder="Cutoff Value"
                                  />
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.patient_value"
                                    placeholder="Patient Value"
                                  />
                                </div>
                              </td>
                              <td
                                colspan="2"
                                *ngIf="report?.report_type === 'editor'"
                              ></td>

                              <td
                                colspan="2"
                                *ngIf="report?.report_type === 'combo'"
                              >
                                  <span
                                    *ngIf="report?.report_type_1 === 'text'"
                                  >
                                    <input
                                      type="text"
                                      style="max-width: 100%"
                                      placeholder="text"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.report_type_1_value"
                                    />
                                    <div
                                      class="d-flex mt-2"
                                      *ngIf="report?.show_cutoff_patient_value"
                                    >
                                      <input
                                        type="text"
                                        class="form-control form-control-sm mr-3"
                                        [(ngModel)]="report.cutoff_value"
                                        placeholder="Cutoff Value"
                                      />
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="report.patient_value"
                                        placeholder="Patient Value"
                                      />
                                    </div>
                                  </span>
                                <span
                                  *ngIf="report?.report_type_1 === 'decimal'"
                                >
                                    <input
                                      type="text"
                                      (keydown)="isNumberKey($event)"
                                      style="max-width: 100%"
                                      placeholder="number"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.report_type_1_value"
                                    />
                                    <div
                                      class="d-flex mt-2"
                                      *ngIf="report?.show_cutoff_patient_value"
                                    >
                                      <input
                                        type="text"
                                        class="form-control form-control-sm mr-3"
                                        [(ngModel)]="report.cutoff_value"
                                        placeholder="Cutoff Value"
                                      />
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="report.patient_value"
                                        placeholder="Patient Value"
                                      />
                                    </div>
                                  </span>
                                <span
                                  *ngIf="report?.report_type_1 === 'dropdown'"
                                >
                                    <select
                                      class="form-control form-control-sm"
                                      style="max-width: 100%"
                                      [(ngModel)]="report.report_type_1_value"
                                    >
                                      <option value="">Select</option>
                                      <option
                                        *ngFor="
                                          let val of report?.report_type_1_result
                                        "
                                        [value]="val"
                                      >
                                        {{ val }}
                                      </option>
                                    </select>
                                    <div
                                      class="d-flex mt-2"
                                      *ngIf="report?.show_cutoff_patient_value"
                                    >
                                      <input
                                        type="text"
                                        class="form-control form-control-sm mr-3"
                                        [(ngModel)]="report.cutoff_value"
                                        placeholder="Cutoff Value"
                                      />
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="report.patient_value"
                                        placeholder="Patient Value"
                                      />
                                    </div>
                                  </span>
                              </td>

                              <!--                              <td *ngIf="report?.report_type === 'combo' && report?.report_type_2 !== ''; else notShowContent">-->
                              <!--                            <span *ngIf="report?.report_type_2 === 'text'">-->
                              <!--                              <input type="text" style="max-width: 100%" placeholder="text" class="form-control form-control-sm" [(ngModel)]="report.report_type_2_value">-->
                              <!--                            </span>-->
                              <!--                                <span *ngIf="report?.report_type_2 === 'decimal'">-->
                              <!--                              <input type="text" (keydown)="isNumberKey($event)" style="max-width: 100%" placeholder="number" class="form-control form-control-sm" [(ngModel)]="report.report_type_2_value">-->
                              <!--                            </span>-->
                              <!--                                <span *ngIf="report?.report_type_2 === 'dropdown'">-->
                              <!--                              <select class="form-control form-control-sm" style="max-width: 100%" [(ngModel)]="report.report_type_2_value">-->
                              <!--                                <option value="">Select</option>-->
                              <!--                                <option *ngFor="let val of report?.report_type_2_result" [value]="val">{{val}}</option>-->
                              <!--                              </select>-->
                              <!--                            </span>-->
                              <!--                              </td>-->

                              <!--                              <ng-template #notShowContent>-->
                              <!--                                <td></td>-->
                              <!--                              </ng-template>-->
                            </tr>
                            <tr *ngIf="report?.report_type === 'editor'">
                              <td
                                colspan="7"
                                style="padding-left: 0px; padding-right: 0px"
                              >
                                <div
                                  class="textEditor"
                                  id="textEditor{{ i }}{{ j }}"
                                ></div>
                              </td>
                            </tr>
                          </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card card-custom mb-5">
                    <div class="card-body">
                      <div class="clearfix">
                        <div class="col-xs-12 p-lr-0">
                          <h4 class="margin-0">Remarks</h4>
                        </div>
                      </div>
                      <div class="clearfix">
                        <table class="table table-striped margin-bottom-0">
                          <thead>
                          <tr>
                            <th style="width: 20%">Remarks</th>
                            <th style="width: 80%">Details</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>
                              <select
                                class="form-control form-control-sm"
                                (change)="
                                    updatePathologistRemarks(
                                      $event.target.value
                                    )
                                  "
                              >
                                <option value="">Select</option>
                                <ng-container
                                  *ngFor="
                                      let comments of activeLabEntry?.pathologist_remarks
                                    "
                                >
                                  <option value="{{ comments.remarks }}">
                                    {{ comments.remarks }}
                                  </option>
                                </ng-container>
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                [id]="'pathologistRemarks'"
                                [(ngModel)]="pathologistRemarks"
                              />
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="showCostingDetailModal">
                    <app-lab-costing-modal [addReportsCostingDetail]="addReportsCostingDetail"></app-lab-costing-modal>
                  </ng-container>
                  <div class="card card-custom mb-5">
                    <div class="card-body text-right">
                      <button
                        type="button"
                        class="btn btn-sm btn-default mr-2"
                        (click)="c('Close click')"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-light-primary mr-2"
                        [disabled]="resultSubmitBtn"
                        (click)="submitResult(true)"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        [disabled]="resultSubmitBtn"
                        (click)="submitResult(false)"
                        *ngIf="activeLabEntry.status !== 'Unverified'"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="modalType === 'reportsModalUpdate'">
                <div
                  class="card card-custom mb-5"
                  *ngFor="let reports of reportTypeUpdate; let i = index"
                >
                  <div class="card-body">
                    <div class="clearfix">
                      <div
                        class="col-xs-12 p-lr-0"
                        *ngIf="
                          reports.name !== 'null' &&
                          reports.name !== 'undefined'
                        "
                      >
                        <h4 class="margin-0">{{ reports.name }}</h4>
                      </div>
                    </div>
                    <div class="clearfix">
                      <table class="table table-striped margin-bottom-0">
                        <thead>
                        <tr>
                          <th style="width: 20%">Name</th>
                          <th style="width: 10%">Uom</th>
                          <th style="width: 10%">specimen</th>
                          <th style="width: 10%">Range</th>
                          <th style="width: 10%">Comments</th>
                          <th style="width: 20%">Value</th>
                          <th style="width: 20%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container
                          *ngFor="let report of reports.value; let j = index"
                        >
                          <tr>
                            <td>{{ report.caption }}</td>
                            <td>{{ report.unit }}</td>
                            <td>{{ report.specimen }}</td>
                            <td>{{ report.range || "--" }}</td>
                            <td>
                                <span
                                  [innerHTML]="report.range_comments"
                                ></span>
                            </td>
                            <!--              <td  style="width: 20%"><input type="number" class="form-control form-control-sm" [(ngModel)]="report.value"></td>-->

                            <td *ngIf="report?.report_type === 'single'">
                              <!--                                <input-->
                              <!--                                  type="text"-->
                              <!--                                  (keydown)="isNumberKey($event)"-->
                              <!--                                  style="max-width: 100%"-->
                              <!--                                  placeholder="number"-->
                              <!--                                  class="form-control form-control-sm"-->
                              <!--                                  [(ngModel)]="report.value"-->
                              <!--                                />-->
                              <!--                                <input-->
                              <!--                                  type="text"-->
                              <!--                                  (keydown)="isNumberKey($event)"-->
                              <!--                                  style="max-width: 100%"-->
                              <!--                                  placeholder="number"-->
                              <!--                                  class="form-control form-control-sm"-->
                              <!--                                  [(ngModel)]="report.value"-->
                              <!--                                />-->
                              <!--                                <input-->
                              <!--                                  type="text"-->
                              <!--                                  (keydown)="isNumberKey($event)"-->
                              <!--                                  style="max-width: 100%"-->
                              <!--                                  placeholder="number"-->
                              <!--                                  class="form-control form-control-sm"-->
                              <!--                                  [(ngModel)]="report.value"-->
                              <!--                                />-->
                              <ng-container
                                *ngIf="report?.range_type === 'in_between'"
                              >
                                <input
                                  type="text"
                                  (keydown)="isNumberKey($event)"
                                  style="max-width: 100%"
                                  placeholder="number"
                                  class="form-control form-control-sm"
                                  [ngClass]="
                                        (convertToNumber(report.value) >=
                                          report.range_start &&
                                          report.value <=
                                            report.range_end) ||
                                        report.value === '' ||
                                        report.value === null ||
                                        report.value === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                  [(ngModel)]="report.value"
                                />
                              </ng-container>
                              <ng-container
                                *ngIf="report?.range_type === 'less_then'"
                              >
                                <input
                                  type="text"
                                  (keydown)="isNumberKey($event)"
                                  style="max-width: 100%"
                                  placeholder="number"
                                  class="form-control form-control-sm"
                                  [ngClass]="
                                        convertToNumber(report.value) <=
                                          report.range_end ||
                                        report.value === '' ||
                                        report.value === null ||
                                        report.value === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                  [(ngModel)]="report.value"
                                />
                              </ng-container>
                              <ng-container
                                *ngIf="
                                      report?.range_type === 'greater_then'
                                    "
                              >
                                <input
                                  type="text"
                                  (keydown)="isNumberKey($event)"
                                  style="max-width: 100%"
                                  placeholder="number"
                                  class="form-control form-control-sm"
                                  [ngClass]="
                                        convertToNumber(report.value) >=
                                          report.range_start ||
                                        report.value === '' ||
                                        report.value === null ||
                                        report.value === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                  [(ngModel)]="report.value"
                                />
                              </ng-container>
                              <ng-container
                                *ngIf="report?.range_type === 'equal'"
                              >
                                <input
                                  type="text"
                                  (keydown)="isNumberKey($event)"
                                  style="max-width: 100%"
                                  placeholder="number"
                                  class="form-control form-control-sm"
                                  [ngClass]="
                                        convertToNumber(report.value) ===
                                          report.range_start ||
                                        report.value === '' ||
                                        report.value === null ||
                                        report.value === undefined
                                          ? ''
                                          : 'is-invalid-range'
                                      "
                                  [(ngModel)]="report.value"
                                />
                              </ng-container>
                              <ng-container
                                *ngIf="
                                      report?.range_type !== 'equal' &&
                                      report?.range_type !== 'greater_then' &&
                                      report?.range_type !== 'less_then' &&
                                      report?.range_type !== 'in_between'
                                    "
                              >
                                <input
                                  type="text"
                                  (keydown)="isNumberKey($event)"
                                  style="max-width: 100%"
                                  placeholder="number"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="report.value"
                                />
                              </ng-container>
                            </td>
                            <td *ngIf="report?.report_type === 'single'"></td>

                            <td
                              colspan="2"
                              *ngIf="report?.report_type === 'text'"
                            >
                              <input
                                type="text"
                                placeholder="text"
                                class="form-control form-control-sm"
                                [(ngModel)]="report.heading"
                              />
                              <div
                                class="d-flex mt-2"
                                *ngIf="report?.show_cutoff_patient_value"
                              >
                                <input
                                  type="text"
                                  class="form-control form-control-sm mr-3"
                                  [(ngModel)]="report.cutoff_value"
                                  placeholder="Cutoff Value"
                                />
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  [(ngModel)]="report.patient_value"
                                  placeholder="Patient Value"
                                />
                              </div>
                            </td>
                            <td
                              colspan="2"
                              *ngIf="report?.report_type === 'editor'"
                            ></td>

                            <td
                              colspan="2"
                              *ngIf="report?.report_type === 'combo'"
                            >
                                <span *ngIf="report?.report_type_1 === 'text'">
                                  <input
                                    type="text"
                                    style="max-width: 100%"
                                    placeholder="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.report_type_1_value"
                                  />
                                  <div
                                    class="d-flex mt-2"
                                    *ngIf="report?.show_cutoff_patient_value"
                                  >
                                    <input
                                      type="text"
                                      class="form-control form-control-sm mr-3"
                                      [(ngModel)]="report.cutoff_value"
                                      placeholder="Cutoff Value"
                                    />
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.patient_value"
                                      placeholder="Patient Value"
                                    />
                                  </div>
                                </span>
                              <span
                                *ngIf="report?.report_type_1 === 'decimal'"
                              >
                                  <input
                                    type="text"
                                    (keydown)="isNumberKey($event)"
                                    style="max-width: 100%"
                                    placeholder="number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="report.report_type_1_value"
                                  />
                                  <div
                                    class="d-flex mt-2"
                                    *ngIf="report?.show_cutoff_patient_value"
                                  >
                                    <input
                                      type="text"
                                      class="form-control form-control-sm mr-3"
                                      [(ngModel)]="report.cutoff_value"
                                      placeholder="Cutoff Value"
                                    />
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.patient_value"
                                      placeholder="Patient Value"
                                    />
                                  </div>
                                </span>
                              <span
                                *ngIf="report?.report_type_1 === 'dropdown'"
                              >
                                  <select
                                    class="form-control form-control-sm"
                                    style="max-width: 100%"
                                    [(ngModel)]="report.report_type_1_value"
                                  >
                                    <option value="">Select</option>
                                    <option
                                      *ngFor="
                                        let val of report?.report_type_1_result
                                      "
                                      [value]="val"
                                    >
                                      {{ val }}
                                    </option>
                                  </select>
                                  <div
                                    class="d-flex mt-2"
                                    *ngIf="report?.show_cutoff_patient_value"
                                  >
                                    <input
                                      type="text"
                                      class="form-control form-control-sm mr-3"
                                      [(ngModel)]="report.cutoff_value"
                                      placeholder="Cutoff Value"
                                    />
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      [(ngModel)]="report.patient_value"
                                      placeholder="Patient Value"
                                    />
                                  </div>
                                </span>
                            </td>
                            <!--                            <td *ngIf="report?.report_type === 'combo' && report?.report_type_2 !== ''; else notShowContent1">-->
                            <!--                              <span *ngIf="report?.report_type_2 === 'text'">-->
                            <!--                                <input type="text" style="max-width: 100%" placeholder="text" class="form-control form-control-sm" [(ngModel)]="report.report_type_2_value">-->
                            <!--                              </span>-->
                            <!--                              <span *ngIf="report?.report_type_2 === 'decimal'">-->
                            <!--                                <input type="text" (keydown)="isNumberKey($event)" style="max-width: 100%" placeholder="number" class="form-control form-control-sm" [(ngModel)]="report.report_type_2_value">-->
                            <!--                              </span>-->
                            <!--                              <span *ngIf="report?.report_type_2 === 'dropdown'">-->
                            <!--                                <select class="form-control form-control-sm" style="max-width: 100%" [(ngModel)]="report.report_type_2_value">-->
                            <!--                                  <option value="">Select</option>-->
                            <!--                                  <option *ngFor="let val of report?.report_type_2_result" [value]="val">{{val}}</option>-->
                            <!--                                </select>-->
                            <!--                              </span>-->
                            <!--                            </td>-->
                            <!--                            <ng-template #notShowContent1>-->
                            <!--                              <td></td>-->
                            <!--                            </ng-template>-->
                          </tr>
                          <tr *ngIf="report?.report_type === 'editor'">
                            <td
                              colspan="7"
                              style="padding-left: 0px; padding-right: 0px"
                            >
                              <div
                                class="textEditor"
                                id="textEditorUpdate{{ i }}{{ j }}"
                              ></div>
                            </td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card card-custom mb-5">
                  <div class="card-body">
                    <div class="clearfix">
                      <div class="col-xs-12 p-lr-0">
                        <h4 class="margin-0">Remarks</h4>
                      </div>
                    </div>
                    <div class="clearfix">
                      <table class="table table-striped margin-bottom-0">
                        <thead>
                        <tr>
                          <th style="width: 20%">Remarks</th>
                          <th style="width: 80%">Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>
                            <select
                              class="form-control form-control-sm"
                              (change)="
                                  updatePathologistRemarks($event.target.value)
                                "
                            >
                              <option value="">Select</option>
                              <ng-container
                                *ngFor="
                                    let comments of activeLabEntry?.pathologist_remarks
                                  "
                              >
                                <option value="{{ comments.remarks }}">
                                  {{ comments.remarks }}
                                </option>
                              </ng-container>
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              [id]="'pathologistRemarksUpdate'"
                              [(ngModel)]="pathologistRemarks"
                            />
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="showCostingDetailModal">
                  <app-lab-costing-modal [addReportsCostingDetail]="addReportsCostingDetail"></app-lab-costing-modal>
                </ng-container>
                <div class="card card-custom mb-5">
                  <div class="card-body text-right">
                    <button
                      type="button"
                      class="btn btn-sm btn-default mr-2"
                      (click)="c('Close click')"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-light-primary mr-2"
                      [disabled]="resultSubmitBtn"
                      (click)="submitResultUpdate(true)"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      [disabled]="resultSubmitBtn"
                      (click)="submitResultUpdate(false)"
                      *ngIf="
                        activeLabEntry.status != 'Unverified' &&
                        activeLabEntry.status != 'Delivered' &&
                        activeLabEntry.status != 'Verified'
                      "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="modalType === 'cultureReportsModal'">
                <form [formGroup]="cultureReportsForm">
                  <div class="">
                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Specimen</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              (click)="addFieldsInCulture('specimen')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                              ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                              ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="
                            cultureReportsForm.value.culture_specimen.length > 0
                          "
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                            <tr>
                              <th>Specimen Name</th>
                              <th>Result</th>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                              formArrayName="culture_specimen"
                              *ngFor="
                                  let g of reports_specimen.controls;
                                  let i = index
                                "
                            >
                              <ng-container [formGroupName]="i">
                                <td style="width: 30%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="name_id"
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.name_id.errors
                                      }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="let type of labConfigurations"
                                    >
                                      <option
                                        *ngIf="
                                            type.configurator_type ===
                                            'specimen'
                                          "
                                        [value]="type.id"
                                      >
                                        {{ type.description }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td style="width: 30%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="value"
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.value.errors
                                      }"
                                  >
                                    <option value="">Select</option>
                                    <option value="Growth">Growth</option>
                                    <option value="No Growth">
                                      No Growth
                                    </option>
                                  </select>
                                </td>
                                <td style="width: 40%" class="text-right">
                                  <button
                                    (click)="
                                        removeCultureIndex('specimen', i)
                                      "
                                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                    type="button"
                                  >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                      ><!--end::Svg Icon--></span
                                      >
                                  </button>
                                </td>
                              </ng-container>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Culture</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              *ngIf="
                                cultureReportsForm.value.culture.length < 3
                              "
                              (click)="addFieldsInCultureWithResistance()"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                              ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                              ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="cultureReportsForm.value.culture.length > 0"
                        >
                          <div
                            class="widget margin-bottom-10"
                            *ngFor="
                              let g of reports_culture.controls;
                              let i = index
                            "
                          >
                            <h5 class="margin-0 font-weight-bold">
                              G {{ i + 1 }}
                            </h5>
                            <table class="table table-condensed table-hover">
                              <thead>
                              <tr>
                                <th>Growth</th>
                                <th>Culture</th>
                                <th>Atmosphere</th>
                                <th>Duration</th>
                                <th></th>
                                <th>Temperature</th>
                                <th>Colony Count</th>
                                <th></th>
                              </tr>
                              </thead>
                              <tbody>
                              <ng-container formArrayName="culture">
                                <ng-container [formGroupName]="i">
                                  <tr>
                                    <td style="width: 14%">
                                      <select
                                        class="form-control form-control-sm"
                                        formControlName="growth"
                                        [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.growth.errors
                                          }"
                                      >
                                        <option value="">Select</option>
                                        <!--                            <option value="Yes">Yes</option>-->
                                        <!--                            <option value="No">No</option>-->
                                        <ng-container
                                          *ngFor="let type of cultureList"
                                        >
                                          <option
                                            *ngIf="
                                                type.culture_type ===
                                                'cultures_type'
                                              "
                                            [value]="type.id"
                                          >
                                            {{ type.name }}
                                          </option>
                                        </ng-container>
                                      </select>
                                    </td>
                                    <td style="width: 14%">
                                      <select
                                        class="form-control form-control-sm"
                                        formControlName="culture"
                                        [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.culture.errors
                                          }"
                                      >
                                        <option value="">Select</option>
                                        <ng-container
                                          *ngFor="let type of cultureList"
                                        >
                                          <option
                                            *ngIf="
                                                type.culture_type ===
                                                'culture_name'
                                              "
                                            [value]="type.id"
                                          >
                                            {{ type.name }}
                                          </option>
                                        </ng-container>
                                      </select>
                                    </td>
                                    <td style="width: 14%">
                                      <select
                                        class="form-control form-control-sm"
                                        formControlName="atmosphere"
                                        [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.atmosphere.errors
                                          }"
                                      >
                                        <option value="">Select</option>
                                        <ng-container
                                          *ngFor="let type of cultureList"
                                        >
                                          <option
                                            *ngIf="
                                                type.culture_type ===
                                                'culture_atmosphere'
                                              "
                                            [value]="type.id"
                                          >
                                            {{ type.name }}
                                          </option>
                                        </ng-container>
                                      </select>
                                    </td>
                                    <td style="width: 14%">
                                      <select
                                        class="form-control form-control-sm float-left"
                                        formControlName="duration_type"
                                        [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.duration_type.errors
                                          }"
                                      >
                                        <option value="Minutes">
                                          Minutes
                                        </option>
                                        <option value="Hours">Hours</option>
                                        <option value="Days">Days</option>
                                        <option value="Weeks">Weeks</option>
                                      </select>
                                    </td>
                                    <td style="width: 14%">
                                      <input
                                        type="text"
                                        (keydown)="isNumberKey($event)"
                                        class="form-control form-control-sm float-left"
                                        placeholder="Value"
                                        formControlName="duration_value"
                                        [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.duration_value.errors
                                          }"
                                      />
                                    </td>
                                    <td style="width: 14%">
                                      <select
                                        class="form-control form-control-sm"
                                        formControlName="temperature"
                                        [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.temperature.errors
                                          }"
                                      >
                                        <option value="">Select</option>
                                        <ng-container
                                          *ngFor="let type of cultureList"
                                        >
                                          <option
                                            *ngIf="
                                                type.culture_type ===
                                                'culture_temperature'
                                              "
                                            [value]="type.id"
                                          >
                                            {{ type.name }}
                                          </option>
                                        </ng-container>
                                      </select>
                                    </td>
                                    <td style="width: 14%">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        placeholder="Colony Count"
                                        formControlName="colony_count"
                                        [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.colony_count.errors
                                          }"
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <!--                        <td  style="width: 15%">-->
                                    <!--                          <input type="text" class="form-control form-control-sm" placeholder="Colony Count" formControlName="colony_count" [ngClass]="{ 'is-invalid': cultureReportSubmitted && g.controls.colony_count.errors }">-->
                                    <!--                        </td>-->
                                    <td colspan="6" style="width: 90%">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        placeholder="Details"
                                        formControlName="comments"
                                        [ngClass]="{
                                            'is-invalid':
                                              cultureReportSubmitted &&
                                              g.controls.comments.errors
                                          }"
                                      />
                                    </td>
                                    <td style="width: 10%" class="text-right">
                                      <button
                                        (click)="
                                            removeCultureIndexWithResistance(i)
                                          "
                                        class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                        type="button"
                                      >
                                          <span
                                            class="svg-icon svg-icon-primary svg-icon-2x"
                                          ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                              <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                              >
                                                <rect
                                                  x="0"
                                                  y="0"
                                                  width="24"
                                                  height="24"
                                                />
                                                <path
                                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                  fill="#000000"
                                                  fill-rule="nonzero"
                                                />
                                                <path
                                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                />
                                              </g></svg
                                          ><!--end::Svg Icon--></span
                                          >
                                      </button>
                                    </td>
                                  </tr>
                                </ng-container>
                              </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Sensitivity</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              [disabled]="disableBtn"
                              (click)="addFieldsInCulture('sensitivity')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                              ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                              ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="
                            cultureReportsForm.value.sensitivity.length > 0
                          "
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                            <tr>
                              <th>Type</th>
                              <th>Medicine</th>
                              <ng-container
                                formArrayName="culture"
                                *ngFor="
                                    let g of reports_culture.controls;
                                    let i = index
                                  "
                              >
                                <ng-container [formGroupName]="i">
                                  <th>G{{ i + 1 }}</th>
                                </ng-container>
                              </ng-container>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                              formArrayName="sensitivity"
                              *ngFor="
                                  let sensitivity of reports_sensitivity.controls;
                                  let i = index
                                "
                            >
                              <ng-container [formGroupName]="i">
                                <td>
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="type"
                                    (change)="
                                        updateSensitivityType(
                                          $event.target.value,
                                          i
                                        )
                                      "
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          sensitivity.controls.type.errors
                                      }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="
                                          let type of cultureListSensitivity
                                        "
                                    >
                                      <!--                          <option *ngIf="type.culture_type === 'sensitivity_type'" [value]="type.id">{{type.name}}</option>-->
                                      <option [value]="type.id">
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td>
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="group"
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          sensitivity.controls.group.errors
                                      }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="
                                          let type of cultureGroupArray[i]
                                        "
                                    >
                                      <!--                          <option *ngIf="type.culture_type === 'sensitivity_group'" [value]="type.id">{{type.name}}</option>-->
                                      <option [value]="type.id">
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>

                                <td
                                  formArrayName="sensitivity_resistance"
                                  *ngFor="
                                      let resistance of sensitivity.get(
                                        'sensitivity_resistance'
                                      ).controls;
                                      let j = index
                                    "
                                >
                                  <ng-container [formGroupName]="j">
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="type"
                                      [ngClass]="{
                                          'is-invalid':
                                            cultureReportSubmitted &&
                                            resistance.controls.type.errors
                                        }"
                                    >
                                      <option value="">Select</option>
                                      <ng-container
                                        *ngFor="let type of cultureList"
                                      >
                                        <option
                                          *ngIf="
                                              type.culture_type ===
                                              'sensitivity_resistance_ir'
                                            "
                                          [value]="type.id"
                                        >
                                          {{ type.name }}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </ng-container>
                                </td>

                                <!--                  <td>-->
                                <!--                    <select class="form-control form-control-sm" formControlName="sensitivity_resistance" [ngClass]="{ 'is-invalid': cultureReportSubmitted && sensitivity.controls.sensitivity_resistance.errors }">-->
                                <!--                      <option value="">Select</option>-->
                                <!--                      <ng-container *ngFor="let type of cultureList">-->
                                <!--                        <option *ngIf="type.culture_type === 'sensitivity_resistance_ir'" [value]="type.id">{{type.name}}</option>-->
                                <!--                      </ng-container>-->
                                <!--                    </select>-->
                                <!--                  </td>-->
                                <!--                  -->
                                <td class="text-right">
                                  <button
                                    (click)="
                                        removeCultureIndex('sensitivity', i)
                                      "
                                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                    type="button"
                                  >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                      ><!--end::Svg Icon--></span
                                      >
                                  </button>
                                </td>
                              </ng-container>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div
                          class="clearfix"
                          *ngIf="
                            cultureReportsForm.value.reports_comment.length > 0
                          "
                        >
                          <div
                            class="widget"
                            formArrayName="reports_comment"
                            *ngFor="
                              let comments of reports_comment.controls;
                              let i = index
                            "
                            style="margin-bottom: 10px"
                          >
                            <h4 class="margin-0">Reports Comment</h4>
                            <table
                              class="table table-condensed table-hover mb-0"
                            >
                              <thead>
                              <tr>
                                <th>Heading G{{ i + 1 }}</th>
                                <th>Details</th>
                                <!--                  <th></th>-->
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <ng-container [formGroupName]="i">
                                  <td>
                                    <select
                                      class="form-control form-control-sm"
                                      formControlName="heading"
                                      (change)="
                                          updateFreeHeadingText(
                                            $event.target.value,
                                            'reports_comment',
                                            i
                                          )
                                        "
                                      [ngClass]="{
                                          'is-invalid':
                                            cultureReportSubmitted &&
                                            comments.controls.heading.errors
                                        }"
                                    >
                                      <option value="">Select</option>
                                      <ng-container
                                        *ngFor="let type of cultureList"
                                      >
                                        <option
                                          *ngIf="
                                              type.culture_type ===
                                              'report_comments_heading'
                                            "
                                          [value]="type.id"
                                        >
                                          {{ type.name }}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </td>
                                  <td colspan="4">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      formControlName="comments"
                                      [ngClass]="{
                                          'is-invalid':
                                            cultureReportSubmitted &&
                                            comments.controls.comments.errors
                                        }"
                                    />
                                  </td>
                                  <!--                    <td class="text-right">-->
                                  <!--                      <span style="font-size: 18px" class="pointer" (click)="removeCultureIndex('reports_comment', i)"><i class="fa fa-remove"></i></span>-->
                                  <!--                    </td>-->
                                </ng-container>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Gram Stain</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              (click)="addFieldsInCulture('gram_stain')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                              ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                              ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="cultureReportsForm.value.gram_stain.length > 0"
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                            <tr>
                              <th>Result</th>
                              <th>Details</th>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                              formArrayName="gram_stain"
                              *ngFor="
                                  let g of reports_gram_stain.controls;
                                  let i = index
                                "
                            >
                              <ng-container [formGroupName]="i">
                                <td style="width: 30%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="heading"
                                    (change)="
                                        updateFreeHeadingText(
                                          $event.target.value,
                                          'gram_stain',
                                          i
                                        )
                                      "
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.heading.errors
                                      }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="let type of cultureList"
                                    >
                                      <option
                                        *ngIf="
                                            type.culture_type ===
                                            'free_fields_heading'
                                          "
                                        [value]="type.id"
                                      >
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td style="width: 60%">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    formControlName="comments"
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.comments.errors
                                      }"
                                  />
                                </td>
                                <td style="width: 10%" class="text-right">
                                  <button
                                    (click)="
                                        removeCultureIndex('gram_stain', i)
                                      "
                                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                    type="button"
                                  >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                      ><!--end::Svg Icon--></span
                                      >
                                  </button>
                                </td>
                              </ng-container>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">ZN Stain</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              (click)="addFieldsInCulture('zn_stain')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                              ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                              ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="cultureReportsForm.value.zn_stain.length > 0"
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                            <tr>
                              <th>Result</th>
                              <th>Details</th>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                              formArrayName="zn_stain"
                              *ngFor="
                                  let g of reports_zn_stain.controls;
                                  let i = index
                                "
                            >
                              <ng-container [formGroupName]="i">
                                <td style="width: 30%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="heading"
                                    (change)="
                                        updateFreeHeadingText(
                                          $event.target.value,
                                          'zn_stain',
                                          i
                                        )
                                      "
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.heading.errors
                                      }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="let type of cultureList"
                                    >
                                      <option
                                        *ngIf="
                                            type.culture_type ===
                                            'free_fields_zn_stain'
                                          "
                                        [value]="type.id"
                                      >
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td style="width: 60%">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    formControlName="comments"
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.comments.errors
                                      }"
                                  />
                                </td>
                                <td style="width: 10%" class="text-right">
                                  <button
                                    (click)="
                                        removeCultureIndex('zn_stain', i)
                                      "
                                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                    type="button"
                                  >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                      ><!--end::Svg Icon--></span
                                      >
                                  </button>
                                </td>
                              </ng-container>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-8 p-lr-0">
                            <h4 class="margin-0">Fungal Stain</h4>
                          </div>
                          <div class="col-4 p-lr-0 text-right">
                            <button
                              (click)="addFieldsInCulture('fungal_stain')"
                              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-4"
                              type="button"
                            >
                              <span
                                class="svg-icon svg-icon-primary svg-icon-2x"
                              ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                                    <path
                                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                                      fill="#000000"
                                    />
                                  </g></svg
                              ><!--end::Svg Icon-->
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class="clearfix"
                          *ngIf="
                            cultureReportsForm.value.fungal_stain.length > 0
                          "
                        >
                          <table class="table table-condensed table-hover">
                            <thead>
                            <tr>
                              <th>Result</th>
                              <th>Details</th>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                              formArrayName="fungal_stain"
                              *ngFor="
                                  let g of reports_fungal_stain.controls;
                                  let i = index
                                "
                            >
                              <ng-container [formGroupName]="i">
                                <td style="width: 30%">
                                  <select
                                    class="form-control form-control-sm"
                                    formControlName="heading"
                                    (change)="
                                        updateFreeHeadingText(
                                          $event.target.value,
                                          'fungal_stain',
                                          i
                                        )
                                      "
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.heading.errors
                                      }"
                                  >
                                    <option value="">Select</option>
                                    <ng-container
                                      *ngFor="let type of cultureList"
                                    >
                                      <option
                                        *ngIf="
                                            type.culture_type ===
                                            'free_fields_fungal_stain'
                                          "
                                        [value]="type.id"
                                      >
                                        {{ type.name }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </td>
                                <td style="width: 60%">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    formControlName="comments"
                                    [ngClass]="{
                                        'is-invalid':
                                          cultureReportSubmitted &&
                                          g.controls.comments.errors
                                      }"
                                  />
                                </td>
                                <td style="width: 10%" class="text-right">
                                  <button
                                    (click)="
                                        removeCultureIndex('fungal_stain', i)
                                      "
                                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                                    type="button"
                                  >
                                      <span
                                        class="svg-icon svg-icon-primary svg-icon-2x"
                                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                          <g
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"
                                            />
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g></svg
                                      ><!--end::Svg Icon--></span
                                      >
                                  </button>
                                </td>
                              </ng-container>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card card-custom mb-5">
                      <div class="card-body">
                        <div class="clearfix">
                          <div class="col-xs-12 p-lr-0">
                            <h4 class="margin-0">Remarks</h4>
                          </div>
                        </div>
                        <div class="clearfix">
                          <table class="table table-striped margin-bottom-0">
                            <thead>
                            <tr>
                              <th style="width: 20%">Remarks</th>
                              <th style="width: 80%">Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  (change)="
                                      updatePathologistRemarks(
                                        $event.target.value
                                      )
                                    "
                                >
                                  <option value="">Select</option>
                                  <ng-container
                                    *ngFor="
                                        let comments of activeLabEntry?.pathologist_remarks
                                      "
                                  >
                                    <option value="{{ comments.remarks }}">
                                      {{ comments.remarks }}
                                    </option>
                                  </ng-container>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  [value]="pathologistRemarks"
                                  id="pathologistRemarks"
                                />
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="showCostingDetailModal">
                      <app-lab-costing-modal
                        [addReportsCostingDetail]="addReportsCostingDetail"></app-lab-costing-modal>
                    </ng-container>
                  </div>
                </form>
                <div class="card card-custom mb-5">
                  <div class="card-body text-right">
                    <button
                      type="button"
                      class="btn btn-sm btn-default mr-2"
                      (click)="c('Close click')"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-light-primary mr-2"
                      [disabled]="resultSubmitBtn"
                      (click)="submitCultureReport(true)"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      [disabled]="resultSubmitBtn"
                      (click)="submitCultureReport(false)"
                      *ngIf="
                        activeLabEntry.status != 'Unverified' &&
                        activeLabEntry.status != 'Delivered' &&
                        activeLabEntry.status != 'Verified'
                      "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="modalType === 'pcrModal'">
                <app-pcr-modal
                  [pcrListData]="pcrListData"
                  [cultureList]="cultureList"
                  [detectorList]="detectorList"
                  [organismList]="organismList"
                  [pcrData]="pcrData"
                  [labResultEntryAllow]="labResultEntryAllow"
                  (updatedData)="afterAddPCR($event)"
                  (closePcrModal)="closeModalPcr()"
                ></app-pcr-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!--Patient Discount Modal-->

<!-- Your modal HTML -->
<div
  class="modal fade"
  id="fullScreenModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  data-backdrop="false"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="labparameter">Adjustment V2</h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="hideDiscountModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isModal">
          <app-patient-discount-v2
            [labOrderId]="labOrderId"
            [patientId]="patientId"
            [isModal]="isModal"
            [cancelService]="'cancelService'"
            (isCloseModal)="hideModal($event)"
          ></app-patient-discount-v2>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="hideDiscountModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!--Patient Details Modal-->
<div
  class="modal fade"
  id="detailModal"
  tabindex="-1"
  style="z-index: 1051 !important"
  role="dialog"
  aria-labelledby="ModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalTitle">Patient Documents Details</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <div class="row">
            <label class="font-weight-bold ml-4">File Name:</label>
            <span class="ml-1">{{ imageDetail?.document_name }}</span>
          </div>
          <div class="row">
            <label class="font-weight-bold ml-4">File Type:</label>
            <span class="ml-1">{{ imageDetail?.document_type }}</span>
          </div>
          <div class="row">
            <label class="font-weight-bold ml-4 mr-1">Description:</label>
            <span [innerHTML]="imageDetail?.description"></span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!--patient Documments modal-->
<div class="example-preview">
  <div>
    <ng-template #patientImageModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Document Files</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click'); closePatImgModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            id="demo"
            class="carousel slide patDocModal"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <ng-container *ngFor="let img of patientDocs; let i = index">
                <div
                  class="carousel-item"
                  [ngClass]="{ active: i === 0 }"
                >
                  <img
                    [src]="img?.upload_file"
                    alt="Paper with Data"
                    style="width: 100%; height: auto"
                  />
                  <div class="row mt-3 ml-0">
                    <h5 class="mr-1">File Name:</h5>
                    <p style="margin-left: 2px">{{ img?.document_name }}</p>
                  </div>
                  <div class="row ml-0">
                    <a
                      class="btn btn-sm btn-primary"
                      (click)="openDocuments(img?.upload_file)"
                    >Download</a
                    >
                    <a
                      class="btn btn-sm btn-primary cursor-pointer ml-2"
                      (click)="openDetailModal(img?.id)"
                    >Details</a
                    >
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- Left and right controls -->
            <a
              class="carousel-control-prev marginleft"
              href="#demo"
              data-slide="prev"
            >
              <span class="carousel-control-prev-icon arrow-buton"></span>
            </a>
            <a
              class="carousel-control-next marginleft"
              href="#demo"
              data-slide="next"
            >
              <span class="carousel-control-next-icon arrow-buton"></span>
            </a>
          </div>
          <!--          <div class="mt-3" *ngFor="let img of patientDocs">-->

          <!--          </div>-->
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click'); closePatImgModal()"
          >
            Close
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

