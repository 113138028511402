<div
  class="text-right cursor-pointer"
  style="text-decoration: underline; color: #3699ff"
>
  <span *ngIf="showCostingDetail" (click)="showHIdeCostingDetail()"
    >Hide Costing Detail</span
  >
  <span *ngIf="!showCostingDetail" (click)="showHIdeCostingDetail()"
    >Show Costing Detail</span
  >
</div>
<div *ngIf="showCostingDetail" class="mb-4">
  <h5>Costing Detail</h5>
</div>
<div *ngIf="showCostingDetail" class="col-6 mb-6">
  <div class="">
    <label class="mr-2">Products</label>
    <!--begin::Search-->
    <div id="kt_quick_search_toggle" class="location_name">
      <div
        class="quick-search quick-search-dropdown p-0 quick-search-has-result"
        id="kt_quick_search_dropdown"
      >
        <!--begin:Form-->
        <form method="get" class="quick-search-form">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text px-2 py-0">
                <span class="svg-icon svg-icon-primary svg-icon-lg">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </span>
            </div>
            <input
              type="text"
              autofocus
              class="form-control form-control-sm"
              id="productSearch"
              name="searchProductValue"
              [(ngModel)]="selectedProduct"
              #search_value
              (keyup)="setTimeToCallProduct(search_value.value)"
              placeholder="Search..."
              autocomplete="off"
            />
            <div class="input-group-append">
              <span class="input-group-text px-2 py-0">
                <i
                  class="quick-search-close ki ki-close icon-sm text-muted"
                  (click)="removeSearchString()"
                ></i>
              </span>
            </div>
          </div>
        </form>
        <div
          class="location-search-bar pointOfSale"
          id="medicineList"
          [ngClass]="{
            active: productSearchList?.length
          }"
          *ngIf="selectedProduct && showData"
        >
          <table class="table table-head-custom tableBorder">
            <thead>
              <tr>
                <th style="width: 9%; text-align: center">Code</th>
                <th style="width: 20%; text-align: center">Product Name</th>
                <th style="width: 12%; text-align: center">Generic</th>
                <!--                                        <th style="width: 6%; text-align: center">UPC</th>-->
                <th style="width: 12%; text-align: center">Unit Qty</th>
                <th style="width: 12%; text-align: center">Pack Qty</th>
                <th style="width: 12%; text-align: center">Unit Price</th>
                <th style="width: 13%; text-align: center">Pack Price</th>
                <th style="width: 10%; text-align: center">Manufacture</th>
              </tr>
            </thead>
            <tbody class="pointOFSale2" id="innerScrollContainer">
              <!--                                      [ngClass]="{'hoverProduct': activeDropDownIndex === i}"-->
              <tr
                class="hoverPro d-flex"
                *ngFor="let products of productSearchList; let i = index"
                [ngClass]="{
                  hoverProduct: activeDropDownIndex === i
                }"
                id="productID{{ i }}"
                (click)="appendRow(products)"
              >
                <td style="width: 9%; text-align: center">
                  {{ products?.code }}
                </td>
                <td style="width: 20%; text-align: left">
                  {{ products.name }}
                </td>
                <td style="width: 12%; text-align: left">
                  {{ products.generic_name || "--" }}
                </td>
                <!--                                        <td style="width: 6%; text-align: center">{{products.upc || '&#45;&#45;'}}</td>-->
                <td style="width: 12%; text-align: center">
                  {{ products.available_quantity || 0 | number: "1.0-0" }}
                </td>
                <td style="width: 12%; text-align: center">
                  {{
                    products.available_quantity / products.pack_quantity || 0
                      | number: "1.0-0"
                  }}
                </td>
                <td style="width: 12%; text-align: center">
                  {{ products.sale_price || 0 }}
                </td>
                <td style="width: 13%; text-align: center">
                  {{ products.sale_price * products.pack_quantity || 0 }}
                </td>
                <td style="width: 10%; text-align: left">
                  {{ products.manufacturer || "--" }}
                </td>
              </tr>
            </tbody>
          </table>
          <!--                                  <p (click)="appendRow(products)" style="font-size: 17px"> {{products.generic_name}} - {{products.name}}</p>-->
        </div>
        <!--end::Scroll-->
      </div>
    </div>
    <!--end::Search-->
  </div>
</div>

<!--Table Start-->
<div *ngIf="showCostingDetail">
  <form [formGroup]="costDetail">
    <div class="table-responsive" *ngIf="costDetails.length">
      <table class="table table-head-custom table-vertical-center">
        <thead>
          <tr>
            <th style="width: 10%">Code</th>
            <th style="width: 18%">Name</th>
            <th style="width: 13%; text-align: center">Qty. Per Unit</th>
            <th style="width: 12%">Qty. Required</th>
            <th style="width: 13%">UOM</th>
            <th style="width: 11%">Cost</th>
            <th style="width: 15%" *ngIf="type === 'lab-service'">Consumption</th>
            <th style="width: 8%">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            formArrayName="cost_details"
            *ngFor="let product of costDetails.controls; let i = index"
          >
            <ng-container [formGroupName]="i">
              <td>{{ product?.get("code").value }}</td>
              <td>{{ product?.get("name").value }}</td>
              <td class="text-center">
                {{ product?.get("unit_quantity").value || 0 }}
              </td>
              <td>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="0"
                  formControlName="required_qty"
                  maxlength="8"
                  (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
                  (input)="calculateCost(i)"
                  (paste)="$event.preventDefault()"
                />
              </td>
              <td>{{ product?.get("uom").value }}</td>
              <td>{{ product?.get("cost").value }}</td>
              <td *ngIf="type === 'lab-service'">
                  <select class="form-control form-control-sm"
                          (change)="getConsumption($event.target.value, i)"
                          formControlName="consumption">
                    <option value="">Select</option>
                    <option value="on_collection">On Collection</option>
                    <option value="on_reporting">On Reporting</option>
                  </select>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                  (click)="removeProduct(i)"
                >
                  <span class="svg-icon svg-icon-primary svg-icon-2x">
                    <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        />
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </button>
              </td>
            </ng-container>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="font-weight-bold">Items Cost:</td>
            <td class="font-weight-bold">{{ itemsCost || 0 }}</td>
            <td></td>
          </tr>
          <tr>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none" class="font-weight-bold">
              Over Head Cost:
            </td>
            <td style="border: none">
              <input
                type="text"
                class="form-control form-control-sm"
                (input)="calculateTotal()"
                placeholder="0"
                formControlName="over_head_cost"
                maxlength="10"
                (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
                (paste)="$event.preventDefault()"
              />
            </td>
            <td style="border: none"></td>
          </tr>
          <tr>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none" class="font-weight-bold">Total Cost:</td>
            <td style="border: none" class="font-weight-bold">
              {{ totalCost }}
            </td>
            <td style="border: none"></td>
          </tr>
          <tr>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none" class="font-weight-bold">Margin(%):</td>
            <td style="border: none">
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="margin"
                placeholder="0"
                (input)="calTotalPrice()"
                (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
                (paste)="$event.preventDefault()"
              />
            </td>
            <td style="border: none"></td>
          </tr>
          <tr>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none" class="font-weight-bold">Total Price:</td>
            <td style="border: none" class="font-weight-bold">
              {{ totalPrice }}
            </td>
            <td style="border: none"></td>
          </tr>
          <tr *ngIf="!labCostingData">
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none"></td>
            <td style="border: none" class="font-weight-bold">
              Use this price as service price:
            </td>
            <td style="border: none" class="font-weight-bold">
              <label class="checkbox checkbox-sm justify-content-left">
                <input
                  class="printCheckbox"
                  type="checkbox"
                  formControlName="is_service_price"
                  (change)="addToServicePrice()"
                />
                <span></span>
              </label>
            </td>
            <td style="border: none"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
  <hr />
</div>
