import {Component, Input, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {HrService} from '../../../services/hr.service';
import {HospitaldoctorService} from '../../../services/hospitaldoctor.service';

declare var toastr: any;
@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.css']
})
export class ConsentFormComponent implements OnInit {
@Input() selectedPatient: any;
  employeesListing = [];
  doctors = [];
  constructor( private ngxLoader: NgxUiLoaderService,
               private hrService: HrService,
               private hospitalDoctorService: HospitaldoctorService
  ) { }
  getEmployeesList(): any {
    this.hrService.employeeListNew().subscribe((data) => {
      this.employeesListing = data;
    });
  }

  getDoctors(): any {
    this.doctors = [];
    this.ngxLoader.start();
    this.hospitalDoctorService.fetchRadiologyTemplatesDoctorslist().subscribe(
      (data) => {
        this.doctors = data.users;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  ngOnInit(): void {
    this.getEmployeesList();
    this.getDoctors();
  }

}
