<div class="d-flex flex-column flex-root">
  <!--begin::Page-->
  <div class="d-flex flex-row flex-column-fluid page">
    <div class="d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="d-flex flex-column-fluid main-content">
          <div class="container-fluid">
            <!--Page Header :: Start-->
            <div class="card card-custom headerCard">
              <div class="card-header ribbon ribbon-top">
                <div
                  class="ribbon-target bg-danger"
                  style="top: -2px; right: 20px"
                >
                  {{ hospitalName }}
                </div>
                <div class="card-toolbar">
                  <ul class="nav nav-light-success nav-bold nav-pills">
                    <li class="nav-item">
                      <h2>OT Scheduling</h2>
                      <!--                      <a class="nav-link active" data-toggle="tab" href="#statsTab">-->
                      <!--                        <span class="nav-icon"><i class="flaticon2-chat-1"></i></span>-->
                      <!--                        <span class="nav-text">Stats</span>-->
                      <!--                      </a>-->
                    </li>
                    <!--                    <li class="nav-item">-->
                    <!--                      <a class="nav-link" data-toggle="tab" href="#leavesTab">-->
                    <!--                        <span class="nav-icon"><i class="flaticon2-drop"></i></span>-->
                    <!--                        <span class="nav-text">Leaves</span>-->
                    <!--                      </a>-->
                    <!--                    </li>-->
                    <!--                    <li class="nav-item">-->
                    <!--                      <a class="nav-link" data-toggle="tab" href="#financialTab">-->
                    <!--                        <span class="nav-icon"><i class="flaticon2-drop"></i></span>-->
                    <!--                        <span class="nav-text">Financial</span>-->
                    <!--                      </a>-->
                    <!--                    </li>-->
                  </ul>
                </div>
              </div>
            </div>
            <!--Page Header :: End-->

            <!--Data :: Table Card :: Start-->
            <div class="card card-custom mt-3">
              <div class="card-body pdf-card">
                <!-- description table -->
                <table class="w-100 table table-striped mb-6">
                  <thead class="service-table">
                    <tr style="background-color: #f9f9f9">
                      <th style="width: 18%; font-size: 18px">Start Time / End Time</th>
<!--                      <th style="width: 15%; font-size: 18px">-->
<!--                        Operation Room-->
<!--                      </th>-->
                      <th style="width: 20%; font-size: 18px">Surgeon</th>
                      <!--                    <th style="width: 10%;font-size: 18px">MRN</th>-->

                      <th style="width: 20%; font-size: 18px">
                        Patient Name / MRN
                      </th>
                      <!--                    <th style="width: 12%;font-size: 18px">Department</th>-->
                      <th style="width: 21%; font-size: 18px">Procedure</th>
                      <th style="width: 21%; font-size: 18px">Ward</th>
                      <!--                    <th style="width: 10%;font-size: 18px">Urgency</th>-->
                      <!--                    <th style="width: 10%;font-size: 18px">Status</th>-->
                    </tr>
                  </thead>
                  <tbody class="service-table">
                  <ng-container *ngFor="let room of allOperationRequests; let i = index">
                  <tr [ngStyle]="{ 'background-color': colors[i] }">
                    <td colspan="5" style="font-size: 20px;font-weight: 500">{{room?.operation_room_name}}</td>
                  </tr>
                    <tr
                      [ngStyle]="{ 'background-color': colors[i] }"
                      *ngFor="let data of room?.records; let j = index"
                      style="font-size: 18px !important"
                    >
                      <td>
                        <!--                      <span *ngIf="data?.status_type === 'approved'" class="d-flex">{{ data?.approved_date }}</span>-->
                        <!--                      <span *ngIf="data?.status_type === 'requested'"-->
                        <!--                            class="d-flex">{{ data?.suggested_date | date: 'dd-MM-yyyy' }}</span>-->
<!--                        <span *ngIf="data?.status_type === 'approved'">{{-->
<!--                          data?.approved_time-->
<!--                        }}</span>-->
<!--                        <span *ngIf="data?.status_type === 'requested'">{{-->
<!--                          data?.suggested_time | date: "h:mm a"-->
<!--                        }}</span>-->
                        <span class="d-block">{{data?.approved_time}}</span>
                        <span>{{data?.approved_end_time}}</span>
                      </td>
<!--                      <td>-->
<!--                        <span>{{ data?.operation_room_name }}</span>-->
<!--                      </td>-->
                      <td>
                        <div *ngFor="let item of data?.surgeon_name">
                          <span class="d-flex">{{ item?.name }}</span>
                          <span style="font-style: italic; font-size: 15px">{{
                            item?.designation
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <span class="d-flex">{{
                          data?.patient?.full_name
                        }}</span>
                        <span class="d-flex">{{ data?.patient?.mrn }}</span>
                      </td>
                      <!--                    <td><span>{{ data?.department?.title }}</span></td>-->
                      <td>
                        <span>{{ data?.procedure_name }}</span>
                      </td>
                      <td>
                        <span>{{ data?.admission?.ward?.title }}</span>
                      </td>
                      <!--                    <td><span>{{ data?.urgency?.name }}</span></td>-->
                      <!--                    <td><span>{{ data?.status_type | titlecase }}</span></td>-->
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
            <!--Data :: Table Card :: End-->
          </div>
        </div>
      </div>

      <!--Page Footer :: Start-->
      <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
        <!--begin::Container-->
        <div
          class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
        >
          <!--begin::Copyright-->
          <div class="text-dark order-2 order-md-1">
            <!--            <span class="text-muted font-weight-bold mr-2">2020©</span>-->
            <a
              class="text-dark-75 text-hover-primary mr-5"
              [routerLink]="['/dashboard']"
              style="font-size: 18px"
              >Home</a
            >
            <a
              class="text-dark-75 text-hover-primary"
              (click)="logout()"
              style="font-size: 18px"
              >Logout</a
            >
          </div>
          <!--end::Copyright-->
          <!--begin::Nav-->
          <div class="nav nav-dark">
            <!--            <a href="http://keenthemes.com/metronic" target="_blank" class="nav-link pl-0 pr-5">About</a>-->
            <!--            <a href="http://keenthemes.com/metronic" target="_blank" class="nav-link pl-0 pr-5">Team</a>-->
            <!--            <a href="http://keenthemes.com/metronic" target="_blank" class="nav-link pl-0 pr-0">Contact</a>-->
          </div>
          <!--end::Nav-->
        </div>
        <!--end::Container-->
      </div>
      <!--Page Header :: End-->
    </div>
  </div>
  <!--End::Page-->
</div>
