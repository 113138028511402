import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RadiologyService } from '../../../services/radiology.service';
import { Router } from '@angular/router';
import { TechAcknowledgementService } from '../../../services/tech-acknowledgement.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareDataService } from '../../../services/share-data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ConfigurationService } from '../../../services/configuration.service';
import { HrService } from '../../../services/hr.service';
import { UserService } from '../../../services/user.service';
import { RouteShareService } from '../../../services/route-share.service';
import { SystemControlsService } from '../../../services/system-controls.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment/moment';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-acknowledgement-v2',
  templateUrl: './acknowledgement-v2.component.html',
  styleUrls: ['./acknowledgement-v2.component.css'],
})
export class AcknowledgementV2Component implements OnInit {
  @ViewChild('changeDrModal') changeDrModal: ElementRef;
  @ViewChild('deliveredLabTestModal') deliveredLabTestModal: ElementRef;
  @ViewChild('orderReceivedConfirmation') orderReceivedConfirmation: ElementRef;
  @ViewChild('cancelLabTestModal') cancelLabTestModal: ElementRef;
  @ViewChild('returnCancelLabTestModal') returnCancelLabTestModal: ElementRef;
  startDate = '';
  endDate = '';
  screenName = '';
  pageDataList: any;
  listData: any;
  searchMrn = '';
  searchAcc = '';
  patientNic: any = '';
  listIds = [];
  doctors = [];
  docId = '';
  history = '';
  page = 1;
  totalPages: any;
  perPage = 25;
  subDepartments = [];
  departmentId = '';
  statusType = 'acknowledge';
  urgency = '';
  changDrType: any;
  changDrId: any;
  changObjId: any;
  showHeader = true;
  showFooter = true;
  labId: any;
  current_environment: string;
  currentControls: any;
  reportDelivered: UntypedFormGroup;
  reportDeliveredSubmited = false;
  currentObj: any;
  radiologyPacsUrls: any;
  currentPacUrl: any;
  userData: any;
  userId = '';
  orderReceived = {
    received_by: '',
    inform_to: '',
    order_id: '',
  };
  elementIndex = '';
  S;
  employeesListing: any;
  resultForm: UntypedFormGroup;
  acknowledgementData = new Array();
  showAcknowledgementButton = true;
  screenControls = [];
  isChecked = false;
  rejectedSampleId = [];
  returnRejectedSampleId = [];
  invoiceId: number;
  patientId: any;
  isModal = false;
  radCosting: any;
  showCostingDetail = false;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private radiologyService: RadiologyService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private techAcknowledgementService: TechAcknowledgementService,
    private hospitaldoctorService: HospitaldoctorService,
    private modalService: NgbModal,
    private shareDataService: ShareDataService,
    private formBuilder: UntypedFormBuilder,
    private configService: ConfigurationService,
    private hrService: HrService,
    private userService: UserService,
    private routeSevice: RouteShareService,
    private systemControlService: SystemControlsService,
  ) {
    this.getDate();
    this.screenName = this.routeSevice.getValue();
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.userData) {
      this.userId = this.userData.employee_id;
    }
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
    });
    this.reportDelivered = this.formBuilder.group({
      receiver_relation: ['Self', Validators.required],
      receiver_cnic: [
        '',
        Validators.compose([
          Validators.minLength(13),
          Validators.maxLength(13),
        ]),
      ],
      receiver_name: ['', Validators.required],
      id: [''],
    });
    // ResultModalForm
    this.resultForm = this.formBuilder.group({
      scan_completed: [''],
      total_contrast_used: [''],
      type_of_contrast: [''],
      total_radiation_dose: [''],
      image_acquisition_issue: [''],
      issue_comments: [''],
      other_comments: [''],
    });
  }
  ngAfterViewInit(): any {
    $('#nic').mask('99999-9999999-9');
  }
  onlyNumeric(event: any): any {
    if (event.charCode != 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }
  getDate(): any {
    setTimeout(() => {
      const currentDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
      $('#start_date').datepicker({
        format: 'dd-mm-yyyy',
        autoClose: true,
      });
      $('#start_date').datepicker('setDate', currentDate);

      $('#end_date').datepicker({
        format: 'dd-mm-yyyy',
        autoClose: true,
        endDate: currentDate,
      });
      $('#end_date').datepicker('setDate', currentDate);
      this.searchFilter();
    }, 500);
  }
  searchFilterValue(): any {
    this.page = 1;
    this.searchFilter();
  }

  getRadiologySubDepartments(): any {
    this.subDepartments = [];
    this.radiologyService.radiologySubDeptByUserV2().subscribe(
      (data) => {
        this.subDepartments = data.departments;
        setTimeout(() => {
          $('#department').selectpicker('refresh');
        }, 200);
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  searchFilter(): any {
    this.listIds = [];
    this.pageDataList = [];
    this.history = '';
    this.docId = '';
    let tempPatientCnic = this.patientNic.replace(/[-]/g, '');
    const fromDate = moment(
      $('#start_date').datepicker('getDate'),
      'DD-MM-YYYY',
    );
    const toDate = moment($('#end_date').datepicker('getDate'), 'DD-MM-YYYY');
    if (toDate.isBefore(fromDate)) {
      toastr.error('To Date cannot be less than From Date');
      return;
    }
    this.startDate = $('#start_date').val();
    this.endDate = $('#end_date').val();
    const startDate = moment(this.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const endDate = moment(this.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.ngxLoader.start();
    this.techAcknowledgementService
      .searchRadiologyServicesByAckV2(
        tempPatientCnic,
        this.searchMrn,
        this.searchAcc,
        this.departmentId,
        this.statusType,
        this.urgency,
        startDate,
        endDate,
        this.page,
        this.perPage,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          this.pageDataList = data.patient_services;
          this.listData = data.patient_services;
          this.ref.markForCheck();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  fetchDoctors(): any {
    this.ngxLoader.start();
    this.hospitaldoctorService.fetchRefDoctorsDoctorV2().subscribe(
      (data) => {
        this.doctors = data.users;
        setTimeout(() => {
          $('#receivedBy').selectpicker();
        }, 1000);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updateFilters(type): any {
    this.pageDataList = [];
    if (type === '') {
      this.pageDataList = this.listData;
    }
    if (type === 'urgent') {
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].urgent === true) {
          this.pageDataList.push(this.listData[i]);
        }
      }
    }
    if (type === 'returned') {
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].is_rejected === true) {
          this.pageDataList.push(this.listData[i]);
        }
      }
    }
    if (type === 'opd') {
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].ipd === false) {
          this.pageDataList.push(this.listData[i]);
        }
      }
    }
    if (type === 'ipd') {
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].ipd === true) {
          this.pageDataList.push(this.listData[i]);
        }
      }
    }
  }

  updateRecordsList(data): any {
    this.radCosting = data;
    if (this.listIds.includes(data.id)) {
      const index = this.listIds.indexOf(data.id);
      this.listIds.splice(index, 1);
      this.acknowledgementData.splice(
        this.acknowledgementData.findIndex((t) => t.id === data.id),
        1,
      );
    } else {
      this.listIds.push(data.id);
      this.acknowledgementData.push(data);
    }
    console.log('acknowledgementData', this.acknowledgementData);
    if (this.listIds.length === 1) {
      this.history = data.user_info;
    } else {
      this.history = '';
    }
  }

  updateRecordsAllList(value): any {
    if (value === true) {
      $('.is_acknowledged').prop('checked', true);
      for (let i = 0; i < this.pageDataList.length; i++) {
        if (
          this.pageDataList[i].investigation_status === 'pending' &&
          this.pageDataList[i].service_cancelled === false
        ) {
          if (
            this.pageDataList[i].ipd === true &&
            this.pageDataList[i].received_by_name === ''
          ) {
          } else {
            this.listIds.push(this.pageDataList[i].id);
          }
        }
      }
    } else {
      $('.is_acknowledged').prop('checked', false);
      this.listIds = [];
    }
    console.log('listIds', this.listIds);
  }

  // changeDoctor(): any {
  //   if (this.docId === '' || this.listIds.length === 0) {
  //     toastr.error('Select doctor and investigations to change.');
  //     return;
  //   }
  //   const obj = {
  //     performing_doctor_id: this.docId,
  //     ids: this.listIds
  //   };
  //
  //   this.ngxLoader.start();
  //   this.techAcknowledgementService.changeDoctor(obj).subscribe(
  //     data => {
  //       toastr.success('Update doctor successful');
  //       this.listIds = [];
  //       this.searchFilter();
  //     },
  //     err => {
  //       this.ngxLoader.stop();
  //     }
  //   );
  // }

  acknowledgeRecords(): any {
    // if (this.listIds.length === 0) {
    //   toastr.error('Please select service');
    //   return;
    // }
    this.ngxLoader.start();
    this.techAcknowledgementService
      .acknowledgeV2(this.listIds, this.history, this.resultForm.value, this.radCosting?.test_cost_details)
      .subscribe(
        (data) => {
          this.searchFilter();
          toastr.success('added successfully');
          $('#ResultModal').modal('hide');
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }
  changeDoctor(type, obj, drId): any {
    if (
      obj.investigation_status === 'final' ||
      obj.investigation_status === 'delivered'
    ) {
      return;
    }
    this.changDrId = drId;
    this.changObjId = obj.id;
    this.changDrType = type;
    this.modalService.open(this.changeDrModal);
    setTimeout((e) => {
      $('#doctorsListChange').selectpicker('refresh');
      $('#doctorsListChange').selectpicker();
    }, 500);
  }
  changeDoctorFun(): any {
    if (this.changDrId === '') {
      toastr.error('Select Doctor');
      return;
    }
    this.ngxLoader.start();
    let obj;
    if (this.changDrType === 'referring') {
      obj = {
        performing_doctor_id: '',
        referring_doctor_id: this.changDrId,
        ids: [],
      };
      obj.ids.push(this.changObjId);
    } else {
      obj = {
        performing_doctor_id: this.changDrId,
        referring_doctor_id: '',
        ids: [],
      };
      obj.ids.push(this.changObjId);
    }
    this.radiologyService.changeDoctorV2(obj).subscribe(
      (data) => {
        const drname = this.doctors.find(
          (m) => m.id === Number(this.changDrId),
        );
        if (this.changDrType === 'referring') {
          this.pageDataList.find(
            (m) => m.id === Number(this.changObjId),
          ).referring_doctor_name = drname.full_name;
          this.pageDataList.find(
            (m) => m.id === Number(this.changObjId),
          ).referring_doctor_id = Number(this.changDrId);
        } else {
          this.pageDataList.find(
            (m) => m.id === Number(this.changObjId),
          ).performing_doctor_name = drname.full_name;
          this.pageDataList.find(
            (m) => m.id === Number(this.changObjId),
          ).performing_doctor_id = Number(this.changDrId);
        }
        // this.searchFilterValue();
        this.modalService.dismissAll(this.changeDrModal);
        toastr.success('Change Doctor');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  printConfirmModal(obj): any {
    this.labId = obj;
    $('#printConfirmation').modal('show');
    this.showHeader =
      this.currentControls.radiology_services.is_radiology_invoice_header;
    this.showFooter =
      this.currentControls.radiology_services.is_radiology_invoice_footer;
  }
  printReport(obj): any {
    if (this.currentControls !== '') {
      if (
        this.currentControls.radiology_services.is_radiology_invoice === false
      ) {
        window.open(
          '/radiology/pdf-report-v2?rad_id=' +
            obj.report.id +
            '&show_header=' +
            this.showHeader +
            '&show_footer=' +
            this.showFooter +
            '&visit_id=' +
            obj.patient.visit_id +
            '&mrn=' +
            obj.patient.mrn,
        );
      } else {
        window.open(
          this.current_environment +
            '/v4/reports/print_report.pdf?auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id') +
            '&id=' +
            obj.report.id +
            '&show_header=' +
            this.showHeader +
            '&show_footer=' +
            this.showFooter,
        );
      }
    } else {
      window.open(
        this.current_environment +
          '/v4/reports/print_report.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          obj.report.id +
          '&show_header=' +
          this.showHeader +
          '&show_footer=' +
          this.showFooter,
      );
    }
    $('#printConfirmation').modal('hide');
  }
  deliveredResult(element): any {
    this.currentObj = element;
    this.reportDeliveredSubmited = false;
    this.reportDelivered.reset();
    this.reportDelivered.controls.receiver_relation.setValue('Self');
    this.reportDelivered.controls.receiver_name.setValue(
      element.patient.first_name + ' ' + element.patient.last_name,
    );
    this.reportDelivered.controls.receiver_cnic.setValue(
      element.patient.patient_nic,
    );
    this.reportDelivered.controls.id.setValue(element.id);
    this.modalService.open(this.deliveredLabTestModal);
    this.showHeader =
      this.currentControls.radiology_services.is_radiology_invoice_header;
    this.showFooter =
      this.currentControls.radiology_services.is_radiology_invoice_footer;
  }
  get g() {
    return this.reportDelivered.controls;
  }
  updateReceiverType(type): any {
    if (type === 'Self') {
      this.reportDelivered.controls.receiver_name.setValue(
        this.currentObj.patient.first_name +
          ' ' +
          this.currentObj.patient.last_name,
      );
      this.reportDelivered.controls.receiver_cnic.setValue(
        this.currentObj.patient.patient_nic,
      );
    } else {
      this.reportDelivered.controls.receiver_name.setValue('');
      this.reportDelivered.controls.receiver_cnic.setValue('');
    }
  }
  deliveredResultFun(): any {
    this.reportDeliveredSubmited = true;
    if (this.reportDelivered.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.radiologyService
      .deliveredLabTestResultFunV2(this.reportDelivered.value)
      .subscribe(
        (data) => {
          this.printReport(this.currentObj);
          this.searchFilter();
          this.reportDeliveredSubmited = false;
          this.modalService.dismissAll(this.deliveredLabTestModal);
          this.ngxLoader.stop();
          toastr.success('Report Delivered');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  _onlyNumeric(event: any): any {
    if (event.charCode != 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }
  openDiscomImage(id): any {
    const newUrl = this.currentPacUrl.replace('{:id}', id);
    window.open(newUrl);
  }
  updatePACSURL(value: boolean): any {
    if (value === true) {
      this.currentPacUrl = this.radiologyPacsUrls.web_url;
    } else {
      this.currentPacUrl = this.radiologyPacsUrls.radiant_url;
    }
  }
  getRadiologyPACSUrls(): any {
    this.configService.getPACSUrlV2().subscribe(
      (data) => {
        if (data.radiology_configurator) {
          this.radiologyPacsUrls = data.radiology_configurator;
          this.updatePACSURL(true);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  receivedOrderConfirm(data, index): any {
    console.log(this.orderReceived.received_by);
    this.modalService.open(this.orderReceivedConfirmation);
    this.orderReceived = {
      received_by: this.userId,
      inform_to: '',
      order_id: data.id,
    };
    console.log(this.orderReceived);
    this.elementIndex = index;
    setTimeout(() => {
      $('#receivedBy').selectpicker();
      $('#informTo').selectpicker();
    }, 500);
    setTimeout(() => {
      $('#receivedBy').selectpicker('refresh');
      $('#informTo').selectpicker('refresh');
    }, 1000);
  }
  receivedOrderFun(): any {
    if (this.orderReceived.received_by === '') {
      toastr.error('Select Received By');
      return;
    }
    if (this.orderReceived.inform_to === '') {
      toastr.error('Select Inform To');
      return;
    }
    this.ngxLoader.start();
    this.radiologyService.receivedOrderFunctionV2(this.orderReceived).subscribe(
      (data) => {
        this.pageDataList[this.elementIndex].received_by_name = 'abc';
        this.modalService.dismissAll(this.orderReceivedConfirmation);
        this.ngxLoader.stop();
        toastr.success('Report Received');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEmployeesList(): any {
    this.hrService.employeeListNew().subscribe((data) => {
      this.employeesListing = data;
    });
  }
  openResultModal(): any {
    if (this.listIds.length === 0) {
      toastr.error('Please select service');
      return;
    }
    $('#ResultModal').modal('show');
    this.resultForm.reset({
      scan_completed: '',
      type_of_contrast: '',
      image_acquisition_issue: '',
    });
  }
  closeResultModal(): any {
    $('#ResultModal').modal('hide');
  }
  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        if (this.screenControls.length > 0) {
          if (
            this.screenControls.filter(
              (m) => m.code === 'showacknowledgementbutton',
            ).length > 0
          ) {
            this.showAcknowledgementButton = this.screenControls.filter(
              (m) => m.code === 'showacknowledgementbutton',
            )[0].is_show;
          }
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  returnCancelLabTest(element): any {
    this.returnRejectedSampleId = [];
    this.returnRejectedSampleId.push(element.id);

    this.isChecked = false;
    this.modalService.open(this.returnCancelLabTestModal);
  }
  returnCancelLabTestFun(): any {
    if (this.isChecked === false) {
      toastr.error('Select confirmation');
      return;
    }
    this.ngxLoader.start();
    this.radiologyService
      .returnCancelLabTestV2(this.returnRejectedSampleId)
      .subscribe(
        (data) => {
          this.searchFilter();
          this.modalService.dismissAll(this.returnCancelLabTestModal);
          toastr.success('Cancel service returned');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  cancelLabTest(element): any {
    this.isChecked = false;
    this.rejectedSampleId = [];
    this.rejectedSampleId.push(element.id);
    this.modalService.open(this.cancelLabTestModal);
  }
  openAddDiscountModal(element): any {
    this.isModal = true;
    this.invoiceId = element.id;
    this.patientId = element.patient.mrn;
    $('#fullScreenModal').modal('show');
  }
  hideDiscountModal(): any {
    this.isModal = false;
    $('#fullScreenModal').modal('hide');
  }
  hideModal(event): any {
    this.isModal = false;
    $('#fullScreenModal').modal('hide');
    this.searchFilter();
  }
  cancelLabTestFun(): any {
    if (this.isChecked === false) {
      toastr.error('Select confirmation');
      return;
    }
    this.ngxLoader.start();
    this.radiologyService.cancelLabTestV2(this.rejectedSampleId).subscribe(
      (data) => {
        this.searchFilter();
        this.modalService.dismissAll(this.cancelLabTestModal);
        toastr.success('Service Cancelled');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  showHIdeCostingDetail(): any {
    this.showCostingDetail = !this.showCostingDetail;
  }
  ngOnInit(): void {
    this.current_environment = environment.rorUrl;
    this.getScreenControls();
    this.getRadiologySubDepartments();
    this.fetchDoctors();
    this.getRadiologyPACSUrls();
    this.getEmployeesList();
    $('.selectpicker').selectpicker();
    setTimeout(() => {
      this.statusType = 'acknowledge';
      $('#statusType').val('acknowledge');
    }, 500);
  }
}
