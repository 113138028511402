import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient) {}
  index(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  lateInDetails(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_late_in_emp_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            // 'Accept': 'application/json'
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  chartsData(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard/chart_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  opdEmrLinesChart(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/dashboard/line_chart/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  PatientTypeWiseIncomeChart(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/dashboard/patient_type_wise_income/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  PatientGenderWiseChart(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/dashboard/gender_wise_patients/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  topDepartmentSalesChart(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/dashboard/top_department_sales/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  topLabServicesChart(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/dashboard/top_10_lab_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  topRadiologyServicesChart(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/dashboard/top_10_radiology_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVisitWiseChart(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/dashboard/patient_age_wise/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTotalStats(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/dashboard/total_stats/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getLabDashboardData(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard/lab_new_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getWardDashboardData(value: number): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/beds/filter_by_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&ward_id=' +
          value,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getOpdDashboardData(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard/opd_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getHrDashboardData(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLabNotifications(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/all_lab_orders/lab_test_verified_with_viewed_status_false/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
    isNotificationView(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/all_lab_orders/update_lab_test_viewed_status/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          lab_ids: value,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getRadiologyDashboardData(value): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard/radiology_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIpdDashboardData(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard/ipd_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getOtDashboardData(): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/status_count?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getSuperAdminStatsData(fromDate, toDate): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard/master_dashboard?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSuperAdminPatientStatsData(
    fromDate,
    toDate,
    type,
    currentPage: any,
    perPage: any,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/dashboard/get_patients_data_against_date?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&type=' +
          type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Blood Bank Dashboard
  getBloodDashboardData(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/dashboard_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  //  Inventory Dashboard
  getInventoryDashboardData(value): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/dashboard_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          value.fromDate +
          '&to_date=' +
          value.toDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
