import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { DialysisService } from '../../../services/dialysis.service';
import { ShareDataService } from '../../../services/share-data.service';
import { PatientTypeService } from '../../../services/patient-type.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-dialysis-services-modal',
  templateUrl: './dialysis-services-modal.component.html',
  styleUrls: ['./dialysis-services-modal.component.css'],
})
export class DialysisServicesModalComponent implements OnInit {
  @Output() serviceCreatedOrUpdated: EventEmitter<any> =
    new EventEmitter<any>();
  DialysisServicesForm: UntypedFormGroup;
  submitted = false;
  departments = [];
  sub_departments = [];
  departmentServices = [];
  updateDialysis = false;
  updatedData: any;
  updatedDialysisData: any;
  patientTypes = [];
  currentId: any;
  isChecked = false;
  disableSubmit = false;
  showCostingDetail = false;
  updateCostingDetail: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private dialysisService: DialysisService,
    private ref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private ngxService: NgxUiLoaderService,
    private departmentService: DepartmentsbyhospitalService,
    private sharedService: ShareDataService,
    private patientTypeService: PatientTypeService,
  ) {
    this.DialysisServicesForm = this.formBuilder.group({
      service_name: ['', Validators.required],
      service_id: [''],
      id: [''],
      comments: [''],
      department_id: ['', Validators.required],
      parent_department_id: [''],
      department_service_id: [''],
      service_price: [''],
      replicate_service_pat_types: [''],
      is_active: [true],
    });
  }

  getDepartments(): any {
    this.departments = [];
    this.ngxService.start();
    this.departmentService.fetchSubDeptbyServicev2('dialysis', '').subscribe(
      (data) => {
        this.departments = data.departments;
        this.ngxService.stop();
        setTimeout(() => {
          $('.departmentSelectpicker').selectpicker();
          $('.departmentSelectpicker').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }
  parent_department(): any {
    this.sub_departments = [];
    this.departmentServices = [];
    this.ngxService.start();
    let department_id = '';
    let department_code = 'Dialysis';
    // if(this.parentDepartmentId !=='')
    // {
    //   console.log('test id', this.parentDepartmentId);
    //   department_id = this.parentDepartmentId;
    // }
    this.departmentService
      .parent_deptV2Dialysis(department_id, department_code)
      .subscribe(
        (data) => {
          this.sub_departments = data.departments;
          this.ngxService.stop();
          setTimeout(() => {
            $('.subDepartmentSelectpicker').selectpicker();
            $('.subDepartmentSelectpicker').selectpicker('refresh');
          }, 500);
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }
  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  noDecimalPoint(event: any): any {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  createDialysisService(): any {
    this.disableSubmit = true;
    this.submitted = true;
    if (this.DialysisServicesForm.invalid) {
      this.disableSubmit = false;
      return;
    }
    if (!this.isChecked && !this.updateDialysis) {
      toastr.error('Select Confirmation');
      this.disableSubmit = false;
      return;
    }
    const dialysisFormValue = this.DialysisServicesForm.value;
    dialysisFormValue.cost_details = this.updateCostingDetail;
    this.ngxService.start();
    this.dialysisService
      .createDialysisServiceV2(dialysisFormValue)
      .subscribe(
        (data) => {
          // this.searchFilter();
          toastr.success('Successfully Saved');
          this.ngxService.stop();
          this.serviceCreatedOrUpdated.emit();
          this.disableSubmit = false;
          // $('#dialysis-service').modal('hide');
        },
        (err) => {
          this.disableSubmit = false;
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }
  departmentsServices(): any {
    this.departmentServices = [];
    this.ngxService.start();
    this.departmentService
      .fetchServicesV2(this.DialysisServicesForm.value.department_id)
      .subscribe(
        (data) => {
          this.departmentServices = data.department_services;
          this.ngxService.stop();
        },
        (err) => {
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }

  clear(): any {
    this.submitted = false;
    this.updateDialysis = false;
    this.DialysisServicesForm.reset({
      service_name: '',
      service_id: '',
      id: '',
      comments: '',
      department_id: '',
      parent_department_id: '',
      department_service_id: '',
      is_active: true,
      // replicate_service_pat_types: '',
      service_price: '',
    });
    if (this.patientTypes) {
      const defaultPatientType = this.patientTypes.find((t) => t.is_default);
      const patientType = defaultPatientType ? [defaultPatientType.id] : [];
      this.DialysisServicesForm.get('replicate_service_pat_types').setValue(
        patientType,
      );
      setTimeout(() => {
        $('#patient_types').selectpicker('refresh');
        $('#patient_types').selectpicker();
      }, 500);
    } else {
      this.getPatientTypes();
    }
    setTimeout(() => {
      $('.subDepartmentSelectpicker').selectpicker();
      $('.subDepartmentSelectpicker').selectpicker('refresh');
      $('.departmentSelectpicker').selectpicker();
      $('.departmentSelectpicker').selectpicker('refresh');
    }, 500);
  }
  updateDialysisService(): any {
    this.disableSubmit = true;
    this.submitted = true;
    if (this.DialysisServicesForm.invalid) {
      this.disableSubmit = false;
      return;
    }
    const dialysisFormValue = this.DialysisServicesForm.value;
    dialysisFormValue.cost_details = this.updateCostingDetail;
    this.ngxService.start();
    this.dialysisService
      .updateDialysisServiceV2(
        dialysisFormValue,
        this.DialysisServicesForm.value.id,
        this.DialysisServicesForm.value.service_id,
      )
      .subscribe(
        (data) => {
          toastr.success('Successfully Saved');
          // this.searchFilter();
          this.serviceCreatedOrUpdated.emit();
          this.ngxService.stop();
          this.disableSubmit = false;
        },
        (err) => {
          this.disableSubmit = false;
          this.ngxService.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getPatientTypes(): any {
    this.patientTypes = [];
    this.ngxLoader.start();
    this.patientTypeService.indexV2().subscribe(
      (data) => {
        this.patientTypes = data.patient_types;
        if (this.patientTypes) {
          const defaultPatientType = this.patientTypes.find(
            (t) => t.is_default,
          );
          const patientType = defaultPatientType ? [defaultPatientType.id] : [];
          this.DialysisServicesForm.get('replicate_service_pat_types').setValue(
            patientType,
          );
        }
        setTimeout(() => {
          $('#patient_types').selectpicker('refresh');
          $('#patient_types').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getDialysisService(id): any {
    this.updateDialysis = true;
    this.showCostingDetail = false;
    this.ngxService.start();
    this.dialysisService.showDialysisServiceV2(id).subscribe(
      (data) => {
        this.updatedData = data.dialysis_services[0];
        this.updatedDialysisData = data.dialysis_services[0].test_cost_details;
        this.DialysisServicesForm.patchValue({
          id: this.updatedData.id,
          service_id: this.updatedData.service.id,
          service_name: this.updatedData.service.name,
          comments: this.updatedData.comments,
          is_active: this.updatedData.is_active,
          parent_department_id: this.updatedData.parent_department.id,
          service_price: this.updatedData?.service_price ?? '',
          department_id: this.updatedData.department.id,
          replicate_service_pat_types:
            this.updatedData?.replicate_service_pat_types ?? '',
          department_service_id: this.updatedData.service.department_service_id,
        });

        this.ngxService.stop();

        // if (this.DialysisServicesForm.value.parent_department_id) {
        this.parent_department();
        //  this.parentDepartmentId=this.updatedData.department.id;
        setTimeout(() => {
          $('.subDepartmentSelectpicker').selectpicker();
          $('.subDepartmentSelectpicker').selectpicker('refresh');
          $('.departmentSelectpicker').selectpicker();
          $('.departmentSelectpicker').selectpicker('refresh');
          $('#patient_types').selectpicker();
          $('patient_types').selectpicker('refresh');
        }, 500);
        // this.parent_department(this.updatedData.department.id);
        // }
        this.showCostingDetail = true;
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getCostingData(event): any {
    this.updateCostingDetail = event;
    if (this.updateCostingDetail.is_service_price) {
      this.DialysisServicesForm.get('service_price').setValue(
        this.updateCostingDetail.total_price,
      );
      $('#service_price').prop('disabled', true);
    } else {
      $('#service_price').prop('disabled', false);
    }
  }
  ngOnInit(): void {
    this.clear();
    this.parent_department();
    // this.getDepartments();
    this.getPatientTypes();
    this.sharedService.updateDialysisServiceData.subscribe((data) => {
      if (data) {
        this.currentId = data;
        this.DialysisServicesForm.get('service_price').clearValidators();
        this.DialysisServicesForm.get('service_price').updateValueAndValidity();
        this.DialysisServicesForm.get(
          'replicate_service_pat_types',
        ).clearValidators();
        this.DialysisServicesForm.get(
          'replicate_service_pat_types',
        ).updateValueAndValidity();
        this.getDialysisService(data);
      } else {
        this.showCostingDetail = false;
        this.currentId = '';
        this.DialysisServicesForm.get('service_price').setValidators(
          Validators.required,
        );
        this.DialysisServicesForm.get('service_price').updateValueAndValidity();
        this.DialysisServicesForm.get(
          'replicate_service_pat_types',
        ).setValidators(Validators.required);
        this.DialysisServicesForm.get(
          'replicate_service_pat_types',
        ).updateValueAndValidity();
        this.updatedDialysisData = '';
        setTimeout(() => {
          this.showCostingDetail = true;
        }, 500);
        this.clear();
      }
    });
  }
  get f(): any {
    return this.DialysisServicesForm.controls;
  }
}
