<!--begin::Login-->
<div *ngIf="!listData" class="d-flex flex-column flex-root">
  <!--begin::Login-->
  <div
    class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
    id="kt_login"
  >
    <!--begin::Aside-->
    <div
      class="login-aside d-flex flex-column flex-row-auto"
      style="background-color: #3599fe38; height: 100vh; position: relative"
    >
      <!--begin::Aside Top-->
      <div
        class="d-flex flex-column-auto flex-column pt-lg-40 pt-15"
        style="z-index: 99"
      >
        <!--begin::Aside header-->
        <!--        <a  class="text-center mb-10">-->
        <!--          <img src="assets/media/logos/logo-letter-1.png" class="max-h-70px" alt="" />-->
        <!--        </a>-->
        <!--end::Aside header-->
        <!--begin::Aside title-->
        <!--        <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #986923;">Affordable, scalable <br> & secure healthcare solutions<br></h3>-->
        <!--end::Aside title-->
      </div>
      <!--end::Aside Top-->
      <!--begin::Aside Bottom-->
      <!--      <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" style="background-image: url(assets/media/svg/illustrations/login-visual-1.svg)"></div>-->
      <!--      <img src="assets/media/svg/illustrations/loginbg.png" style="width: 100%;position: absolute;bottom: 0px;max-height: 100vh;">-->
      <img
        [src]="homeImage"
        style="width: 100%; position: absolute; bottom: 0px; max-height: 100vh"
      />
      <!--end::Aside Bottom-->
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <div
      class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
    >
      <!--begin::Content body-->
      <div class="d-flex flex-column-fluid flex-center">
        <!--begin::Signin-->
        <div class="login-form login-signin">
          <!--begin::Form-->

          <form
            class="form"
            [formGroup]="loginForm"
            (ngSubmit)="createSession()"
          >
            <!--begin::Title-->
            <div class="pb-13 pt-lg-0 pt-5">
              <h2 class="font-weight-bolder text-dark">
                Welcome to<br />
                Camden Health System
              </h2>
              <!--              <span class="text-muted font-weight-bold font-size-h4">New Here?-->
              <!--									<a href="javascript:;" id="kt_login_signup" class="text-primary font-weight-bolder">Create an Account</a></span>-->
            </div>
            <!--begin::Title-->

            <!--begin::Form group-->
            <div class="form-group">
              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                  >MRN</label
                >
                <!--              <a data-toggle="modal" data-target="#resetusername" class="text-muted cursor-pointer  font-weight-bolder pt-5">Forgot Username ?</a>-->
              </div>
              <input
                class="form-control h-auto p-3"
                type="text"
                autocomplete="new-name"
                formControlName="mrn"
                [ngClass]="{ 'is-invalid': submitted && f.mrn.errors }"
              />
            </div>
            <!--end::Form group-->

            <!--begin::Form group-->
            <div class="form-group">
              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                  >Registered Mobile Number</label
                >
                <!--                data-toggle="modal" data-target="#resetpassword"-->
                <!--                <a class="text-muted cursor-pointer  font-weight-bolder pt-5" (click)="resetForgotPassForm();openPasswordModal()">Forgot Password ?</a>-->
              </div>
              <input
                class="form-control h-auto p-3"
                formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                type="password"
                autocomplete="off"
              />
            </div>
            <!--end::Form group-->
            <!--begin::Action-->
            <div class="pb-lg-0 pb-5">
              <!--              id="kt_login_signin_submit"-->
              <button
                type="submit"
                class="btn btn-primary font-weight-bolder font-size-h6 px-6 py-2 mb-3 mr-3"
              >
                Login
              </button>
            </div>
            <!--end::Action-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Signin-->
      </div>
      <!--end::Content body-->
      <!--begin::Content footer-->
      <div class="py-7 py-lg-0">
        <div class="text-dark-50 font-size-lg font-weight-bolder">
          <span class="mr-1">2021©</span>
          <a
            href="https://irvinetek.com/"
            target="_blank"
            class="text-dark-75 text-hover-primary"
            >Powered By IrvineTek</a
          >
        </div>
        <br />
        <a class="text-primary font-weight-bolder font-size-lg">Terms</a>
        <!--        <a  class="text-primary ml-5 font-weight-bolder font-size-lg">Plans</a>-->
        <a class="text-primary ml-5 font-weight-bolder font-size-lg"
          >Contact Us</a
        >
      </div>
      <!--end::Content footer-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Login-->
</div>
<!--end::Login-->

<!--begin::Listing-->
<div *ngIf="listData">
  <div class="container-fluid mt-6">
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div
            class="symbol symbol-70 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            style=""
          >
            <div
              *ngIf="!patient"
              class="symbol-label"
              style="
                border-radius: 50px;
                background-image: url(&quot;assets/media/users/blank.png&quot;);
              "
            ></div>
            <div
              *ngIf="patient?.gender === 'unidentified'"
              class="symbol-label"
              style="
                border-radius: 50px;
                background-image: url(&quot;assets/media/users/blank.png&quot;);
              "
            ></div>
            <div
              *ngIf="patient?.gender === 'transgender'"
              class="symbol-label"
              style="
                border-radius: 50px;
                background-image: url(&quot;assets/media/users/blank.png&quot;);
              "
            ></div>
            <div
              *ngIf="patient?.gender === 'male'"
              class="symbol-label"
              style="
                border-radius: 50px;
                background-image: url(&quot;assets/media/svg/avatars/009-boy-4.svg&quot;);
              "
            ></div>
            <div
              *ngIf="patient?.gender === 'female'"
              class="symbol-label"
              style="
                border-radius: 50px;
                background-image: url(&quot;assets/media/svg/avatars/002-girl.svg&quot;);
              "
            ></div>
          </div>
          <div>
            <div class="font-weight-bolder" style="font-size: 17px">
              <span>{{ patient?.full_name }}</span>
            </div>
            <div>
              <span><strong>MRN#</strong> {{ patient?.mrn }}</span>
            </div>
            <div>
              <span
                ><strong>Age:</strong> {{ patient?.age }},
                {{ patient?.gender }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom gutter-b">
      <!--begin::Body-->
      <div class="card-body">
        <!--begin::Table-->

        <!--          <div class="table_top_bar mb-3">-->
        <!--            <div class="row">-->
        <!--              <div class="col-12 col-sm-3">-->
        <!--                <div class="d-block form-group">-->
        <!--                  <input class="form-control form-control-sm" placeholder="Group Name" name="Store Name" [(ngModel)]="batchName" />-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="col-12 col-sm-3">-->
        <!--                <div class="form-group">-->
        <!--                  <div class="mt-3 text-left">-->
        <!--                    <a class="btn btn-light-primary btn-sm px-6 font-weight-bold" (click)="searchFilterValue()" ngbTooltip="Search Filter" placement="bottom">Search</a>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->

        <div class="table-responsive position-relative">
          <table
            class="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr>
                <th>
                  <div>
                    <span>Test Name</span>
                  </div>
                  <div>
                    <span>Date/Time</span>
                  </div>
                </th>
                <!--              <th style=""></th>-->
                <th class="responsive-dpt">Department</th>
                <th class="responsive-dpt">Status</th>
                <!--              <th style="">MR #</th>-->
                <!--              <th style="">Patient Name</th>-->
                <th class="responsive-dpt">Report</th>
                <th class="responsiveness text-right">
                  <div>
                    <span>Report</span>
                  </div>
                  <div>
                    <span>Status</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of listData; let i = index">
                <td>
                  <div class="font-weight-bold">
                    <span>{{ data?.test }}</span>
                  </div>
                  <div>
                    <span>{{
                      data?.created_at | dateTimeFormat
                    }}</span>
                  </div>
                </td>
                <!--              <td></td>-->
                <!--              <td></td>-->
                <td class="responsive-dpt">{{ data?.department_name }}</td>
                <td class="responsive-dpt">
                  <span
                    class="label label-lg label-light-success label-inline"
                    *ngIf="data?.status === 'Delivered'"
                    >{{ data?.status }}</span
                  >
                  <span
                    class="label label-lg label-light-primary label-inline"
                    *ngIf="data?.status === 'Verified'"
                    >{{ data?.status }}</span
                  >
                </td>
                <!--              <td>{{data?.patient_mrn}}</td>-->
                <!--              <td>{{data?.patient_name}}</td>-->
                <td class="responsive-dpt">
                  <a
                    class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-2"
                    (click)="printReport(data.id, data?.report_type)"
                  >
                    <span class="svg-icon svg-icon-primary svg-icon-2x"
                      ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\File.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            opacity="0.3"
                          />
                          <rect
                            fill="#000000"
                            x="6"
                            y="11"
                            width="9"
                            height="2"
                            rx="1"
                          />
                          <rect
                            fill="#000000"
                            x="6"
                            y="15"
                            width="5"
                            height="2"
                            rx="1"
                          />
                        </g></svg
                      ><!--end::Svg Icon--></span
                    >
                  </a>
                </td>
                <td class="responsiveness text-right">
                  <div>
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-2"
                      (click)="printReport(data.id, data?.report_type)"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Files\File.svg--><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <rect
                              fill="#000000"
                              x="6"
                              y="11"
                              width="9"
                              height="2"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              x="6"
                              y="15"
                              width="5"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      >
                    </a>
                  </div>
                  <div class="mt-1">
                    <span
                      class="label label-lg label-light-success label-inline"
                      *ngIf="data?.status === 'Delivered'"
                      >{{ data?.status }}</span
                    >
                    <span
                      class="label label-lg label-light-primary label-inline"
                      *ngIf="data?.status === 'Verified'"
                      >{{ data?.status }}</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <!--pagination-->
        <!--          <div class="pagination_main">-->
        <!--            <ngb-pagination [(page)]="page" (pageChange)="loadPage(page)" [pageSize]="perPage" [collectionSize]="totalPages" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>-->
        <!--            <div class="dropdown">-->
        <!--              <a class="btn dropdown-toggle btn-light btn-sm" href="#" role="button" id="pagination_btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--                    <span class="page_count">-->
        <!--                        {{perPage}}-->
        <!--                    </span>-->
        <!--                <span class="count_count_icon"></span>-->
        <!--              </a>-->
        <!--              <div class="dropdown-menu" aria-labelledby="pagination_btn">-->
        <!--                <a class="dropdown-item" (click)="selectPaginationLimit($event)">25</a>-->
        <!--                <a class="dropdown-item" (click)="selectPaginationLimit($event)">50</a>-->
        <!--                <a class="dropdown-item" (click)="selectPaginationLimit($event)">75</a>-->
        <!--                <a class="dropdown-item" (click)="selectPaginationLimit($event)">100</a>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
      </div>
      <!--end::Body-->
    </div>
  </div>
</div>
<!--end::Listing-->
