<form [formGroup]="LabTestForm" (ngSubmit)="newLabTest()">
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Code <span class="text-danger">*</span></label>
          <input
            type="text"
            formControlName="profile_code"
            class="form-control form-control-sm"
            placeholder="Enter Code"
            disabled
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Name <span class="text-danger">*</span></label>
          <input
            type="text"
            formControlName="profile_name"
            class="form-control form-control-sm"
            placeholder="Enter Test Name"
            [ngClass]="{
              'is-invalid': labTestSubmitted && f.profile_name.errors
            }"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>Group Name </label>
          <div
          >
            <select
              class="form-control form-control-sm"
              id="groupName"
              data-live-search="true"
              formControlName="lab_test_group"
              data-none-selected-text="Select"
            >
              <option value="">Select</option>
              <option
                [value]="lab.id"
                *ngFor="let lab of labHeadingConfigurations"
              >
                {{ lab.description }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>Sub Department <span class="text-danger">*</span></label>
          <div
            [ngClass]="{
              'is-invalid': labTestSubmitted && f.department_id.errors
            }"
          >
            <select
              class="form-control form-control-sm"
              id="departmentPicker"
              data-live-search="true"
              formControlName="department_id"
              data-none-selected-text="Select Department"
            >
              <option value="" [disabled]="true">Select Sub Department</option>
              <option
                [value]="department.id"
                *ngFor="let department of departments"
              >
                {{ department.title }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Send outs </label>
          <select
            class="form-control form-control-sm"
            id="sendout"
            data-live-search="true"
            formControlName="send_out_id"
            data-none-selected-text="Select send outs"
          >
            <option value="" [selected]="true">Select send outs</option>
            <option *ngFor="let hospital of ehmList" [value]="hospital.id">
              {{ hospital.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label> Specimen </label>
          <select
            class="form-control form-control-sm"
            id="specimen"
            data-live-search="true"
            formControlName="specimen_id"
            data-none-selected-text="Select specimen"
          >
            <option value="" [selected]="true">Select specimen</option>
            <ng-container *ngFor="let type of labConfigurations">
              <option
                *ngIf="type.configurator_type === 'specimen'"
                [value]="type.id"
              >
                {{ type.description }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-6 col-12" *ngIf="!updateId">
        <div class="form-group">
          <label> Price <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Price"
            id="service_price"
            maxlength="10"
            formControlName="service_price"
            [ngClass]="{
              'is-invalid': labTestSubmitted && f.service_price.errors
            }"
            (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
          />
        </div>
      </div>
      <!--              <div class="col-lg-3 col-md-2 col-sm-6 col-12">-->
      <!--                <div class="form-group">-->
      <!--                  <label> Report Type  <span class="text-danger">*</span></label>-->
      <!--                  <div [ngClass]="{ 'is-invalid': labTestSubmitted && f.report_type.errors }">-->
      <!--                    <select  class="form-control form-control-sm" id="reportTypePicker" data-live-search="true" formControlName="report_type"  >-->
      <!--                      <option value="" [disabled]="true">Select Report Type</option>-->
      <!--                      &lt;!&ndash;                    <option [value]="report_type" *ngFor="let report_type of report_types">{{report_type}}</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Standard">Standard</option>&ndash;&gt;-->
      <!--                      <option value="Customized">Customized</option>-->
      <!--  &lt;!&ndash;                    <option value="Customized-1">Customized-1</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Customized-2">Customized-2 (without uom)</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Customized-3">Customized-2 (without uom and reference ranges)</option>&ndash;&gt;-->
      <!--                      <option value="Culture">Culture</option>-->
      <!--  &lt;!&ndash;                    <option value="Histo">Histo</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Covid">Covid</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Covid-19">Covid-19</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Culture-1">Culture-1</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Blood-donor">Blood-donor</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Combo">Combo</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Combo-2">Combo-2 (without uom)</option>&ndash;&gt;-->
      <!--  &lt;!&ndash;                    <option value="Combo-3">Combo-3 (without uom and reference value)</option>&ndash;&gt;-->
      <!--                    </select>-->

      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->

      <div class="col-lg-1 col-md-1 col-sm-6 col-12">
        <div class="form-group">
          <label> Color </label>
          <input
            type="color"
            formControlName="color"
            class="form-control form-control-sm p-1"
            placeholder="Enter Color"
            [ngClass]="{ 'is-invalid': labTestSubmitted && f.color.errors }"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <!--                  <label> Status  </label>-->
          <label class="checkbox checkbox-sm mt-6">
            <input type="checkbox" id="activeStatus" formControlName="status" />
            <span class="mr-2"></span>Status
          </label>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <!--                  <label> Status  </label>-->
          <label class="checkbox checkbox-sm mt-5">
            <input
              type="checkbox"
              id="dateRequired"
              formControlName="reporting_time"
            />
            <span class="mr-2"></span>Reporting Time
          </label>
        </div>
      </div>

      <div class="col-lg-5 col-md-5 col-sm-6 col-12" *ngIf="!updateId">
        <label
          >Replicate Service price On<span class="text-danger">*</span></label
        >
        <div
          class="form-group"
          [ngClass]="{
            'is-invalid':
              labTestSubmitted && f.replicate_service_pat_types.errors
          }"
        >
          <select
            class="form-control form-control-sm bootstrap-select-container"
            formControlName="replicate_service_pat_types"
            id="patient_type"
            multiple
            data-live-search="true"
            data-container="body"
            data-none-selected-text="Select Patient Type"
          >
            <option value="" [disabled]="true">Select Patient Type</option>
            <ng-container *ngFor="let patient_type of patientTypes">
              <option [value]="patient_type.id">{{ patient_type.name }}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="addLabTest">
      <label class="checkbox checkbox-sm mt-6">
        <input
          type="checkbox"
          [(ngModel)]="isChecked"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="mr-2"></span>Do you want to apply the same service price on
        selected Patient type?
      </label>
    </div>
    <hr />

    <!--    Costing Detail Start-->
    <ng-container *ngIf="showCostingDetail">
      <app-service-costing-detail
        [labCostingData]="labCosting"
        [type]="'lab-service'"
        (updateCostingData)="this.getCostingData($event)"
      ></app-service-costing-detail>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn btn-secondary btn-sm mr-2"
      data-dismiss="modal"
    >
      Close
    </button>
    <button
      type="button"
      id="clearButton"
      (click)="clearModalValues()"
      class="btn btn-primary btn-sm"
    >
      Clear
    </button>
    <button
      *ngIf="addLabTest"
      [disabled]="disabledBtn"
      type="submit"
      class="btn btn-primary btn-sm ml-2"
    >
      Add
    </button>
    <button
      *ngIf="!addLabTest"
      type="button"
      [disabled]="disabledBtn"
      (click)="updateLabTestFun()"
      class="btn btn-primary btn-sm ml-2"
    >
      Update
    </button>
  </div>
</form>
